import React, { useContext, useEffect, useState } from "react";
import Index from "../components/Registration-MemberRegistration/index";
import { SpinnerContext } from "./spinner.context";
import * as RegistrationAPI from "../services/RegistrationAPI";
import { MEMBER_ID_NO } from "../define.constants";

const MultiStepContext = React.createContext();

const MultiStepProvider = ({ children }) => {
  const [currentStep, setStep] = useState(1);
  const [enrollState, setEnrollState] = useState("");
  const [selectedPlan, setSelectedPlan] = useState(MEMBER_ID_NO);
  const [memberId, setMemberId] = useState("");
  const [dob, setDob] = useState("");
  const [email, setEmail] = useState("");
  const [confirmEmail, setConfirmEmail] = useState("");
  const [userId, setUserId] = useState("");
  const [pwd, setPwd] = useState("");
  const [confirmPwd, setConfirmPwd] = useState("");
  const [phoneNo, setPhoneNo] = useState("");
  const [confirmPhoneNo, setConfirmPhoneNo] = useState("");
  const [textConsent, setTextConsent] = useState(false);
  const [securityCode, setSecurityCode] = useState("");
  const { setSpinner } = useContext(SpinnerContext);
  const [sessionId, setSessionId] = useState("");
  const [fullMedicareNo, setFullMedicareNo] = useState("");
  const [medicareno, setMedicareno] = useState("");
  const [medicareNoSecond, setMedicareNoSecond] = useState("");
  const [medicareNoThird, setMedicareNoThird] = useState("");
  const [confirmSsnField3, setConfirmSsnFeildThird] = useState("");
  const [confirmSsnField2, setConfirmSsnFeildTwo] = useState("");
  const [confirmSsnField1, setConfirmSsnFeildOne] = useState("");
  const [ssnFeild3, setSsnFeild3] = useState("");
  const [ssnFeild2, setSsnFeild2] = useState("");
  const [ssnFeild1, setSsnFeild1] = useState("");
  const [zipCode, setZipCode] = useState("");
  const [fullSSN, setFullSSN] = useState("");
  const [recordRetrievalSSN, setRecordRetrievalFailSSN] = useState(0);

  function setBrowserSessionId() {
    sessionStorage.setItem("sessionId", crypto.randomUUID());
  }
  function submitData() {
    setFinalData((finalData) => [...finalData, userData]);
    setUserData("");
  }

  function sendSecurityCode(param) {
    setSpinner(true);
    RegistrationAPI.getSendOrResendSecurityCode({
      EmailId: email,
      PhoneNum: phoneNo,
      isphonenumber: param !== "email" ? true : false,
      Functionality: "MemberRegistration",
      isResendClicked: false,
    })
      .then((data) => {
        if (data.Message === "Success") {
          setSecurityCode(data.ResultData);
          return 1;
        } else {
          //TODO
          alert("ERROR!!");
          return 0;
        }
      })
      .catch((error) => {
        alert("Error Occurred!", error.status);
        return 0;
      })
      .finally(() => {
        setSpinner(false);
      });
  }

  return (
    <div>
      <MultiStepContext.Provider
        value={{
          currentStep,
          setStep,
          setSelectedPlan,
          selectedPlan,
          setBrowserSessionId,
          enrollState,
          setEnrollState,
          memberId,
          setMemberId,
          medicareno,
          setMedicareno,
          fullMedicareNo,
          setFullMedicareNo,
          medicareNoSecond,
          setMedicareNoSecond,
          medicareNoThird,
          setMedicareNoThird,
          confirmSsnField3,
          setConfirmSsnFeildThird,
          ssnFeild3,
          setSsnFeild3,
          zipCode,
          setZipCode,
          dob,
          setDob,
          email,
          setEmail,
          confirmEmail,
          setConfirmEmail,
          userId,
          setUserId,
          pwd,
          setPwd,
          confirmPwd,
          setConfirmPwd,
          phoneNo,
          setPhoneNo,
          confirmPhoneNo,
          setConfirmPhoneNo,
          textConsent,
          setTextConsent,
          securityCode,
          setSecurityCode,
          sendSecurityCode,
          setSsnFeild2,
          setSsnFeild1,
          ssnFeild2,
          ssnFeild1,
          confirmSsnField2,
          setConfirmSsnFeildTwo,
          confirmSsnField1,
          setConfirmSsnFeildOne,
          fullSSN,
          setFullSSN,
          recordRetrievalSSN,
          setRecordRetrievalFailSSN,
        }}
      >
        {children}
      </MultiStepContext.Provider>
    </div>
  );
};

export { MultiStepProvider, MultiStepContext };
