import { useContext, useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import { SpinnerContext } from "../contexts/spinner.context";
import { ErrorContext } from "../contexts/error.context";
import { MARKETPLACE } from "../define.constants";
import { getOneildigitalid } from "../services/OneilIdCardAPI";
import { ONeilAuditLogIDcard } from "../services/ONeilAuditLogIDcard ";

const useOneilIdCardAPI = (
  memberId,
  MemberStateName,
  year,
  memberLOB,
  RKSMEMBER_ID,
  programId,
  userId
) => {
  const { setError } = useContext(ErrorContext);
  const { setSpinner } = useContext(SpinnerContext);
  const [result, setResult] = useState([]);
  const [statusCode, setStatusCode] = useState();
  const isValidInput = (memberId, yearVal, lobVal) => {
    if (lobVal?.toLowerCase() === MARKETPLACE && memberId && yearVal) {
      return true;
    } else {
      return false;
    }
  };
  useEffect(() => {
    if (isValidInput(memberId, year, memberLOB)) {
      setSpinner(true);
      setResult([]);
      setStatusCode(undefined);
      const TransactionId = uuidv4();
      getOneildigitalid(
        MemberStateName,
        memberLOB.toLowerCase(),
        [memberId],
        "Digital",
        year.toString(),
        TransactionId
      )
        .then((response) => {
          setResult(response);
          ONeilAuditLogIDcard(
            userId,
            RKSMEMBER_ID,
            "IDcard",
            MemberStateName,
            programId,
            memberLOB.toLowerCase(),
            new Date(),
            JSON.stringify({
              state: MemberStateName,
              lob: memberLOB.toLowerCase(),
              memberId: [memberId],
              type: "Digital",
              planYear: year.toString(),
              TransactionId,
            }),
            "Success",
            "IDcard",
            "",
            "",
            RKSMEMBER_ID,
            MemberStateName,
            memberId
          ).catch(function (err) {});
        })
        .catch(function (err) {
          if (err.statusCode !== 404) {
            setError({ err });
          }
          setStatusCode(err.statusCode);
          ONeilAuditLogIDcard(
            userId,
            RKSMEMBER_ID,
            "IDcard",
            MemberStateName,
            programId,
            memberLOB.toLowerCase(),
            new Date(),
            JSON.stringify({
              state: MemberStateName,
              lob: memberLOB.toLowerCase(),
              memberId: [memberId],
              type: "Digital",
              planYear: year.toString(),
              TransactionId,
            }),
            "Error",
            "IDcard",
            "",
            "",
            RKSMEMBER_ID,
            MemberStateName,
            memberId
          ).catch(function (err) {});
        })
        .finally(function () {
          setSpinner(false);
        });
    }
  }, [setSpinner, memberId, MemberStateName, year, memberLOB]);
  return {
    result,
    statusCode,
  };
};
export { useOneilIdCardAPI };
