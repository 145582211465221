import React, { useContext, useRef, useEffect, useState } from "react";
import { AppContext } from "../../contexts/app.context";
import { useGetDDLYearsAPI } from "../../hooks/useGetDDLYearsAPI";
import { getFormattedDate } from "../../utils/helpers";
import {
  ICON_PATH,
  SPANISH_LANG_CODE,
  ENGLISH_LANG_CODE,
  SPANISH_LANG_TEXT,
  MARKETPLACE,
  GLOBAL_VARIABLE,
  Implementation_Year,
} from "../../define.constants";
import {
  getStateConfiguration,
  getStateLobConfigurationItems,
} from "./../../utils/helpers";
import { CustomSelect } from "../../common/Select";
import {
  Text,
  RichText,
  getFieldValue,
} from "@sitecore-jss/sitecore-jss-react";

import { useLocation } from "react-router-dom";
import { createFileIcon, createLinkIcon } from "./Helper";
import { ErrorContext } from "../../contexts/error.context";
import * as CommonAPI from "../../services/Common";
import { MyResourcesContext } from "../../contexts/myResources.context";
import { SpinnerContext } from "../../contexts/spinner.context";
import { COVERAGE_ELIGIBILITY } from "../../define.widgets";
import NonMarketplaceFormDocs from "./NonMarketplaceFormDocs";
const MyResourcesFormDocs = (props) => {
  let {
    MemberStateName,
    memberLOB,
    prefLang,
    isSupportUser,
    programId,
    isNYAffinityMember,
    isCABrightcareMember,
  } = useContext(AppContext);
  //const [distItem, SetDistItem] = useState(null);
  const [yearFromMyCoverage, setYearFromMyCoverage] = useState(null);
  const { setError } = useContext(ErrorContext);
  const { distItem, listRecords } = useContext(MyResourcesContext);
  let formsFields, docsFields;
  GLOBAL_VARIABLE.genesysIntent = getFieldValue(props?.fields, "intent");
  if (props.fields.FormDocslist?.length > 0) {
    formsFields = props.fields.FormDocslist[0].fields; // To fetch Forms fields
    docsFields = props.fields.FormDocslist[1].fields; // To fetch Docs fields
  }
  const cy2024ImplementationYear = parseInt(
    props.fields.yearParameter?.value != undefined ||
      props.fields.yearParameter?.value != null
      ? props.fields.yearParameter?.value
      : Implementation_Year
  );

  let formsList = {};
  if (isNYAffinityMember || isCABrightcareMember) {
    formsList = getStateLobConfigurationItems(
      formsFields,
      MemberStateName,
      memberLOB,
      programId
    );
  } else {
    formsList = getStateConfiguration(formsFields, MemberStateName, memberLOB);
  }

  let docsList = [];

  if (isCABrightcareMember) {
    docsList = getStateLobConfigurationItems(
      docsFields,
      MemberStateName,
      memberLOB,
      programId
    );
  } else {
    docsList = getStateConfiguration(docsFields, MemberStateName, memberLOB);
  }

  const [recordList, setRecordList] = useState([]);
  const [record, setRecord] = useState(null);
  const [isYearSpecific, setIsYearSpecific] = useState(false);
  //const { listRecords } = useGetDDLYearsAPI();
  const { innerSpinnerHTML } = useContext(SpinnerContext);
  useEffect(() => {
    let plans = listRecords?.sort((a, b) => b.Year - a.Year);
    setRecordList(plans);
    setRecord(plans[0]);
    listRecords && listRecords?.length > 0
      ? setIsYearSpecific(true)
      : setIsYearSpecific(false);
  }, [listRecords]);

  const handleYearSelect = (option) => {
    let record = recordList.filter((r) => Number(r.Year) === option.value)[0];
    setRecord(record);
    setYearFromMyCoverage(null);
  };

  //code to navigate to Documents section
  let currentpath = useLocation();
  const langRef = useRef(null);
  useEffect(() => {
    if (currentpath.hash) {
      setTimeout(
        function () {
          if (langRef?.current?.offsetTop) {
            window.scrollTo(0, langRef.current.offsetTop - 250);
          }
        }.bind(this),
        1000
      );
      let selectedCoverageYear = Number(currentpath.hash.split(",")[1]);
      let record = recordList.filter(
        (r) => Number(r.Year) === selectedCoverageYear
      )[0];
      setRecord(record);
      setYearFromMyCoverage(selectedCoverageYear);
    }
  }, [distItem, prefLang]);
  if (memberLOB.toLocaleLowerCase() == MARKETPLACE.toLocaleLowerCase()) {
    return (
      <div
        className="tab-pane fade show active my-resources-page"
        id="formsDocs"
        role="tabpanel"
        aria-label="tab-1"
      >
        {/* Forms Section start */}
        <div className="row">
          <div className="col-md-12">
            <h5 className="tab-title title1">{formsFields?.tabName.value}</h5>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <ul className="list-group">
              {Array.isArray(formsList) && formsList.length && (
                <>
                  {formsList?.map((item, index) => (
                    <li className="list-group-item cards cards-md" key={index}>
                      <div className="row" id={item.fields.formTitle}>
                        <div className="col-xxl-2 col-md-3 list-title card-titles">
                          <Text field={item.fields.formTitle} />
                        </div>
                        <div className="col-xxl-8 col-md-7 list-desc">
                          {item.fields.isSubmitOnline.value === true ? (
                            <div>
                              <RichText
                                field={item.fields.submitOnlineDescription}
                              />
                            </div>
                          ) : (
                            <>
                              {getFieldValue(item.fields, "formDescription") ? (
                                <div>
                                  <RichText
                                    field={item.fields.formDescription}
                                  />
                                </div>
                              ) : null}
                            </>
                          )}

                          {getFieldValue(item.fields, "formFaxContact") ||
                          getFieldValue(item.fields, "formAddressContact") ? (
                            <>
                              <hr className="dropdown-divider d-sm-block d-md-none" />
                            </>
                          ) : null}

                          {getFieldValue(item.fields, "formFaxContact") ||
                          getFieldValue(item.fields, "formAddressContact") ? (
                            <>
                              <div className="row">
                                <div className="col-xxl-4 col-lg-6 col-md-6">
                                  {getFieldValue(
                                    item.fields,
                                    "formAddressContact"
                                  ) ? (
                                    <>
                                      <div className="list-desc-title address-title">
                                        <Text
                                          field={item.fields.formAddressHeading}
                                        />
                                      </div>
                                      <div className="list-desc-details address-details">
                                        <RichText
                                          field={item.fields.formAddressContact}
                                        />
                                      </div>{" "}
                                    </>
                                  ) : null}
                                </div>
                                <div className="col-xxl-3 col-lg-6 col-md-6">
                                  {getFieldValue(
                                    item.fields,
                                    "formFaxContact"
                                  ) ? (
                                    <>
                                      <div className="list-desc-title">
                                        <Text
                                          field={item.fields.formFaxHeading}
                                        />
                                      </div>
                                      <div className="list-desc-details">
                                        <Text
                                          field={item.fields.formFaxContact}
                                        />
                                      </div>
                                    </>
                                  ) : null}
                                </div>
                              </div>
                            </>
                          ) : null}
                        </div>
                        <div className="col-xxl-2 col-md-2 list-multi-link-drp">
                          {item.fields.formLinkLangList != null && (
                            <div className="text-right">
                              {item.fields.formLinkLangList.length > 1 ? (
                                <>
                                  <div>
                                    <a
                                      className="list-icon-download"
                                      href="#"
                                      id={index}
                                      data-mdb-toggle="dropdown"
                                      cls_vname={
                                        MemberStateName +
                                        "_" +
                                        memberLOB +
                                        "_" +
                                        item?.displayName.replace(/\s+/g, "_")
                                      }
                                    >
                                      <img
                                        src={`${ICON_PATH}/Group_2641.svg`}
                                        alt={formsFields?.tabName.value}
                                        id={`downloadIcon${index}`}
                                      />
                                    </a>

                                    <div className="dropdown-menu dropdown-menu-right dropdown-default">
                                      {item.fields.formLinkLangList?.map(
                                        (e, index) => {
                                          return e.fields.isDoc?.value === true
                                            ? createFileIcon(
                                                e.fields.formDoc,
                                                index,
                                                e.fields.language,
                                                "dropdown-item",
                                                true,
                                                null,
                                                MemberStateName +
                                                  "_" +
                                                  memberLOB +
                                                  "_" +
                                                  item?.displayName.replace(
                                                    /\s+/g,
                                                    "_"
                                                  )
                                              )
                                            : createLinkIcon({
                                                link: e.fields.formLink,
                                                index,
                                                language: e.fields.language,
                                                className: "dropdown-item",
                                                multiLanguage: true,
                                                imageName: "Group_2641.svg",
                                                byPassAccessControl: true,
                                                cls_vname:
                                                  MemberStateName +
                                                  "_" +
                                                  memberLOB +
                                                  "_" +
                                                  item?.displayName.replace(
                                                    /\s+/g,
                                                    "_"
                                                  ),
                                              });
                                        }
                                      )}
                                    </div>
                                  </div>
                                </>
                              ) : item.fields.formLinkLangList.length === 1 ? (
                                <>
                                  {item.fields.formLinkLangList?.map(
                                    (e, index) => {
                                      return e.fields.isDoc?.value === true
                                        ? createFileIcon(
                                            e.fields.formDoc,
                                            index,
                                            e.fields.language,
                                            "list-icon-download",
                                            false,
                                            formsFields?.tabName,
                                            MemberStateName +
                                              "_" +
                                              memberLOB +
                                              "_" +
                                              item?.displayName.replace(
                                                /\s+/g,
                                                "_"
                                              )
                                          )
                                        : createLinkIcon({
                                            link: e.fields.formLink,
                                            index,
                                            language: e.fields.language,
                                            className: "list-icon-download",
                                            multiLanguage: false,
                                            imageName: "Group_2641.svg",
                                            tabName: formsFields?.tabName,
                                            byPassAccessControl: true,
                                            cls_vname:
                                              MemberStateName +
                                              "_" +
                                              memberLOB +
                                              "_" +
                                              item?.displayName.replace(
                                                /\s+/g,
                                                "_"
                                              ),
                                          });
                                    }
                                  )}
                                </>
                              ) : null}
                            </div>
                          )}
                        </div>
                      </div>
                    </li>
                  ))}
                </>
              )}
            </ul>
          </div>
        </div>
        {/* Forms Section end */}
        <div ref={langRef}>
          <hr className="dropdown-divider hr-divider" />
        </div>

        {/* Documents Section start */}
        {distItem && (
          <>
            <div className="row">
              <div className="col-xxl-3 col-lg-4 col-md-6 col-sm-12">
                <h5 className="tab-title title2">
                  {docsFields?.tabName.value}
                </h5>
              </div>
              {listRecords?.length > 1 && (
                <div className="col-xxl-9 col-lg-8 col-md-6 col-sm-12 document-plan-year coverage-page">
                  <div className="plan-year-block">
                    <div>{docsFields?.planYear.value}</div>
                    <div>
                      <CustomSelect
                        field={{
                          name: "documentsPlanYears",
                          value: yearFromMyCoverage
                            ? yearFromMyCoverage
                            : record?.Year,
                        }}
                        className="form-select"
                        aria-label={getFieldValue(formsFields, "planYear")}
                        options={recordList.map((item) => ({
                          value: item.Year,
                          label: item.Year,
                        }))}
                        isFormik={false}
                        onChange={handleYearSelect}
                      />
                    </div>
                  </div>
                </div>
              )}
            </div>
            <div className="row ">
              <div className="col-md-12">
                <ul className="list-group loder-position">
                  {innerSpinnerHTML?.[COVERAGE_ELIGIBILITY]}
                  {Array.isArray(docsList) && docsList.length && (
                    <>
                      {docsList?.map((item, index) => {
                        if (index === docsList.length - 1) {
                          if (
                            item.fields.excludePlanIds?.value === "" ||
                            (item.fields.excludePlanIds?.value !== "" &&
                              item.fields.excludePlanIds?.value
                                .toString()
                                .split(",")
                                .indexOf(record?.PlanID) < 0)
                          ) {
                            return (
                              <li
                                className="list-group-item last-card-tab1 cards-md"
                                key={index}
                              >
                                <div className="row">
                                  <div className="col-xxl-2 col-md-3 list-title card-titles">
                                    <Text field={item.fields.documentTitle} />
                                  </div>
                                  <div className="col-xxl-8 col-md-7 list-desc2">
                                    <div>
                                      <Text
                                        field={item.fields.documentDescription}
                                      />
                                    </div>
                                  </div>
                                  <div className="col-xxl-2 col-md-2">
                                    <div className="text-right">
                                      {item.fields.isDoc?.value === true &&
                                      record?.Year ==
                                        distItem?.phrases.comingSoonYear
                                        ? createFileIcon(
                                            item.fields.Doc,
                                            index,
                                            "",
                                            "list-icon-download",
                                            false,
                                            docsFields?.tabName,
                                            MemberStateName +
                                              "_" +
                                              memberLOB +
                                              "_" +
                                              item?.displayName.replace(
                                                /\s+/g,
                                                "_"
                                              )
                                          )
                                        : createLinkIcon({
                                            link: item.fields.documentLink,
                                            index,
                                            language: "",
                                            className: "list-icon-download",
                                            multiLanguage: false,
                                            imageName: "Group_2641.svg",
                                            tabName: docsFields?.tabName,
                                            distItem,
                                            MemberStateName,
                                            memberLOB,
                                            name: item.fields.docName?.value,
                                            planId: record?.PlanID,
                                            selectedYear: record?.Year,
                                            isYearSpecific,
                                            byPassAccessControl: true,
                                            cls_vname:
                                              MemberStateName +
                                              "_" +
                                              memberLOB +
                                              "_" +
                                              item?.displayName.replace(
                                                /\s+/g,
                                                "_"
                                              ),
                                          })}
                                    </div>
                                  </div>
                                </div>
                              </li>
                            );
                          }
                        } else {
                          if (
                            item.fields.excludePlanIds?.value === "" ||
                            (item.fields.excludePlanIds?.value !== "" &&
                              item.fields.excludePlanIds?.value
                                .toString()
                                .split(",")
                                .indexOf(record?.PlanID) < 0)
                          ) {
                            return (
                              <li
                                className="list-group-item cards cards-md"
                                key={index}
                              >
                                <div className="row">
                                  <div className="col-xxl-2 col-md-3 list-title card-titles">
                                    <Text field={item.fields.documentTitle} />
                                  </div>
                                  <div className="col-xxl-8 col-md-7 list-desc2">
                                    <div>
                                      <Text
                                        field={item.fields.documentDescription}
                                      />
                                    </div>
                                  </div>
                                  <div className="col-xxl-2 col-md-2">
                                    <div className="text-right">
                                      {item.fields.isDoc?.value === true &&
                                      record?.Year ==
                                        distItem?.phrases.comingSoonYear
                                        ? createFileIcon(
                                            item.fields.Doc,
                                            index,
                                            "",
                                            "list-icon-download",
                                            false,
                                            docsFields?.tabName,
                                            MemberStateName +
                                              "_" +
                                              memberLOB +
                                              "_" +
                                              item?.displayName.replace(
                                                /\s+/g,
                                                "_"
                                              )
                                          )
                                        : createLinkIcon({
                                            link: item.fields.documentLink,
                                            index,
                                            language: "",
                                            className: "list-icon-download",
                                            multiLanguage: false,
                                            imageName: "Group_2641.svg",
                                            tabName: docsFields?.tabName,
                                            distItem,
                                            MemberStateName,
                                            memberLOB,
                                            name: item.fields.docName?.value,
                                            planId: record?.PlanID,
                                            selectedYear: record?.Year,
                                            isYearSpecific,
                                            byPassAccessControl: true,
                                            cls_vname:
                                              MemberStateName +
                                              "_" +
                                              memberLOB +
                                              "_" +
                                              item?.displayName.replace(
                                                /\s+/g,
                                                "_"
                                              ),
                                          })}
                                    </div>
                                  </div>
                                </div>
                              </li>
                            );
                          }
                        }
                      })}
                    </>
                  )}
                </ul>
              </div>
            </div>
          </>
        )}
        {/* Documents Section end */}
      </div>
    );
  } else {
    return (
      <NonMarketplaceFormDocs
        formsFields={formsFields}
        formsList={formsList}
        langRef={langRef}
        distItem={distItem}
        docsFields={docsFields}
        docsList={docsList}
        listRecords={listRecords}
        cy2024ImplementationYear={cy2024ImplementationYear}
      />
    );
  }
};

export default MyResourcesFormDocs;
