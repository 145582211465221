import React, { createContext, useState } from "react";
import { useMemberAPI } from "../hooks/useMemberAPI";
import { useGetProfilesAPI } from "../hooks/useGetProfilesAPI";
import { useAccountSettingsProfileAPI } from "../hooks/useAccountSettingsProfileAPI";

const AppContext = createContext({
  refreshMemberInfoAll: () => {},
  refreshMemberInfoAndReload: () => {},
  isMemberAPICalled: false,
  unreadInboxMsgCount: "",
  memberFirstName: "",
  memberLastName: "",
  id: "",
  index: "",
  memberLOB: "",
  graceStatus: "",
  memberRKSID: "",
  programId: "",
  roleId: "",
  userId: "",
  gender: "",
  dateOfBirth: "",
  planName: "",
  coverageEffDt: "",
  planTerminationDt: "",
  mailingAddress: "",
  prefEmailId: "",
  prefPhone: "",
  prefLang: "",
  memberId: "",
  doctorName: "",
  homePhone: "",
  cellPhone: "",
  Dependents: [],
  memberName: "",
  MemberStateName: "",
  PPHRAlerts: "",
  isMemInfoResponseLoaded: false,
  DependentCategory: "",
  isAIANMember: false,
  loggedInUserDependentCat: "",
  switchedUserDependentCat: "",
  selfRoleId: "",
  planId: "",
  zip: "",
  memberStateCode: "",
  primaryCaseManager: "",
  enrollmentEffDate: "",
  city: "",
  stateFullName: "",
  sfContactId: "",
  isCABrightcareMember: "",
  ClientID_WA: "",
});
AppContext.displayName = "App";

function AppProvider({ children }) {
  const {
    userType,
    refreshMemberInfoAll,
    refreshMemberInfoAndReload,
    isMemberAPICalled,
    unreadInboxMsgCount,
    memberFirstName,
    memberLastName,
    id,
    memberLOB,
    graceStatus,
    memberRKSID,
    programId,
    roleId,
    showAccordant,
    userId,
    gender,
    dateOfBirth,
    planName,
    coverageEffDt,
    planTerminationDt,
    mailingAddress,
    prefEmailId,
    prefPhone,
    //prefLang,
    memberId,
    doctorName,
    homePhone,
    cellPhone,
    memberName,
    MemberStateName,
    PPHRAlerts,
    isMemInfoResponseLoaded,
    refreshSession,
    DependentCategory,
    DependentDetails,
    isAIANMember,
    planId,
    IsDualEligible,
    zip,
    isTermedMember,
    isSupportUser,
    supportUserId,
    isNYAffinityMember,
    isILHBIMember,
    isFIMCMember,
    isDualEligible,
    IsDSNPMember,
    DSNPdetails,
    IsWISSIMember,
    isNYEssentialPlanUser,
    IsMCCMemberFlag,
    memberStateCode,
    primaryCaseManager,
    enrollmentEffDate,
    RedeterminationAlerts,
    RedeterminationDate,
    RedetSFMessageID,
    VA_Category,
    IsOHIORISE,
    OH_CSPValue,
    city,
    stateFullName,
    isCABrightcareMember,
    ServiceCoordinatorPhoneNumber,
  } = useMemberAPI();

  const {
    isDependentLoggedIn,
    Dependents,
    changeDependentView,
    changeDSNPView,
    isSwitchedToDependent,
    selectedMemberNumber,
    selectedMemberName,
    isSwitchedView,
    switchedUserType,
    showUserSwitch,
    switchedUserDependentCat,
    loggedInUserDependentCat,
    selfRoleId,
    refreshProfiles,
  } = useGetProfilesAPI({
    refresh: refreshSession,
    refreshMemberInfoAndReload,
    memberRKSID,
    memberName: `${memberFirstName} ${memberLastName}`,
    _memberNumber: id,
    isDependentLoggedIn: true,
    loggedInStateCode: MemberStateName,
    userType,
    isMemberAPICalled,
  });
  const {
    profileList,
    prefLang,
    refreshAPI,
    sfContactId,
    ClientID_WA,
    refreshGetAccountProfileAPI,
  } = useAccountSettingsProfileAPI({});

  const context = {
    userType,
    refreshMemberInfoAll,
    refreshMemberInfoAndReload,
    isMemberAPICalled,
    unreadInboxMsgCount,
    memberFirstName,
    memberLastName,
    id,
    memberLOB,
    graceStatus,
    memberRKSID,
    programId,
    roleId,
    isDependentLoggedIn,
    showAccordant,
    userId,
    gender,
    dateOfBirth,
    planName,
    coverageEffDt,
    planTerminationDt,
    mailingAddress,
    prefEmailId,
    prefPhone,
    prefLang,
    memberId,
    doctorName,
    homePhone,
    cellPhone,
    Dependents,
    memberName,
    MemberStateName,
    PPHRAlerts,
    IsDualEligible,
    changeDependentView,
    changeDSNPView,
    refreshProfiles,
    isSwitchedToDependent,
    isMemInfoResponseLoaded,
    selectedMemberNumber,
    selectedMemberName,
    isSwitchedView,
    switchedUserType,
    DependentCategory,
    DependentDetails,
    isAIANMember,
    switchedUserDependentCat,
    loggedInUserDependentCat,
    selfRoleId,
    showUserSwitch,
    planId,
    zip,
    profileList,
    refreshAPI,
    refreshGetAccountProfileAPI,
    isTermedMember,
    isSupportUser,
    supportUserId,
    isNYAffinityMember,
    isILHBIMember,
    isFIMCMember,
    isDualEligible,
    IsDSNPMember,
    DSNPdetails,
    IsWISSIMember,
    isNYEssentialPlanUser,
    IsMCCMemberFlag,
    memberStateCode,
    primaryCaseManager,
    enrollmentEffDate,
    RedeterminationAlerts,
    RedeterminationDate,
    RedetSFMessageID,
    VA_Category,
    IsOHIORISE,
    OH_CSPValue,
    city,
    stateFullName,
    sfContactId,
    isCABrightcareMember,
    ClientID_WA,
    ServiceCoordinatorPhoneNumber,
  };

  return <AppContext.Provider value={context}>{children}</AppContext.Provider>;
}

export { AppContext, AppProvider };
