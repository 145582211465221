import React from "react";
import { Text } from "@sitecore-jss/sitecore-jss-react";
import { Calendar } from "../../../common/Calendar";
import { Field, ErrorMessage } from "formik";
import { InlineFormError } from "../../../common/InlineFormError";

const DisplayDateItem = (props) => {
  const {
    dateClass,
    dateLabel,
    dateName,
    formikProps,
    invalidDateMessage,
    minDateMessage,
    maxDateMessage,
    minDate,
  } = props;
  return (
    <div className={`col-xl-3 col-md-6 col-12 ${dateClass}`}>
      <label className="label mb-2">
        <Text field={dateLabel} />
      </label>
      {minDate ? (
        <Field
          component={Calendar}
          name={dateName}
          value={formikProps.values[dateName]}
          invalidDateMessage={invalidDateMessage}
          minDateMessage={minDateMessage}
          maxDateMessage={maxDateMessage}
          onChange={(value) => formikProps.setFieldValue(dateName, value)}
          aria-label={dateName}
          minDate={minDate}
        />
      ) : (
        <Field
          component={Calendar}
          name={dateName}
          value={formikProps.values[dateName]}
          invalidDateMessage={invalidDateMessage}
          minDateMessage={minDateMessage}
          maxDateMessage={maxDateMessage}
          onChange={(value) => formikProps.setFieldValue(dateName, value)}
          aria-label={dateName}
        />
      )}

      <ErrorMessage name={dateName} component={InlineFormError} />
    </div>
  );
};

export default DisplayDateItem;
