import React, { useContext, useState, useEffect } from "react";
import { Formik, Field, Form, ErrorMessage } from "formik";
import { getFieldValue, Text, Link } from "@sitecore-jss/sitecore-jss-react";
import * as Yup from "yup";
import StepProgressBar from "./StepProgressBar";
import { MultiStepContext } from "../../contexts/Steps.context";
import { InlineFormError } from "../../common/InlineFormError";
import {
  ICON_PATH,
  PWD_LOWERCASE_REGEX,
  PWD_NUMBER_REGEX,
  PWD_UPPERCASE_REGEX,
  REPEAT_THREE_REGEX,
  SUCCESS_RESPONSE,
  SUCCESS_RESULT_CODE,
  USERNAME_CHECK_LENGTH,
  PHONE_DIGIT_ONLY_REGEX,
  INVALID_PASSWORD_CODE,
  PWD_MINIMUM_LENGTH,
  FAILURE_RESULT_CODE,
  USERID_ALREADY_EXISTS_RESPONSE,
  EMAIL_ALREADY_EXISTS_RESPONSE,
  SSN_NO,
  FLAG_NO,
  SSN_NO_MEMBER_FOUND_THIRDORMOREATTEMPT,
  VALIDATE_MEMBERSHIP_ATTEMPT_FIRST,
  SSN_MEMBER_NOT_FOUND,
  SSN_MULTIPLE_MEMBER_FOUND,
  SSN_MEMBER_ALREADY_REGISTERED,
  SSN_MEMMASTER_ALREADY_HASRECORD,
} from "../../define.constants";
import {
  highlightBorderOnError,
  brightCarePortal,
  handleLogin,
} from "../../utils/helpers";
import { SpinnerContext } from "../../contexts/spinner.context";
import * as RegistrationAPI from "../../services/RegistrationAPI";
import { ErrorContext } from "../../contexts/error.context";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Popover from "react-bootstrap/Popover";
import * as cdpAPI from "../../services/CDPAPI";

const UserIdnPwd = (props) => {
  const { fields } = props;
  const { setSpinner } = useContext(SpinnerContext);
  const { setError } = useContext(ErrorContext);
  const {
    setStep,
    enrollState,
    memberId,
    setMemberId,
    setEnrollState,
    setSsnFeild3,
    setMedicareno,
    setMedicareNoSecond,
    setMedicareNoThird,
    dob,
    email,
    setEmail,
    confirmEmail,
    setConfirmEmail,
    userId,
    setUserId,
    pwd,
    setPwd,
    confirmPwd,
    setConfirmPwd,
    phoneNo,
    setPhoneNo,
    confirmPhoneNo,
    setConfirmPhoneNo,
    fullMedicareNo,
    textConsent,
    setTextConsent,
    securityCode,
    setSecurityCode,
    selectedPlan,
    fullSSN,
    zipCode,
    setSsnFeild2,
    setSsnFeild1,
    recordRetrievalSSN,
    setRecordRetrievalFailSSN,
  } = useContext(MultiStepContext);

  const [username, setUsername] = useState("");
  const [pwdValidate, setPwdValidate] = useState(false);
  const [isEmailExist, setIsEmailExist] = useState(false);
  const [pwdLength, setPwdLength] = useState(false);
  const [pwdUppercaseRule, setPwdUppercaseRule] = useState(false);
  const [userNameEightToFifteen, setUserNameEightToFifteen] = useState(false);
  const [userNoSpecialCharacters, setUserNoSpecialCharacters] = useState(false);
  const [pwdLowercaseRule, setPwdLowercaseRule] = useState(false);
  const [pwdNumberRule, setPwdNumberRule] = useState(false);
  const [pwdRepeatThreeRule, setPwdRepeatThreeRule] = useState(false);
  const [pwdNoUsernameRule, setPwdNoUsernameRule] = useState(false);
  const [pwdNoSpaceRule, setPwdNoSpaceRule] = useState(false);
  const [showPopover, setShowPopover] = useState(false);
  const [showUserIdPopover, setShowUserIdPopover] = useState(false);
  const [showPasswordError, setShowPasswordError] = useState("");
  const [
    membAlreadyRegisteredErrorMessage,
    setMembAlreadyRegisteredErrorMessage,
  ] = useState("");
  const [showPasswordErrorPopover, setShowPasswordErrorPopover] =
    useState(false);
  const [showPasswordSecondModel, setshowPasswordSecondModel] = useState(false);
  const [isUserAlreadyExists, setIsUserAlreadyExists] = useState(false);
  const [isSubmited, setIsSubmited] = useState(false);
  const [popoverPlacement, setPopoverPlacement] = useState();
  const [invalidPasswordFlag, setInvalidPasswordFlag] = useState(false);
  const [passwordError, setPasswordError] = useState(false);
  const eye = `${ICON_PATH}/visibility-24px.svg`;
  const eyeSlash = `${ICON_PATH}/visibility_off_black_24dp.svg`;
  const [visible, setVisible] = useState(false); //for Password Field
  const [consentCheckBox, setConsentCheckBox] = useState(false);
  const [agreeCheckBox, setAgreeCheckBox] = useState(false);
  const [pingPasswordError, setPingPasswordError] = useState(true);
  const [pingUserId, setPingUserId] = useState("");
  const [isBrightCare, setIsBrightCare] = useState(null);
  const [validateMembership, setValidationMsg] = useState("");
  const [statusCode, setStatusCode] = useState("");

  const pwdVisibilityIcon = (
    <img
      src={visible ? eye : eyeSlash}
      alt={getFieldValue(fields, "pwd")}
      onClick={() => setVisible(!visible)}
      className="pwd-icon"
    />
  );
  const pwdInputType = visible ? "text" : "password";
  const required = <span style={{ color: "red" }}>*</span>;

  const [visible2, setVisible2] = useState(false); //for Confirm Password Field
  const pwdVisibilityIcon2 = (
    <img
      src={visible2 ? eye : eyeSlash}
      alt={getFieldValue(fields, "pwd")}
      onClick={() => setVisible2(!visible2)}
      className="pwd-icon"
    />
  );
  const pwdInputType2 = visible2 ? "text" : "password";

  useEffect(async () => {
    setIsBrightCare(await brightCarePortal());
    if (window.outerWidth > 768) {
      setPopoverPlacement("right");
    } else {
      setPopoverPlacement("bottom");
    }
  }, [window.outerWidth]);

  async function validateEmail(value) {
    if (value !== null && value !== "") {
      try {
        let response = await RegistrationAPI.getEmailExistsInSystem({
          MemberNumber: memberId,
          Dob: dob,
          StateCode: enrollState,
          Email: value,
        });

        if (
          response.Message !== SUCCESS_RESPONSE &&
          response.ResultCode === 5007
        ) {
          setIsEmailExist(true);
        }
      } catch {
        (err) => setError({ err });
      }
    }
  }

  async function validateUserIdExistance(value) {
    setShowPasswordError(false);
    if (value !== null && value !== "") {
      try {
        let response = await RegistrationAPI.getUserIdExistsInSystem({
          MemberNumber: memberId,
          Dob: dob,
          StateCode: enrollState,
          UserId: value,
        });

        if (response) {
          if (
            response.Message !== SUCCESS_RESPONSE &&
            response.ResultCode === 5007
          ) {
            setIsUserAlreadyExists(true);
          }
        }
      } catch {
        (err) => setError({ err });
      }
    }
  }

  function usernameInPwd(pwd, username) {
    let i = 0;
    do {
      const test = username.slice(i, USERNAME_CHECK_LENGTH);
      if (pwd.includes(test)) {
        return true;
      }
    } while (i++ + USERNAME_CHECK_LENGTH < username.length);
    return false;
  }

  function validatepwd(value) {
    setPasswordError(false);
    setPwdNoUsernameRule(false);
    let invalid = false;
    if (value.length < PWD_MINIMUM_LENGTH) {
      setPwdLength(false);
      invalid = true;
    } else {
      setPwdLength(true);
    }
    if (PWD_UPPERCASE_REGEX.test(value)) {
      setPwdUppercaseRule(true);
    } else {
      setPwdUppercaseRule(false);
      invalid = true;
    }
    if (PWD_LOWERCASE_REGEX.test(value)) {
      setPwdLowercaseRule(true);
    } else {
      setPwdLowercaseRule(false);
      invalid = true;
    }
    if (PWD_NUMBER_REGEX.test(value)) {
      setPwdNumberRule(true);
    } else {
      setPwdNumberRule(false);
      invalid = true;
    }
    if (value.includes(" ")) {
      setPwdNoSpaceRule(false);
      invalid = true;
    } else {
      setPwdNoSpaceRule(true);
    }
    if (REPEAT_THREE_REGEX.test(value)) {
      setPwdRepeatThreeRule(false);
      invalid = true;
    } else {
      setPwdRepeatThreeRule(true);
    }
    if (passwordError) {
      invalid = true;
    }
    setPwdNoUsernameRule(!usernameInPwd(value, username));

    setPwdValidate(!invalid);
    setShowPasswordError("");
    return invalid;
  }

  const DisplayValidationerror = (validationerror) => {
    return (
      <div className="validation-msg" style={{ height: "auto" }}>
        {validationerror}
      </div>
    );
  };
  const DisplayValidationerrorPassword = (validationerror) => {
    return (
      <div className="validation-msg">
        {validationerror}{" "}
        {validationerror && (
          <img
            src={`${ICON_PATH}/info-24px.svg`}
            alt={getFieldValue(fields, "infoIcon")}
            className="info-icon"
            tabIndex="-1"
            onClick={() =>
              setShowPasswordErrorPopover(!showPasswordErrorPopover)
            }
            onBlur={() => setShowPasswordErrorPopover(false)}
          />
        )}
      </div>
    );
  };

  const saveDetails = (obj) => {
    setMemberId("");
    setMedicareno("");
    setMedicareNoSecond("");
    setMedicareNoThird("");
    // setSsnFeild3("");
    setEnrollState("");
    setEmail(obj.email);
    setConfirmEmail(obj.confirmEmail);
    setUserId(obj.userId);
    setPwd(obj.pwd);
    setConfirmPwd(obj.confirmPwd);
    setPhoneNo(obj.phoneNo);
    setConfirmPhoneNo(obj.confirmPhoneNo);
    // setSsnFeild2("");
    // setSsnFeild1("");
    setStep(1);
  };

  const popoverListItemSecond = (text, ruleMet, pwdLengthValue) => {
    if (ruleMet) {
      return (
        <li>
          <div className="row">
            <i className="col-1">
              <img src={`${ICON_PATH}/iconGreen.jpg`} />
            </i>

            <div className="col-11 col-md-10 col-sm-10">
              <b>{text}</b>
            </div>
          </div>
        </li>
      );
    } else {
      return (
        <li style={{ color: "red" }}>
          <div className="row">
            <i className="col-1">
              <img src={`${ICON_PATH}/iconRed.jpg`} />
            </i>

            <div className="col-11 col-md-10 col-sm-10">
              <b>{text}</b>
            </div>
          </div>
        </li>
      );
    }
  };
  const popoverListItem = (text) => {
    return (
      <li>
        <div className="row">
          <div className="col-11 col-md-10 col-sm-10">
            <b>{text}</b>
          </div>
        </div>
      </li>
    );
  };
  const popoverListItemUserName = (text, ruleMet) => {
    if (ruleMet) {
      return (
        <li>
          <div className="row">
            <i className="col-1">
              <img src={`${ICON_PATH}/iconGreen.jpg`} />
            </i>

            <div className="col-11 col-md-10 col-sm-10">
              <b>{text}</b>
            </div>
          </div>
        </li>
      );
    } else {
      return (
        <li style={{ color: "red" }}>
          <div className="row">
            <i className="col-1">
              <img src={`${ICON_PATH}/iconRed.jpg`} />
            </i>

            <div className="col-11 col-md-10 col-sm-10">
              <b>{text}</b>
            </div>
          </div>
        </li>
      );
    }
  };

  function formatPhoneNumber(value) {
    if (!value) return value;
    const phoneNumber = value?.replace(PHONE_DIGIT_ONLY_REGEX, "");
    const phoneNumberLength = phoneNumber?.length;
    if (phoneNumberLength < 4) return phoneNumber;
    else if (phoneNumberLength < 7) {
      return `(${phoneNumber?.slice(0, 3)}) ${phoneNumber?.slice(3)}`;
    }
    return `${phoneNumber?.slice(0, 3)}-${phoneNumber?.slice(
      3,
      6
    )}-${phoneNumber?.slice(6, 10)}`;
  }
  const pwdPopoverSubmit = () => {
    return (
      <div>
        <div className="popoverHeader">
          <b>
            <Text field={fields.passwordMustNotInclude} />
          </b>
        </div>
        <div>
          <ul
            className="list-unstyled"
            style={{
              listStyleType: "decimal",
              paddingLeft: "10px",
            }}
          >
            {popoverListItem(getFieldValue(fields, "pwdPartOfUserName"))}
            {popoverListItem(getFieldValue(fields, "lastThreePasswordResets"))}
            {popoverListItem(
              getFieldValue(fields, "closelyMatchCurrentPassword")
            )}
            {popoverListItem(getFieldValue(fields, "personalInformationOr"))}
            {popoverListItem(getFieldValue(fields, "pwdBeEasilyGuessed"))}
          </ul>
        </div>
      </div>
    );
  };
  const userIdPopover = (value) => {
    setUserNameEightToFifteen(
      value?.length > 15 || value?.length < 8 ? false : true
    );
    setUserNoSpecialCharacters(value === "" || userNameFormat.test(value));

    return (
      <div>
        <div className="popoverHeader">
          <b>
            <Text field={fields.popoverTitleUserName} />
          </b>
        </div>
        <div>
          <ul className="list-unstyled">
            {popoverListItemUserName(
              getFieldValue(fields, "userNameEightToFifteen"),
              userNameEightToFifteen
            )}
            {popoverListItemUserName(
              getFieldValue(fields, "pwdValidationMsg"),
              userNoSpecialCharacters
            )}
          </ul>
        </div>
      </div>
    );
  };

  const pwdPopover = (value) => {
    return (
      <div>
        <div
          className="popoverHeader mb-2"
          style={{ fontFamily: "campton-medium", fontSize: "16px" }}
        >
          <b>
            <Text field={fields.howToCreatePassword} />
          </b>
        </div>
        <div className="popoverHeader">
          <b>
            <Text field={fields.popoverTitle} />
          </b>
        </div>
        <div>
          <ul
            className="list-unstyled"
            style={{
              listStyleType: "decimal",
              paddingLeft: "10px",
            }}
          >
            {popoverListItem(getFieldValue(fields, "pwdLength"))}
            {popoverListItem(getFieldValue(fields, "pwdUppercaseRule"))}
            {popoverListItem(getFieldValue(fields, "pwdLowercaseRule"))}
            {popoverListItem(getFieldValue(fields, "pwdNumberRule"))}
            {popoverListItem(getFieldValue(fields, "pwdNoSpaceRule"))}
            {popoverListItem(getFieldValue(fields, "pwdRepeatThreeRule"))}
          </ul>
        </div>
        <div>
          <div className="popoverHeader">
            <b>
              <Text field={fields.passwordMustNotInclude} />
            </b>
          </div>
          <ul
            className="list-unstyled"
            style={{
              listStyleType: "decimal",
              paddingLeft: "10px",
            }}
          >
            {popoverListItem(getFieldValue(fields, "pwdPartOfUserName"))}
            {popoverListItem(getFieldValue(fields, "lastThreePasswordResets"))}
            {popoverListItem(
              getFieldValue(fields, "closelyMatchCurrentPassword")
            )}
            {popoverListItem(getFieldValue(fields, "personalInformationOr"))}
            {popoverListItem(getFieldValue(fields, "pwdBeEasilyGuessed"))}
          </ul>
        </div>
      </div>
    );
  };
  const pwdPopoverSecond = (value) => {
    let pwdLengthValue = value?.length;
    return (
      <div>
        <div className="popoverHeader">
          <b>
            <Text field={fields.popoverTitle} />
          </b>
        </div>
        <div>
          <ul className="list-unstyled">
            {popoverListItemSecond(
              getFieldValue(fields, "pwdLength"),
              pwdLength,
              pwdLengthValue
            )}
            {popoverListItemSecond(
              getFieldValue(fields, "pwdUppercaseRule"),
              pwdUppercaseRule,
              pwdLengthValue
            )}
            {popoverListItemSecond(
              getFieldValue(fields, "pwdLowercaseRule"),
              pwdLowercaseRule,
              pwdLengthValue
            )}
            {popoverListItemSecond(
              getFieldValue(fields, "pwdNumberRule"),
              pwdNumberRule,
              pwdLengthValue
            )}
            {popoverListItemSecond(
              getFieldValue(fields, "pwdNoSpaceRule"),
              pwdNoSpaceRule,
              pwdLengthValue
            )}
            {popoverListItemSecond(
              getFieldValue(fields, "pwdRepeatThreeRule"),
              pwdRepeatThreeRule,
              pwdLengthValue
            )}
          </ul>
        </div>
      </div>
    );
  };
  const hasErrors = (errors) => {
    return (
      errors.userId ||
      errors.pwd ||
      errors.confirmPwd ||
      errors.email ||
      errors.confirmEmail ||
      errors.phoneNo ||
      errors.acceptTerms
    );
  };
  const preventCopyPaste = (e) => {
    e.preventDefault();
  };
  const phoneRegExp = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
  const userNameFormat = /^[a-zA-Z0-9._]+$/;

  // Account creation API call exclusively for SSN opted scenario
  const handleCreateAccountSSN = (requestData) => {
    RegistrationAPI.createMemberAccountUsingSSNZipcode(requestData)
      .then((data) => {
        setStatusCode(data?.ResultCode);
        setPingPasswordError(true);
        setPingUserId(data?.ResultData?.userID);
        setMembAlreadyRegisteredErrorMessage("");
        if (
          data.Message === SUCCESS_RESPONSE &&
          data.ResultCode === SUCCESS_RESULT_CODE
        ) {
          setPwdNoUsernameRule(false);
          setStep(3);
        } else if (data.ResultCode === SSN_NO_MEMBER_FOUND_THIRDORMOREATTEMPT) {
          setRecordRetrievalFailSSN((count) => count + 1);
          setValidationMsg(getFieldValue(fields, "ValidationError3"));
        } else if (data.ResultCode === VALIDATE_MEMBERSHIP_ATTEMPT_FIRST) {
          setRecordRetrievalFailSSN((count) => count + 1);
          setValidationMsg(getFieldValue(fields, "ValidationError1"));
        } else if (
          data.ResultCode === VALIDATE_MEMBERSHIP_ATTEMPT_FIRST ||
          data.ResultCode === SSN_MEMBER_NOT_FOUND ||
          data.ResultCode === SSN_MULTIPLE_MEMBER_FOUND
        ) {
          setRecordRetrievalFailSSN((count) => count + 1);
          if (data?.ResultData?.AttempCountValue === 1) {
            setValidationMsg(
              getFieldValue(fields, "ValidateSSNErrorMessageSecond")
            );
          } else if (data?.ResultData?.AttempCountValue === 2) {
            setValidationMsg(
              getFieldValue(fields, "ValidateSecondAttemptErrorMsg")
            );
          } else if (data?.ResultData?.AttempCountValue >= 3) {
            setValidationMsg(getFieldValue(fields, "ValidationError3"));
          } else {
            setValidationMsg(getFieldValue(fields, "ValidationError3"));
          }
        } else if (
          data.ResultCode === SSN_MEMBER_ALREADY_REGISTERED ||
          data.ResultCode === SSN_MEMMASTER_ALREADY_HASRECORD
        ) {
          setRecordRetrievalFailSSN((count) => count + 1);
          if (data?.ResultData?.AttempCountValue >= 3) {
            setValidationMsg(getFieldValue(fields, "ValidationError3"));
          } else {
            setValidationMsg(getFieldValue(fields, "ValidationError2"));
          }
        } else if (data.ResultCode === 2322) {
          setPwdNoUsernameRule(false);
          setMembAlreadyRegisteredErrorMessage(
            <Text field={fields.ValidationError2} />
          );
        } else if (data.ResultCode === INVALID_PASSWORD_CODE) {
          setInvalidPasswordFlag(true);
          setPasswordError(true);
          setPwdValidate(false);
          setPingPasswordError(false);
          setShowPasswordError(<Text field={fields.pwdRuleTitle} />);
        } else if (
          data.ResultCode === FAILURE_RESULT_CODE &&
          data.ResultData?.Message === USERID_ALREADY_EXISTS_RESPONSE
        ) {
          setIsUserAlreadyExists(true);
        } else if (
          data.ResultCode === FAILURE_RESULT_CODE &&
          data.ResultData?.Message === EMAIL_ALREADY_EXISTS_RESPONSE
        ) {
          setIsEmailExist(true);
        }
      })
      .catch((err) => {
        setError({ err });
      })
      .finally(() => {
        setSpinner(false);
      });
  };

  return (
    <>
      <Formik
        initialValues={{
          email,
          confirmEmail,
          userId,
          pwd,
          confirmPwd,
          phoneNo,
          confirmPhoneNo,
          textAndEmail: consentCheckBox,
          acceptTerms: agreeCheckBox,
        }}
        enableReinitialize={true}
        validateOnMount={true}
        validationSchema={Yup.object({
          email: !isEmailExist
            ? Yup.string()
                .email(getFieldValue(fields, "enterValidEmail"))
                .required(" ")
            : Yup.string().test(
                "Email Already Exists",
                getFieldValue(fields, "EmailExistErrorMsg"),
                (value) => {
                  true;
                }
              ),
          confirmEmail: Yup.string()
            .required(" ")
            .oneOf(
              [Yup.ref("email"), null],
              getFieldValue(fields, "emailMatchError")
            ),
          userId: !isUserAlreadyExists
            ? Yup.string().required(" ").matches(userNameFormat).min(8).max(15)
            : Yup.string().test(
                "User Already Exists",
                getFieldValue(fields, "UserExistErrorMsg"),
                (value) => {
                  true;
                }
              ),
          pwd: Yup.string().required(" "),
          confirmPwd: Yup.string()
            .required(" ")
            .oneOf(
              [Yup.ref("pwd"), null],
              getFieldValue(fields, "doesNotMatchMsg")
            ),
          phoneNo: Yup.string().matches(
            phoneRegExp,
            getFieldValue(fields, "enterValidPhoneNo")
          ),
          acceptTerms: Yup.boolean().oneOf(
            [true],
            getFieldValue(fields, "pleaseAcceptTerms")
          ),
        })}
        onSubmit={(values, errors) => {
          setEmail(values.email);
          setConfirmEmail(values.confirmEmail);
          setUserId(values.userId);
          setPwd(values.pwd);
          setConfirmPwd(values.confirmPwd);
          setPhoneNo(values.phoneNo);
          setConfirmPhoneNo(values.confirmPhoneNo);
          setTextConsent(values.textAndEmail);

          setConsentCheckBox(values.textAndEmail);
          setAgreeCheckBox(values.agreeCheckBox);
          let requestData = {
            MemberNumber: memberId,
            DOB: dob,
            StateCode: enrollState,
            MedicareMemNo: fullMedicareNo,
            Email: values.email,
            Phone: values.phoneNo,
            UserId: values.userId,
            Password: values.pwd,
            RequestSource: "Desktop",
            isPingPasswordValidationSucess: pingPasswordError,
            PingUserID: pingUserId,
            Language: "EN",
            ConsentCheckBox: values.textAndEmail,
            IsCHPPortal: isBrightCare ? "Y" : "N",
          };
          setSpinner(true);
          // IF SSN is opted, then call the API with SSN and Zipcode
          if (selectedPlan === SSN_NO && fullSSN) {
            // Add SSN and Zipcode related request data
            requestData.SSN = fullSSN;
            requestData.Zipcode = zipCode;
            requestData.isSSNSelected = true;
            requestData.SupportUserID = FLAG_NO;
            requestData.SessionID = sessionStorage.getItem("sessionId");
            requestData.AttemptCountVal = recordRetrievalSSN;
            delete requestData.MemberNumber;
            delete requestData.MedicareMemNo;

            // Call the method, contains API to create account using SSN and Zipcode
            handleCreateAccountSSN(requestData);
          } else {
            RegistrationAPI.getCreateAccount(requestData)
              .then((data) => {
                setPingPasswordError(true);
                setPingUserId(data?.ResultData?.userID);
                setMembAlreadyRegisteredErrorMessage("");
                if (
                  data.Message === SUCCESS_RESPONSE &&
                  data.ResultCode === SUCCESS_RESULT_CODE
                ) {
                  setPwdNoUsernameRule(false);
                  setStep(3);
                  //send CDP event for registration completed
                  try {
                    if (
                      sessionStorage.getItem("CDPRegistrationEvent") === "true"
                    ) {
                      cdpAPI.sendCDPAlert("RegistrationCompleted-CDPCampaign");
                    } else {
                      cdpAPI.sendCDPAlert("RegistrationCompleted");
                    }
                  } catch (error) {}
                } else if (data.ResultCode === 2322) {
                  setPwdNoUsernameRule(false);
                  setMembAlreadyRegisteredErrorMessage(
                    <Text field={fields.ValidationError2} />
                  );
                } else if (data.ResultCode === INVALID_PASSWORD_CODE) {
                  setInvalidPasswordFlag(true);
                  setPasswordError(true);
                  setPwdValidate(false);
                  setPingPasswordError(false);
                  setShowPasswordError(<Text field={fields.pwdRuleTitle} />);
                } else if (
                  data.ResultCode === FAILURE_RESULT_CODE &&
                  data.ResultData?.Message === USERID_ALREADY_EXISTS_RESPONSE
                ) {
                  setIsUserAlreadyExists(true);
                } else if (
                  data.ResultCode === FAILURE_RESULT_CODE &&
                  data.ResultData?.Message === EMAIL_ALREADY_EXISTS_RESPONSE
                ) {
                  setIsEmailExist(true);
                }
              })
              .catch((err) => {
                // console.error(
                //   "Error Occurred in processing request for account creation",
                //   e
                // );
                setError({ err });
              })
              .finally(() => {
                setSpinner(false);
              });
          }
        }}
      >
        {({
          touched,
          errors,
          values,
          setFieldValue,
          setFieldError,
          setFieldTouched,
        }) => (
          <Form onChange={setUsername(values.userId)}>
            <div className="Registration">
              <div className="row">
                <div className="col-md-12 col-sm-12">
                  <section className="d-flex justify-content-center">
                    <div className="card lg-card">
                      <div className="card-body">
                        <StepProgressBar fields={fields} />

                        {/* <!-- step2 --> */}
                        <div className="member-info">
                          <div className="sub-gap text-center">
                            <div
                              className="text-center sub-text font-fill-indetails"
                              style={{ color: "#ef2e2e" }}
                            >
                              {validateMembership && (
                                <div
                                  className="validation-msg errorDisplay"
                                  style={{
                                    lineHeight: "20px",
                                    height: "auto",
                                  }}
                                >
                                  {validateMembership}&nbsp;
                                  {(statusCode ===
                                    SSN_MEMBER_ALREADY_REGISTERED ||
                                    statusCode ===
                                      SSN_MEMMASTER_ALREADY_HASRECORD) && (
                                    <a href="#" onClick={handleLogin}>
                                      {getFieldValue(fields, "lnkHelp").text}
                                    </a>
                                  )}
                                </div>
                              )}
                              {membAlreadyRegisteredErrorMessage && (
                                <div
                                  className="validation-msg"
                                  style={{
                                    lineHeight: "20px",
                                    height: "auto",
                                  }}
                                >
                                  {membAlreadyRegisteredErrorMessage}&nbsp;
                                  <span>
                                    <a
                                      href={
                                        getFieldValue(fields, "lnkHelp")?.href
                                      }
                                    >
                                      {getFieldValue(fields, "lnkHelp").text}
                                    </a>
                                  </span>
                                </div>
                              )}
                              <span>
                                {isSubmited && hasErrors(errors) && (
                                  <Text field={fields.allFieldsReq} />
                                  // <Text field={fields.allFieldsReq2} />
                                )}
                              </span>
                            </div>
                            <div className="text-center font-fill-indetails">
                              <b>
                                <Text field={fields.pleaseFillInTheFields} />
                              </b>
                            </div>
                          </div>
                          <OverlayTrigger
                            placement={popoverPlacement}
                            trigger="focus"
                            show={showUserIdPopover}
                            overlay={
                              <Popover className="pwdPopover popoverMob">
                                <Popover.Body>
                                  {userIdPopover(values.userId)}
                                </Popover.Body>
                              </Popover>
                            }
                          >
                            <div className="pass-wrapper">
                              <div className="row-title">
                                <Text field={fields.userId} />
                                {required}
                                {/* <img
                                  src={`${ICON_PATH}/info-24px.svg`}
                                  alt={getFieldValue(fields, "infoIcon")}
                                  className="info-icon"
                                /> */}
                              </div>
                              <Field
                                name="userId"
                                type="text"
                                // value={step2Data["userId"]}
                                className={`form-control ${highlightBorderOnError(
                                  errors.userId,
                                  touched.userId
                                )}`}
                                onFocus={() => {
                                  setShowUserIdPopover(true);
                                  setIsUserAlreadyExists(false);
                                }}
                                aria-label={getFieldValue(fields, "userId")}
                                disabled={invalidPasswordFlag}
                                onBlur={(e) => {
                                  setShowPopover(false);
                                  setShowUserIdPopover(false);
                                  setshowPasswordSecondModel(false);
                                  validateUserIdExistance(e.target.value);
                                }}
                              />
                            </div>
                          </OverlayTrigger>
                          {DisplayValidationerror(
                            isUserAlreadyExists
                              ? getFieldValue(fields, "UserExistErrorMsg")
                              : null //errors.userId
                          )}
                          <div className="pass-wrapper">
                            <div className="row-title">
                              <img
                                src={`${ICON_PATH}/check-green.svg`}
                                className="check-green"
                                style={{
                                  display: pwdValidate ? "inline" : "none",
                                }}
                                alt={getFieldValue(fields, "pwd")}
                              />
                              <Text field={fields.pwd} />
                              {required}
                              <OverlayTrigger
                                placement={popoverPlacement}
                                trigger="focus"
                                show={showPopover}
                                overlay={
                                  <Popover className="pwdPopover popoverMob">
                                    <Popover.Body>
                                      {pwdPopover(values.pwd)}
                                    </Popover.Body>
                                  </Popover>
                                }
                              >
                                <img
                                  src={`${ICON_PATH}/info-24px.svg`}
                                  alt={getFieldValue(fields, "infoIcon")}
                                  className="info-icon"
                                  tabIndex="-1"
                                  onClick={() => {
                                    setShowUserIdPopover(false);
                                    setShowPopover(!showPopover);
                                    setshowPasswordSecondModel(false);
                                  }}
                                  onBlur={() => setShowPopover(false)}
                                />
                              </OverlayTrigger>
                            </div>
                            <OverlayTrigger
                              placement={popoverPlacement}
                              trigger="focus"
                              show={showPasswordSecondModel}
                              overlay={
                                <Popover className="pwdPopover popoverMob">
                                  <Popover.Body>
                                    {pwdPopoverSecond()}
                                  </Popover.Body>
                                </Popover>
                              }
                            >
                              <div>
                                <Field
                                  name="pwd"
                                  id="pwd"
                                  type={pwdInputType}
                                  className={`form-control ${
                                    passwordError && "input-error"
                                  } ${highlightBorderOnError(
                                    errors.pwd,
                                    touched.pwd
                                  )}`}
                                  aria-label={getFieldValue(fields, "pwd")}
                                  validate={validatepwd}
                                  onFocus={() => {
                                    setshowPasswordSecondModel(true);
                                    setShowUserIdPopover(false);
                                    setShowPopover(false);
                                    setShowPasswordError(false);
                                    // if (values?.pwd.length > 0) {
                                    //   setShowPopover(true);
                                    // }
                                  }}
                                  onCopy={(e) => {
                                    preventCopyPaste(e);
                                  }}
                                  onPaste={(e) => {
                                    preventCopyPaste(e);
                                  }}
                                  onBlur={() => {
                                    setShowPopover(false);
                                    setshowPasswordSecondModel(false);
                                  }}
                                  //onClick={() => setShowPopover(false)}
                                />
                                <i>{pwdVisibilityIcon}</i>
                              </div>
                            </OverlayTrigger>
                          </div>
                          <OverlayTrigger
                            placement={popoverPlacement}
                            trigger="focus"
                            show={showPasswordErrorPopover}
                            overlay={
                              <Popover className="pwdPopover  popoverMob">
                                <Popover.Body>
                                  {pwdPopoverSubmit()}
                                </Popover.Body>
                              </Popover>
                            }
                          >
                            {DisplayValidationerrorPassword(showPasswordError)}
                          </OverlayTrigger>

                          <div className="pass-wrapper">
                            <div className="row-title">
                              <img
                                src={`${ICON_PATH}/check-green.svg`}
                                className="check-green"
                                style={{
                                  display:
                                    !errors.confirmPwd &&
                                    values.confirmPwd &&
                                    pwdValidate
                                      ? "inline"
                                      : "none",
                                }}
                                alt={getFieldValue(fields, "pwd")}
                              />
                              <Text field={fields.confirmPwd} />
                              {required}
                            </div>
                            <Field
                              name="confirmPwd"
                              type={pwdInputType2}
                              className={`form-control ${
                                passwordError && "input-error"
                              } ${highlightBorderOnError(
                                errors.confirmPwd,
                                touched.confirmPwd
                              )}`}
                              onBlur={() => {
                                setShowPopover(false);
                                setShowUserIdPopover(false);
                                setshowPasswordSecondModel(false);
                              }}
                              onCopy={(e) => {
                                preventCopyPaste(e);
                              }}
                              onPaste={(e) => {
                                preventCopyPaste(e);
                              }}
                              aria-label={getFieldValue(fields, "confirmPwd")}
                            />
                            <i>{pwdVisibilityIcon2}</i>
                          </div>
                          {DisplayValidationerror(errors.confirmPwd)}

                          <div className="row-title">
                            <Text field={fields.emailAdd} />
                            {required}
                          </div>
                          {/* <input type="text" className="form-control form-gap"> */}
                          <Field
                            name="email"
                            type="text"
                            className={`form-control ${highlightBorderOnError(
                              errors.email,
                              touched.email
                            )}`}
                            onFocus={() => {
                              setIsEmailExist(false);
                            }}
                            aria-label={getFieldValue(fields, "emailAdd")}
                            disabled={invalidPasswordFlag}
                            onBlur={(e) => validateEmail(e.target.value)}
                          />
                          {DisplayValidationerror(
                            isEmailExist
                              ? getFieldValue(fields, "EmailExistErrorMsg")
                              : errors.email
                          )}
                          {/* <ErrorMessage
                            name="email"
                            component={InlineFormError}
                          /> */}
                          <div className="row-title">
                            <Text field={fields.confirmEmailAdd} />
                            {required}
                          </div>

                          <Field
                            name="confirmEmail"
                            type="text"
                            className={`form-control ${highlightBorderOnError(
                              errors.confirmEmail,
                              touched.confirmEmail
                            )}`}
                            aria-label={getFieldValue(
                              fields,
                              "confirmEmailAdd"
                            )}
                            disabled={invalidPasswordFlag}
                          />
                          {DisplayValidationerror(errors.confirmEmail)}

                          <div className="row-title">
                            <Text field={fields.phoneNo} />
                          </div>
                          <Field
                            name="phoneNo"
                            id="phoneNo"
                            type="text"
                            className={`form-control ${highlightBorderOnError(
                              errors.phoneNo,
                              touched.phoneNo
                            )}`}
                            disabled={invalidPasswordFlag}
                            onBlur={(event) => {
                              //props.handleBlur(event);
                              setFieldValue(
                                `phoneNo`,
                                formatPhoneNumber(event?.target?.value)
                              );
                            }}
                            aria-label={getFieldValue(fields, "phoneNo")}
                          />
                          {DisplayValidationerror(errors.phoneNo)}

                          {/* <div className="row-title">
                            <Text field={fields.confirmPhoneNo} />
                          </div>
                          <Field
                            name="confirmPhoneNo"
                            type="text"
                            className={`form-control ${highlightBorderOnError(
                              errors.confirmPhoneNo,
                              touched.confirmPhoneNo
                            )}`}
                            aria-label={getFieldValue(fields, "confirmPhoneNo")}
                          />
                          {DisplayValidationerror(errors.confirmPhoneNo)} */}

                          <div className="text-check">
                            <Field
                              name="textAndEmail"
                              type="checkbox"
                              className={`form-check-input`}
                              disabled={invalidPasswordFlag}
                            />
                            <Text field={fields.textAndEmail} />
                            <div className="font-weight-500 custom-tooltip-spending">
                              <p className="custom-tooltip-spending-text tooltipBox">
                                {isBrightCare != null ? (
                                  isBrightCare ? (
                                    <Text
                                      field={fields.communicationConsnetCHP}
                                    />
                                  ) : (
                                    <Text field={fields.communicationConsnet} />
                                  )
                                ) : null}
                              </p>
                              <img
                                className="img-molina-info-teal"
                                src={`${ICON_PATH}/info-24px.svg`}
                                alt={getFieldValue(fields, "infoIcon")}
                              />
                            </div>
                          </div>

                          <div className="text-check">
                            <Field
                              name="acceptTerms"
                              type="checkbox"
                              className={`form-check-input ${highlightBorderOnError(
                                errors.acceptTerms,
                                touched.acceptTerms
                              )}`}
                              disabled={invalidPasswordFlag}
                            />
                            <Text field={fields.readAndAgree} />
                            &nbsp;
                            <Link
                              field={fields.lnkTermsOfUse}
                              target="_blank"
                            />
                            {required}
                            <div className="validation-msg terms-and-policy">
                              <ErrorMessage
                                name="acceptTerms"
                                component={InlineFormError}
                              />
                            </div>
                            {isBrightCare ? (
                              <Text field={fields.lblSubsidiaryCHP} />
                            ) : (
                              ""
                            )}
                          </div>

                          <div className="row">
                            <div className="col-md-12">
                              <div className="registration-btn">
                                <button
                                  type="button"
                                  className="btn btn-primary ripple-surface1 btn-1"
                                  onClick={() => saveDetails(values)}
                                >
                                  <Text field={fields.backButton} />
                                </button>
                                <button
                                  type="submit"
                                  className="btn btn-primary ripple-surface"
                                  onClick={() => setIsSubmited(true)}
                                >
                                  <Text field={fields.nextButton} />
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>
                </div>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default UserIdnPwd;
