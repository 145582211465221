const FileUploadErrorDialog = ({ message, onDialog, isCABrightcareMember }) => {
  return (
    <div
      style={{
        position: "fixed",
        top: "0",
        left: "0",
        right: "0",
        bottom: "0",
        backgroundColor: "rgba(0,0,0,0.5)",
      }}
      onClick={() => onDialog(false)}
    >
      <div
        className="confirmation-popup"
        onClick={(e) => e.stopPropagation()}
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          position: "absolute",
          margin: "auto",
          border: "2px solid black",
          width: "600px",
          height: "211px",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          background: "white",
          padding: "20px",
          borderRadius: "10px",
        }}
      >
        <div className="confirmationpopupheader">
          <h5
            style={{
              color: "#111",
              fontsize: "20px",
              margintop: "25px",
              marginbottom: "-1px",
            }}
          >
            {message}
          </h5>
        </div>

        <div
          style={{
            display: "flex",
            alignItems: "enter",
            padding: "15px 50px 30px ",
            marginBottom: "-35px",
          }}
        >
          <button
            type="reset"
            onClick={() => onDialog(false)}
            style={{
              background: isCABrightcareMember ? "#005d63" : "#ff4500",
              color: "white",
              fontSize: "30px",
              padding: "10px",
              borderRadius: "4px",
              marginRight: "50px",
              border: "none",
              cursor: "pointer",
              marginBottom: "10px",
            }}
          >
            OK
          </button>
        </div>
      </div>
    </div>
  );
};
export default FileUploadErrorDialog;
//changes to commit
