import React, { useContext } from "react";
import { AppContext } from "../../../contexts/app.context";
import { getStateConfiguration } from "../../../utils/helpers";
import {
  TABNAME_HEALTHRECORD_SERVICEAUTH,
  MARKETPLACE,
  TABNAME_HEALTHRECORD_CLAIMHISTORY_EN,
  TABNAME_HEALTHRECORD_CLAIMHISTORY_ES,
  NY,
  ENGLISH_LANG_CODE,
  SPANISH_LANG_CODE,
  NYMCC_PROGRAMID,
  MCC_Member,
  TABNAME_HEALTHRECORD_MEDICATIONS_EN,
  TABNAME_HEALTHRECORD_MEDICATIONS_ES,
  NM,
  NM_MEDICAID_ProgramID,
  MEDICAID,
} from "../../../define.constants";
import { getClaimHistoryDisclaimerNonMP } from "./Disclaimer";
export const FooterDescription = (fields) => {
  const {
    MemberStateName,
    memberLOB,
    programId,
    isNYAffinityMember,
    isFIMCMember,
    isDualEligible,
    prefLang,
    IsMCCMemberFlag,
    isCABrightcareMember,
  } = useContext(AppContext);
  let footerDescription = null;
  let descriptionListFields = null;
  let isMarketplaceMember =
    memberLOB?.toLowerCase() === MARKETPLACE.toLowerCase();
  const descriptionList = getStateConfiguration(
    fields,
    MemberStateName,
    memberLOB
  );
  descriptionListFields = descriptionList[0]?.fields;
  const isENGPreferredLang =
    prefLang?.toLowerCase() === ENGLISH_LANG_CODE.toLowerCase();
  const isSPAPreferredLang =
    prefLang?.toLowerCase() === SPANISH_LANG_CODE.toLowerCase();

  //Check for currently ClaimHistory tab selected or not
  const isClaimHistoryTabSelected = () => {
    let isClaimHistSelected = false;
    if (
      (isENGPreferredLang &&
        TABNAME_HEALTHRECORD_CLAIMHISTORY_EN ===
          fields?.tabName.value?.trim().toLowerCase()) ||
      (isSPAPreferredLang &&
        TABNAME_HEALTHRECORD_CLAIMHISTORY_ES ===
          fields?.tabName.value?.trim().toLowerCase())
    ) {
      isClaimHistSelected = true;
    }

    return isClaimHistSelected;
  };

  //Check for currently Medication tab selected or not
  const isMedicationTabSelected = () => {
    let isMedicationSelected = false;
    if (
      (isENGPreferredLang &&
        TABNAME_HEALTHRECORD_MEDICATIONS_EN ===
          fields?.tabName.value?.trim().toLowerCase()) ||
      (isSPAPreferredLang &&
        TABNAME_HEALTHRECORD_MEDICATIONS_ES ===
          fields?.tabName.value?.trim().toLowerCase())
    ) {
      isMedicationSelected = true;
    }

    return isMedicationSelected;
  };

  if (descriptionListFields) {
    if (
      TABNAME_HEALTHRECORD_SERVICEAUTH ===
        fields?.tabName.value?.trim().toLowerCase() &&
      !isMarketplaceMember
    ) {
      footerDescription = descriptionListFields?.lblDescriptionNonMP.value;
    } else if (isClaimHistoryTabSelected() && !isMarketplaceMember) {
      footerDescription = getClaimHistoryDisclaimerNonMP(
        MemberStateName,
        descriptionListFields,
        programId,
        isNYAffinityMember,
        isFIMCMember,
        isDualEligible,
        memberLOB,
        isCABrightcareMember
      );
    } else if (isMedicationTabSelected() && !isMarketplaceMember) {
      if (
        MemberStateName === NY.toUpperCase() &&
        (programId === NYMCC_PROGRAMID || IsMCCMemberFlag === MCC_Member)
      ) {
        footerDescription =
          descriptionListFields?.lblErrorMessageNYNonMP?.value;
      } else if (
        MemberStateName === NM &&
        programId === NM_MEDICAID_ProgramID &&
        memberLOB.toLowerCase() === MEDICAID
      ) {
        footerDescription =
          descriptionListFields?.lblErrorMessageNMNonMP?.value;
      } else if (isCABrightcareMember) {
        footerDescription =
          descriptionListFields?.lblErrorMessageNonMPCHP?.value;
      } else {
        footerDescription = descriptionListFields?.lblErrorMessageNonMP?.value;
      }
    } else if (
      MemberStateName === NM &&
      programId === NM_MEDICAID_ProgramID &&
      memberLOB.toLowerCase() === MEDICAID
    ) {
      footerDescription = descriptionListFields?.lblNMDescription?.value;
    } else if (isCABrightcareMember) {
      footerDescription = descriptionListFields?.lblDescriptionCHP?.value;
    } else {
      footerDescription = descriptionListFields?.lblDescription?.value;
    }
  }

  return footerDescription;
};
