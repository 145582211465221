import React, { useContext, useEffect, useState } from "react";
import {
  getFieldValue,
  Text,
  RichText,
  Link,
} from "@sitecore-jss/sitecore-jss-react";
import {
  ICON_PATH,
  SUCCESS_RESULT_CODE,
  CONTRACT_NAME,
  DISPLAY_POSITION,
  PHONE_VALID_REGEX,
  HomePageUrl,
  GENERALQUESTIONS,
  NURSEADVICELINE,
  EMAIL_REGEX_CONTACT,
  SPANISH_LANG_CODE,
  SPANISH_LANG_TEXT,
  ENGLISH_LANG_CODE,
  MARKETPLACE,
  MEDICARE,
  NAL_CATEGORY_EN,
  NAL_CATEGORY_ES,
  GLOBAL_VARIABLE,
} from "../../define.constants";
import * as Yup from "yup";
import { InlineFormError } from "../../common/InlineFormError";
import { Box } from "../../common/Box";
import { BoxContainer } from "../../common/BoxContainer";
import { CustomSelect } from "../../common/Select";
import * as MessageAPI from "../../services/MessagesAPI";
import { useListServicesAPI } from "../../hooks/useListServicesAPI";
import { getCaptcha, highlightBorderOnError } from "../../utils/helpers";
import { ModalContext } from "../../contexts/modal.context";
import * as ContactUsAPI from "../../services/ContactUsAPI";
import { SpinnerContext } from "../../contexts/spinner.context";
import { AppContext } from "../../contexts/app.context";
import { ErrorContext } from "../../contexts/error.context";
import { WhoIsAuthorized } from "../../common/WhoIsAuthorized";
import { SUPPORT_USER, TERMED_MEMBER } from "../../define.constants";
import { Formik, Form, Field, ErrorMessage } from "formik";
import CancelConfirmationDialog from "../CreateNewMessage/CancelConfirmationDialog";
import FileUploadErrorDialog from "../CreateNewMessage/FileUploadErrorDialog";

const ContactSendaMessage = ({
  fields,
  forDesktop = true,
  onPrint,
  approachList,
}) => {
  const { setSpinner } = useContext(SpinnerContext);
  const { unreadInboxMsgCount, prefLang } = useContext(AppContext);
  const { setError } = useContext(ErrorContext);
  const [checkContactMethod, setCheckContactMethod] = useState("");
  const [successMess, setSuccessMess] = useState(false);
  const [categoryText, setCategoryText] = useState("");
  const { MemberStateName } = useContext(AppContext);
  const { MsgServices } = useListServicesAPI();
  const { openModal, closeModal } = useContext(ModalContext);
  let { isTermedMember } = useContext(AppContext);
  const { isSupportUser, memberLOB, isCABrightcareMember } =
    useContext(AppContext);
  GLOBAL_VARIABLE.genesysIntent = getFieldValue(fields, "intent");
  let generalQuestions = GENERALQUESTIONS;
  let printIcon = (
    <img
      src={`${ICON_PATH}/print-24px.svg`}
      alt={getFieldValue(fields, "lblPrint")}
    />
  );
  const isMarketPlaceMember = memberLOB.toLocaleLowerCase() === MARKETPLACE;
  const nurseAdviceLineDropDownAlert = () => {
    openModal({
      content: nurseAlertContent,
      version: 1,
    });
  };
  //NM
  const grievancesDropDownAlert = () => {
    openModal({
      content: grievancesAlertContent,
      version: 1,
    });
  };
  const nurseAdviceLineModal = () => {
    openModal({
      content: successContentNurse,
      version: 2,
    });
  };
  const GrievanceModal = () => {
    openModal({
      content: successContentGreviance,
      version: 2,
    });
  };
  const handleDropdownChange = (selectedOption) => {
    {
      setCategoryText(selectedOption.value);
      if (
        selectedOption.value === "NAL (Línea de asesoramiento de enfermería)" ||
        selectedOption.value === "NAL (Nurse Advice Line)"
      ) {
        nurseAdviceLineDropDownAlert();
      }
      if (
        selectedOption.value === "Grievances/Issues/Appeals" ||
        selectedOption.value === "Queja/Problema/Apelaciones"
      ) {
        grievancesDropDownAlert();
      }
    }
  };

  const SignupSchema = Yup.object().shape({
    category: Yup.string().required("*"),
    description: Yup.string().trim().required("*"),
    contactMethodOH: Yup.string().required("*"),
  });

  const roundOff = (value) => {
    return Math.round(value * 100) / 100;
  };

  const WithoutSignupSchema = Yup.object().shape({
    category: Yup.string().required("*"),
    description: Yup.string().trim().required("*"),
  });
  const onCancelReset = (values) => {
    console.log(values);
    values.category = "";
    values.subject = "";
    values.description = "";
    values.contactMethodOH = "";
    values.contactMethodOther = "";
    console.log(values);
  };
  const ResetMessForm = (values) => {
    values.category = "";
    values.subject = "";
    values.description = "";
    values.contactMethodOH = "";
    values.contactMethodOther = "";
    resetFormUpload();
  };
  const handleOnClick = () => {
    location = MyMessagesUrl;
  };
  const handleOnClickBackHone = () => {
    location =
      "/" +
      (prefLang !== "" &&
      (SPANISH_LANG_TEXT.toLowerCase() === prefLang.toLowerCase() ||
        SPANISH_LANG_CODE.toLocaleLowerCase() === prefLang.toLocaleLowerCase())
        ? SPANISH_LANG_CODE
        : ENGLISH_LANG_CODE) +
      HomePageUrl;
  };
  const isMedicare = memberLOB.toLowerCase() === MEDICARE;
  const countDisplay = (description) => {
    return description.trim() ? 32000 - description.trim().length : 32000;
  };
  const options = [
    {
      value: "",
      label: <Text field={fields.lblCategoryPH} />,
      id: "",
    },
  ];
  if (options.length === 1) {
    //console.log(options.length);
    MsgServices?.map((data) => {
      options.push({
        value: data?.Category,
        id: data?.OptionID,
        label: data?.Category,
      });
    });
  }

  let uploadIcon = (
    <img
      src={`${ICON_PATH}/upload.svg`}
      width="18px"
      height="18px"
      alt={getFieldValue(fields, "lblPrint")}
    />
  );
  let closeIcon = (
    <img
      src={`${ICON_PATH}/cross1.jpg`}
      width="18px"
      height="18px"
      alt={getFieldValue(fields, "lblPrint")}
      onClick={() => deleteAttachments()}
    />
  );

  const [selectedFile, setSelectedFile] = useState();
  const [selectedDetails, setSelectedDetails] = useState([]);
  const [fileUploadFlag, setFileUploadFlag] = useState();
  let listsOfBase64Strings = [];
  let strval = [];

  const [fileUploadErrorDialog, setFileUploadErrorDialog] = useState({
    message: "",
    isLoading: false,
  });

  const confirmToClose = (choose) => {
    handleFileUploadErrorDialog("", false);
  };
  const handleFileUploadErrorDialog = (message, isLoading) => {
    setFileUploadErrorDialog({
      message,
      isLoading,
    });
  };

  const supportedFileTypes: string[] = [
    "application/pdf",
    "image/png",
    "image/jpg",
    "image/jpeg",
    "application/doc",
    "application/ms-doc",
    "application/msword",
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    "application/excel",
    "application/vnd.ms-excel",
    "application/x-excel",
    "application/x-msexcel",
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  ];

  const sizeLimit: numer = 5 * 1048576;

  const [dialog, setCancelConfirmationDialog] = useState({
    message: "",
    isLoading: false,
  });

  const handleDialog = (message, isLoading) => {
    setCancelConfirmationDialog({
      message,
      isLoading,
    });
  };

  const handleOnCancel = () => {
    handleDialog(<Text field={fields.Cancelpopup} />, true);
  };

  const confirmToCancel = (choose) => {
    if (choose) {
      document.getElementById("create-message-form").reset();
      setSelectedDetails([]);
      handleDialog("", false);
    } else {
      handleDialog("", false);
    }
  };

  const changeHandler = async (event) => {
    let intval = 0;
    if (selectedDetails.length > 0) {
      intval = 3 - selectedDetails.length;
    } else if (event.target.files.length >= 3) {
      intval = 3;
    } else {
      intval = event.target.files.length;
    }

    for (let i = 0; i < intval; i++) {
      const fileTypeError = hasFileTypeError(
        event.target.files[i],
        supportedFileTypes
      );
      const fileSizeError = hasFileSizeError(event.target.files[i], sizeLimit);
      /*const fileUploadFlag = !(
              hasFileTypeError(event.target.files[i], supportedFileTypes) ||
              hasFileSizeError(event.target.files[i], sizeLimit)
            );*/

      const fileUploadFlag = hasFileTypeError(
        event.target.files[i],
        supportedFileTypes
      );

      //setFileUploadFlag(fileTypeError);

      if (fileTypeError) {
        handleFileUploadErrorDialog(
          <Text field={fields.FileErrorpopup} />,
          true
        );
      } else if (fileSizeError) {
        handleFileUploadErrorDialog(
          <Text field={fields.FileErrorSizePopup} />,
          true
        );
      } else {
        setSelectedFile(event.target.files[i]);
        if (selectedDetails.length > 0 && i == 0) {
          strval.push(selectedDetails[0]);
          if (selectedDetails.length > 1) {
            strval.push(selectedDetails[1]);
          }
        }
        if (
          event.target.files[i] &&
          selectedDetails.filter(
            (str) => str.name == event.target.files[i].name
          ).length == 0
        ) {
          strval.push(event.target.files[i]);
        }
        if (strval.length > 0) {
          setSelectedDetails(strval);
        }
      }
    }
  };

  const hasFileTypeError = (file: File, supportedFileTypes): boolean =>
    file && !supportedFileTypes.includes(file.type);

  const hasFileSizeError = (file: File, sizeLimit): boolean =>
    file && file.size > sizeLimit;

  const deleteAttachments = (val) => {
    setSelectedDetails(selectedDetails.filter((str) => str.name != val));
  };

  const resetFormUpload = () => {
    setSelectedDetails([]);
  };

  const getBase64String1 = (selectedFile) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(selectedFile);
      reader.onload = () => {
        resolve(reader.result.split(",")[1]);
        //resolve(reader.result);
        //console.log(reader.result.split(",")[1]);
      };
      reader.onerror = (error) => {
        console.log("Error:::" + error);
      };
    });
  };

  const onLoad = (fileString) => {
    console.log(fileString);
    listsOfBase64Strings.push(fileString);
  };

  const nurseAlertContent = (
    <div className="contact-modal">
      <div className="update-header">
        <h4>
          <img
            className="contact-info"
            src={
              isCABrightcareMember
                ? `${ICON_PATH}/CHP_error_outline-24px.svg`
                : `${ICON_PATH}/error_outline-24px.svg`
            }
            alt={fields?.nurseAdviceLinePopUpTitle}
          />

          <Text field={fields?.nurseAdviceLinePopUpTitle ?? ""}></Text>
        </h4>

        <p className="contact-content">
          <Text field={fields?.nurseAdviceLinePopUpDescription ?? ""} />
        </p>
      </div>

      <div className="contact-btns btns">
        <button
          type="button"
          className="btn btn-default"
          data-mdb-dismiss="modal"
          onClick={closeModal}
        >
          <Text field={fields?.nurseAdviceLineCancelBtnTxt} />
        </button>
        <button
          type="button"
          className="btn btn-primary"
          data-mdb-dismiss="modal"
          onClick={closeModal}
        >
          <Text field={fields?.nurseAdviceLineContinueBtnTxt ?? ""} />
        </button>
      </div>
    </div>
  );
  //NM implementation for Grievanice & Appeals --start

  const grievancesAlertContent = (
    <div className="contact-modal">
      <div className="update-header">
        <h4>
          <img
            className="contact-info"
            src={`${ICON_PATH}/error_outline-24px.svg`}
            alt={fields?.nurseAdviceLinePopUpTitle}
          />
          <Text field={fields?.nurseAdviceLinePopUpTitle ?? ""}></Text>
        </h4>
        <p className="contact-content">
          <Text field={fields?.grievancesPopUp} />
          <a
            className="link-design p-0 text-zoom-lg m-l-1 NM-text-decoration contact-content"
            href={getFieldValue(fields, "lnkHelp")?.href}
          >
            {getFieldValue(fields, "lnkHelp").text}
          </a>
        </p>
      </div>

      <div className="contact-btns btns">
        <button
          type="button"
          className="btn btn-default"
          data-mdb-dismiss="modal"
          onClick={closeModal}
        >
          <Text field={fields?.nurseAdviceLineCancelBtnTxt} />
        </button>
        <button
          type="button"
          className="btn btn-primary"
          data-mdb-dismiss="modal"
          onClick={closeModal}
        >
          <Text field={fields?.nurseAdviceLineContinueBtnTxt ?? ""} />
        </button>
      </div>
    </div>
  );
  //NM implementation for Grievanice & Appeals -ENd
  let nurseListItems = null;
  approachList
    .filter((o) => o?.fields?.sortBy?.value === DISPLAY_POSITION)
    .map((nal) => {
      nurseListItems = nal?.fields;
    });
  const successContentNurse = (
    <>
      <div className="contact-modal">
        <div className="update-header">
          <h5>
            <Text field={fields?.messageSubmittedTitle}></Text>
          </h5>
          {isMedicare ? (
            <p className="contact-content">
              <Text field={fields?.succesMsgNALMcareSpecific} />
            </p>
          ) : (
            <p className="contact-content">
              <Text field={fields?.succesMsgNALSpecific} />
            </p>
          )}
          <hr />
          <h5 className="contact-header header">
            <Text field={fields?.nurseAdviceLineText} />
          </h5>
          <div className="contact-content content para">
            <RichText field={nurseListItems?.timings1} />
          </div>
          <div>
            <p className="contact-content content">
              <Text field={nurseListItems?.phoneNumber1} />
            </p>
            <p className="contact-content content para1">
              <Text field={nurseListItems?.phoneNumber2 ?? ""} />
            </p>
            <p className="contact-content content para1 para2">
              <Text field={nurseListItems?.tty1 ?? ""} />
            </p>
          </div>
        </div>
        <div className="contact-button">
          <button
            type="button"
            className="btn btn-primary"
            data-mdb-dismiss="modal"
            onClick={closeModal}
          >
            <Text field={fields?.nurseAdviceLineCloseBtnTxt} />
          </button>
        </div>
      </div>
    </>
  );

  return (
    <>
      <div className="container">
        <div className="row">
          <div className="col-xl-2 col-md-3">
            <div className="page-heading">
              <Text field={fields.heading} />
            </div>
          </div>
        </div>
      </div>
      <div className="row inbox-container">
        <div className="col-xl-12 col-md-12 mess-top-head">
          <Formik
            initialValues={{
              category: "",
              subject: "",
              description: "",
              contactMethodOH: "",
              contactMethodOther: "",
              Attachments: "",
            }}
            validationSchema={
              MemberStateName == "OH" ? SignupSchema : WithoutSignupSchema
            }
            onSubmit={async (values) => {
              console.log(values);
              console.log(selectedDetails);
              setSpinner(true);

              for (let i = 0; i < selectedDetails.length; i++) {
                const base64String = await getBase64String1(selectedDetails[i]);
                listsOfBase64Strings.push(base64String);
              }
              var res1 = await MessageAPI.CreateNewConversation({
                Subject: values?.subject,
                CategoryID: values?.category,
                Category: categoryText,
                Description: values?.description.trim(),
                PreferredCommunication: values?.contactMethodOH,
                NotificationChannel: values?.contactMethodOther,
                Attachments: "",
              }).catch((err) => {
                setSpinner(false);
                setError({ err });
              });

              var responseConversationId =
                res1?.ResultData?.conversation?.ConversationId;

              for (let i = 0; i < listsOfBase64Strings.length; i++) {
                var res2 = await MessageAPI.setAttachment({
                  ConversationId: responseConversationId,
                  Body: listsOfBase64Strings[i],
                  Name: selectedDetails[i].name,
                });
                var resfinal = res2;
              }
              var flag1 = res1?.Message;
              var flag2 = res2?.Message;

              if (
                listsOfBase64Strings.length == 0 &&
                res1?.Message === "Success"
              ) {
                setSuccessMess(true);
                setSpinner(false);
              } else if (
                listsOfBase64Strings.length > 0 &&
                res1?.Message === "Success" &&
                res2?.Message === "Success"
              ) {
                setSuccessMess(true);
                setSpinner(false);
              } else {
                setSuccessMess(false);
                setSpinner(true);
              }

              if (values.contactMethodOH === "Phone") {
                if (
                  (categoryText ===
                    "NAL (Línea de asesoramiento de enfermería)" ||
                    categoryText === "NAL (Nurse Advice Line)") &&
                  res1?.Message === "Success"
                ) {
                  nurseAdviceLineModal();
                } else {
                  setCheckContactMethod(fields?.MessageSendSuccessOHPhone);
                }
              } else {
                if (
                  (categoryText ===
                    "NAL (Línea de asesoramiento de enfermería)" ||
                    categoryText === "NAL (Nurse Advice Line)") &&
                  res1?.Message === "Success"
                ) {
                  nurseAdviceLineModal();
                } else {
                  setCheckContactMethod(fields?.MessageSendSuccess);
                }
              }

              console.log("categoryText", categoryText);
              ResetMessForm(values);
            }}
          >
            {({ touched, errors, values, setFieldValue }) => (
              <Form id="create-message-form">
                <div>
                  <div className="head-create-mess">
                    <div className="crt-mess-heading">
                      <Text field={fields.sendAMessagetitle} />
                    </div>
                    <div className="subhead-crt-mess">
                      <RichText field={fields.needAssistance} />
                    </div>
                    <div className="subhead-crt-mess-text">
                      <RichText field={fields.AttachmentInfo} />
                    </div>
                    <div className="crt-mess-heading">
                      <Text field={fields?.messageDetails} />
                    </div>
                    <div className="row">
                      <div className="row-title">
                        <Text field={fields?.lblCategory} />
                        {options.lengths}
                        <ErrorMessage
                          name="category"
                          component={InlineFormError}
                        />
                      </div>
                      <div className="dropdown-menu-width">
                        <Field
                          as="select"
                          id="category"
                          name="category"
                          options={options}
                          value={
                            options
                              ? options.find(
                                  (option) => option.value === values.category
                                )
                              : ""
                          }
                          component={CustomSelect}
                          onChange={handleDropdownChange}
                          customclass={` ${highlightBorderOnError(
                            errors.category,
                            touched.category
                          )}`}
                          isFormik={true}
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="row-title">
                        <Text field={fields?.lblSubject} />
                      </div>
                      <div className="dropdown-menu-width">
                        <Field
                          id="subject"
                          name="subject"
                          placeholder={getFieldValue(fields, "lblSubjectPH")}
                          className="form-control"
                          maxLength={250}
                        />
                        <ErrorMessage
                          name="subject"
                          component={InlineFormError}
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="row-title">
                        <Text field={fields?.lblDescription} />
                        <ErrorMessage
                          name="description"
                          component={InlineFormError}
                        />
                      </div>
                      <div className="row-sub-text">
                        <Text field={fields?.lblDescinfo} />
                      </div>
                      <div className="txt-area-width">
                        <Field
                          name="description"
                          id="description"
                          as={`textarea`}
                          maxLength={32000}
                          rows={3}
                          placeholder={getFieldValue(
                            fields,
                            "lblDescriptionPH"
                          )}
                          className={`form-control ${highlightBorderOnError(
                            errors.description,
                            touched.description
                          )}`}
                        />
                        <div className="row-sub-text">
                          <Text field={fields?.lblMaxchars} />
                          {countDisplay(values.description)}{" "}
                          <Text field={fields?.lblremaining} />
                        </div>
                      </div>
                    </div>
                    {MemberStateName == "OH" && (
                      <div className="row">
                        <div className="row-title">
                          <Text field={fields?.lblPreferedContactMethod} />
                          <ErrorMessage
                            name="contactMethodOH"
                            component={InlineFormError}
                          />
                        </div>
                        <div className="row-sub-text">
                          <Text field={fields?.lblChoosereplymsg} />
                        </div>
                        <div className="txt-area-width">
                          <div role="group" aria-labelledby="my-radio-group">
                            <label className="label-radio">
                              <Field
                                type="radio"
                                name="contactMethodOH"
                                value="Message Center"
                              />
                              &nbsp;
                              <Text field={fields?.lblMessageCenter} />
                            </label>
                            <br />
                            <label className="label-radio">
                              <Field
                                type="radio"
                                name="contactMethodOH"
                                value="Phone"
                              />
                              &nbsp;
                              <Text field={fields?.lblPhone} />
                            </label>
                          </div>
                        </div>
                      </div>
                    )}
                    {!(
                      categoryText === NAL_CATEGORY_EN ||
                      categoryText === NAL_CATEGORY_ES
                    ) && (
                      <div className="row">
                        <div className="row-title">
                          <Text field={fields?.lblAttachments} />
                        </div>
                        <div>
                          <label
                            className="custom-file-upload"
                            title={getFieldValue(fields, "lblchoosefilestt")}
                          >
                            <input
                              type="file"
                              name="file"
                              multiple
                              disabled={
                                selectedDetails.length == 3 ? true : false
                              }
                              className="btn btn-upload"
                              onChange={changeHandler}
                            />
                            <i className="fa fa-cloud-upload" />
                            {uploadIcon}
                            <Text field={fields?.lblChoosefiles} />
                          </label>
                        </div>
                        <div className="label-attachments">
                          <Text field={fields?.InfoAttachment} />
                        </div>
                        <div className="row filedetails">
                          {selectedDetails?.map((file, index) => {
                            return (
                              <React.Fragment key={file.name}>
                                <div className="label-file-upload">
                                  <table
                                    style={{
                                      border: "1px solid lightblue",
                                      margintop: "3px",
                                    }}
                                  >
                                    <tr>
                                      <td> {file.name}</td>
                                      <td style={{ marginleft: "178px" }}>
                                        <img
                                          src={`${ICON_PATH}/cross1.jpg`}
                                          width="10px"
                                          height="13px"
                                          margin-left="178px"
                                          margin-top="4px"
                                          margin-bottom="3px"
                                          color="red"
                                          className="img-close"
                                          alt={getFieldValue(
                                            fields,
                                            "lblPrint"
                                          )}
                                          onClick={() =>
                                            deleteAttachments(file.name)
                                          }
                                        />
                                      </td>
                                    </tr>
                                  </table>
                                </div>
                              </React.Fragment>
                            );
                          })}
                        </div>
                      </div>
                    )}
                    {((MemberStateName == "OH" &&
                      values.contactMethodOH === "Message Center") ||
                      MemberStateName != "OH") &&
                      !(
                        categoryText === NAL_CATEGORY_EN ||
                        categoryText === NAL_CATEGORY_ES
                      ) && (
                        <div className="row">
                          <div className="row-radio-btn-sub-text">
                            <Text field={fields?.lblSelectOption} />
                          </div>
                          <div className="txt-area-width">
                            <div role="group" aria-labelledby="my-radio-group">
                              <label className="label-radio">
                                <Field
                                  type="radio"
                                  name="contactMethodOther"
                                  value="Send me a text message"
                                />
                                &nbsp;
                                <Text field={fields?.Sendmetext} />
                              </label>
                              <br />
                              <label className="label-radio">
                                <Field
                                  type="radio"
                                  name="contactMethodOther"
                                  value="Send me an email"
                                />
                                &nbsp;
                                <Text field={fields?.Sendmeemail} />
                              </label>
                            </div>
                          </div>
                        </div>
                      )}
                  </div>
                  <div className=" row create-mess-snd-btn">
                    {successMess != true && (
                      <div className="float-right">
                        <button
                          type="submit"
                          disabled={
                            isTermedMember || isSupportUser ? true : false
                          }
                          title={getFieldValue(fields, "lblSendtt")}
                          className={
                            isTermedMember || isSupportUser
                              ? "btn btn-primary btn-margin btn-send-termed"
                              : "btn btn-primary btn-margin btn-send"
                          }
                        >
                          <Text field={fields?.btnsend} />
                        </button>

                        <button
                          type="button"
                          onClick={handleOnCancel}
                          title={getFieldValue(fields, "lblCanceltt")}
                          className="btn btn-primary btn-margin btn-cancel"
                        >
                          <Text field={fields?.btncancel} />
                        </button>
                        {dialog.isLoading && (
                          <CancelConfirmationDialog
                            onDialog={confirmToCancel}
                            message={dialog.message}
                            fields={fields}
                          ></CancelConfirmationDialog>
                        )}
                        {fileUploadErrorDialog.isLoading && (
                          <FileUploadErrorDialog
                            message={fileUploadErrorDialog.message}
                            onDialog={confirmToClose}
                            isCABrightcareMember={isCABrightcareMember}
                          ></FileUploadErrorDialog>
                        )}
                      </div>
                    )}
                    {successMess === true && (
                      <div className="float-right">
                        <button
                          type="button"
                          className="btn btn-primary btn-margin btn-send"
                          onClick={() => handleOnClickBackHone()}
                        >
                          <Text field={fields?.btnBacktohomepage} />
                        </button>
                      </div>
                    )}
                  </div>
                  {successMess == true &&
                    !(
                      categoryText === NAL_CATEGORY_EN ||
                      categoryText === NAL_CATEGORY_ES
                    ) && (
                      <div className="row success-msg">
                        <RichText field={checkContactMethod} />
                      </div>
                    )}
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </>
  );
};
export default ContactSendaMessage;
// const ContactSendaMessage = ({
//   fields,
//   sendAMsgList,
//   errors,
//   touched,
//   resetForm,
//   setSubmitting,
//   approachList,
//   values,
//   setFieldValue,
//   handleChange,
//   handleSubmit,
//   isValid,
// }) => {
//   const { openModal, closeModal } = useContext(ModalContext);
//   const { setSpinner } = useContext(SpinnerContext);
//   const { setError } = useContext(ErrorContext);
//   const [sendButton, setSendButton] = useState(true);
//   const [valueChanged, setValueChanged] = useState(false);
//   const [succesMsgNALStateSpecific, setsuccesMsgNALStateSpecific] = useState(
//     {}
//   );

//   useEffect(() => {
//     if (sendAMsgList?.length > 0)
//       setsuccesMsgNALStateSpecific(
//         sendAMsgList[0]?.fields?.succesMsgNALStateSpecific
//       );
//   }, [sendAMsgList]);

//   let generalQuestions = GENERALQUESTIONS;
//   let nurseAdviceLine = NURSEADVICELINE;

//   let {
//     memberLOB,
//     MemberStateName,
//     programId,
//     homePhone,
//     prefEmailId,
//     cellPhone,
//     prefPhone,
//   } = useContext(AppContext);

//   useEffect(() => {
//     getCaptcha();
//     if (
//       isValid &&
//       values.message !== "" &&
//       values.phone !== "" &&
//       values.email !== ""
//     ) {
//       setSendButton(false);
//     } else {
//       setSendButton(true);
//     }
//   }, [isValid, values]);

//   try {
//     const nurseAlertContent = (
//       <div className="contact-modal">
//         <div className="update-header">
//           <h4>
//             <img
//               className="contact-info"
//               src={`${ICON_PATH}/error_outline-24px.svg`}
//               alt={fields?.nurseAdviceLinePopUpTitle}
//             />
//             <Text field={fields?.nurseAdviceLinePopUpTitle ?? ""}></Text>
//           </h4>
//           <p className="contact-content">
//             <Text field={fields?.nurseAdviceLinePopUpDescription ?? ""} />
//           </p>
//         </div>
//         <div className="contact-btns btns">
//           <button
//             type="button"
//             className="btn btn-default"
//             data-mdb-dismiss="modal"
//             onClick={closeModal}
//           >
//             <Text field={fields?.nurseAdviceLineCancelBtnTxt} />
//           </button>
//           <button
//             type="button"
//             className="btn btn-primary"
//             data-mdb-dismiss="modal"
//             onClick={closeModal}
//           >
//             <Text field={fields?.nurseAdviceLineContinueBtnTxt ?? ""} />
//           </button>
//         </div>
//       </div>
//     );

//     const successContentGeneralQns = (
//       <div className="contact-modal">
//         <div className="update-header">
//           <h5>
//             <Text field={fields?.messageSubmittedTitle ?? ""}></Text>
//           </h5>
//           <p>
//             <Text field={fields?.succesMsgGeneralQuestions ?? ""} />
//           </p>
//         </div>
//         <div className="contact-btn">
//           <button
//             type="button"
//             className="btn btn-primary"
//             data-mdb-dismiss="modal"
//             onClick={(e) => {
//               setSendButton(true);
//               closeModal();
//             }}
//           >
//             <Text field={fields?.generalQuestionsOkayBtnTxt ?? ""} />
//           </button>
//         </div>
//       </div>
//     );
//     let nurseListItems = null;
//     const setNurseListItems = approachList
//       .filter((o) => o?.fields?.sortBy?.value === DISPLAY_POSITION)
//       .map((nal) => {
//         nurseListItems = nal?.fields;
//       });

//     const successContentNurse = (
//       <>
//         <div className="contact-modal">
//           <div className="update-header">
//             <h5>
//               <Text field={fields?.messageSubmittedTitle}></Text>
//             </h5>
//             <p className="contact-content">
//               <Text field={succesMsgNALStateSpecific} />
//             </p>
//             <hr />
//             <h5 className="contact-header header">
//               <Text field={fields?.nurseAdviceLineText} />
//             </h5>
//             <div className="contact-content content para">
//               <RichText field={nurseListItems?.timings1} />
//             </div>
//             <div>
//               <p className="contact-content content">
//                 <Text field={nurseListItems?.phoneNumber1 } />
//               </p>
//               <p className="contact-content content para1">
//                 <Text field={nurseListItems?.phoneNumber2 ?? ""} />
//               </p>
//               <p className="contact-content content para1 para2">
//                 <Text field={nurseListItems?.tty1 ?? ""} />
//               </p>
//             </div>
//           </div>
//           <div className="contact-button">
//             <button
//               type="button"
//               className="btn btn-primary"
//               data-mdb-dismiss="modal"
//               onClick={(e) => {
//                 setSendButton(true);
//                 closeModal();
//               }}
//             >
//               <Text field={fields?.nurseAdviceLineCloseBtnTxt} />
//             </button>
//           </div>
//         </div>
//       </>
//     );

//     const options = [
//       {
//         value: generalQuestions,
//         id: generalQuestions,
//         label: <Text field={fields?.generalQuestionsText} />,
//       },
//       {
//         value: nurseAdviceLine,
//         id: nurseAdviceLine,
//         label: (
//           <span>
//             <Text field={fields?.nurseAdviceLineText} />
//             <img
//               src={`${ICON_PATH}/info-teal.svg`}
//               className="img-fluid select-info-icon img-pad state-color"
//             />
//             <span className="blue-text state-color">
//               <Text field={fields?.nurseAdviceLineDisclaimer} />
//             </span>
//           </span>
//         ),
//       },
//     ];

//     const nurseAdviceLineDropDownAlert = () => {
//       openModal({
//         content: nurseAlertContent,
//         version: 1,
//       });
//     };

//     const generalQnsModal = () => {
//       openModal({
//         content: successContentGeneralQns,
//         version:1,
//       });
//     };

//     const nurseAdviceLineModal = () => {
//       openModal({
//         content: successContentNurse,
//         version: 2,
//       });
//     };

//     const IsValueChanged = () => {
//       if (!valueChanged) setValueChanged(true);
//     };
//     const handleDropdownChange = (selectedOption) => {
//       {
//         selectedOption.id === selectorsearch && nurseAdviceLineDropDownAlert();
//       }
//     };

//     const updateFormData = (formContent) => {
//       const phoneNumber = formContent.phone.replace(/[^\d]/g, "");
//       var updatedNumber = [
//         phoneNumber?.slice(0, 3),
//         " ",
//         phoneNumber?.slice(3, 6),
//         " ",
//         phoneNumber?.slice(6),
//       ].join("");
//       const updatedFormContent = {
//         email: formContent.email,
//         message: formContent.message,
//         phone: updatedNumber,
//         reason: formContent.reason,
//       };

//       return updatedFormContent;
//     };

//     function formatPhoneNumber(value) {
//       if (!value) return value;
//       const phoneNumber = value?.replace(/[^\d]/g, "");
//       const phoneNumberLength = phoneNumber?.length;
//       if (phoneNumberLength < 4) return phoneNumber;
//       else if (phoneNumberLength < 7) {
//         return `(${phoneNumber?.slice(0, 3)}) ${phoneNumber?.slice(3)}`;
//       }
//       return `(${phoneNumber?.slice(0, 3)}) ${phoneNumber?.slice(
//         3,
//         6
//       )}-${phoneNumber?.slice(6, 10)}`;
//     }
//     const handleInput = (e) => {
//       handleChange(e);
//       const formattedPhoneNumber = formatPhoneNumber(e?.target?.value);
//       const formattedPhoneNumber2 = formatPhoneNumber(e?.target?.value);
//       setFieldValue("phone1", formattedPhoneNumber + formattedPhoneNumber2);
//     };

//     return (
//       <div className="row" id="dummyIdforLanguage">
//         <BoxContainer
//           sectionClass={"send-msg-section d-flex justify-content-center"}
//         >
//           <Box title={fields?.sendAMessagetitle}>
//             <WhoIsAuthorized
//               authorize={{
//                 [SUPPORT_USER]: false,
//                 [TERMED_MEMBER]: true,
//               }}
//               handleClick={(e) => {
//                 handleSubmit(values);
//                 e.preventDefault();
//                 const updatedFormData = updateFormData(values);
//                 if (
//                   updatedFormData?.reason === "" ||
//                   updatedFormData?.reason === undefined ||
//                   updatedFormData?.phone === "" ||
//                   updatedFormData?.phone === undefined ||
//                   updatedFormData?.email === "" ||
//                   updatedFormData?.email === undefined ||
//                   updatedFormData?.message === "" ||
//                   updatedFormData?.message === undefined
//                 ) {
//                   return 0;
//                 } else if (updatedFormData?.reason === generalQuestions) {
//                   setSpinner(true);
//                   ContactUsAPI.SendGeneralQuestionsContactUs({
//                     Message: updatedFormData?.message,
//                     Reason: updatedFormData?.reason,
//                     Phone: updatedFormData?.phone,
//                     EmailID: updatedFormData?.email,
//                   })
//                     .then((response) => {
//                       setSpinner(false);
//                       if (response?.ResultCode === SUCCESS_RESULT_CODE)
//                         generalQnsModal();
//                     })
//                     .catch((err) => {
//                       setSpinner(false);
//                       setError({ err });
//                     });
//                 } else {
//                   setSpinner(true);
//                   ContactUsAPI.SendCareNetNALForContactUs({
//                     Message: updatedFormData?.message,
//                     contractName: CONTRACT_NAME,
//                     Phone: updatedFormData?.phone,
//                     EmailID: updatedFormData?.email,
//                   })
//                     .then((response) => {
//                       setSpinner(false);
//                       if (response?.ResultCode === SUCCESS_RESULT_CODE)
//                         nurseAdviceLineModal();
//                     })
//                     .catch((err) => {
//                       setSpinner(false);
//                       setError({ err });
//                     });
//                 }
//                 resetForm();
//                 setSubmitting(false);
//               }}
//               isFormik
//             >
//               {({ handleClick, disabledClass }) => {
//                 return (
//                   <Form onSubmit={handleClick}>
//                     <div className="grid-2col-container">
//                       <div className="grid-item">
//                         <div className="row-title">
//                           <Text field={fields?.lblCategory} />
//                         </div>
//                         <Field
//                           as="select"
//                           id="reason"
//                           name="reason"
//                           options={options}
//                           component={CustomSelect}
//                           onChange={handleDropdownChange}
//                           className="form-select"
//                         />
//                         <ErrorMessage
//                           name="reason"
//                           component={InlineFormError}
//                         />
//                       </div>
//                       <div className="grid-item">
//                         <div className="row-title">
//                           <Text field={fields?.lblSubject} />
//                         </div>
//                         <Field
//                           name="phone"
//                           id="phone1"
//                           onChange={handleInput}
//                           className={`form-control ${highlightBorderOnError(
//                             errors.phone,
//                             touched.phone
//                           )}`}
//                         />
//                         <ErrorMessage
//                           name="phone"
//                           component={InlineFormError}
//                         />
//                       </div>
//                       <div className="grid-item">
//                         <div className="row-title">
//                           <Text field={fields?.lblDescription} />
//                         </div>
//                         <Field
//                           name="message"
//                           id="message"
//                           as={`textarea`}
//                           maxLength={2000}
//                           rows={3}
//                           className={`form-control ${highlightBorderOnError(
//                             errors.message,
//                             touched.message
//                           )}`}
//                         />
//                         <ErrorMessage
//                           name="message"
//                           component={InlineFormError}
//                         />
//                       </div>
//                       <div className="grid-item">
//                         <div className="row-title">
//                           <Text field={fields?.lblAttachments} />
//                         </div>
//                       </div>
//                     </div>
//                     <div className="col">
//                       <div className="col-xl-12">
//                         <div className="float-right d-none d-sm-block padding-10">
//                           <button
//                             disabled={sendButton}
//                             type="submit"
//                             className="btn btn-primary"
//                           >
//                             <Text field={fields?.btnsend} />
//                           </button>
//                         </div>
//                       </div>
//                       <div className="col-xl-12">
//                         <div className="float-right d-none d-sm-block padding-10">
//                           <button
//                             disabled={sendButton}
//                             type="submit"
//                             className="btn btn-primary"
//                           >
//                             <Text field={fields?.btncancel} />
//                           </button>
//                         </div>
//                       </div>
//                     </div>
//                   </Form>
//                 );
//               }}
//             </WhoIsAuthorized>
//           </Box>
//         </BoxContainer>
//       </div>
//     );
//   } catch (e) {
//     console.log(e);
//   }
// };

// const FormikSendAMessage = withFormik({
//   mapPropsToValues({ reason, phone, email, message }) {
//     return {
//       reason: reason || "a_grnlQns",
//       phone: phone || "",
//       email: email || "",
//       message: message || "",
//     };
//   },
//   validationSchema: ({ fields }) => {
//     return yup.object().shape({
//       reason: yup
//         .string()
//         .required(getFieldValue(fields, "reasonIsRequired", false)),
//       phone: yup
//         .string()
//         .required(getFieldValue(fields, "phoneNumerIsRequired", false))
//         .length(14, getFieldValue(fields, "phoneNumerLength", false))
//         .matches(
//           PHONE_VALID_REGEX,
//           getFieldValue(fields, "phoneNumerValid", false)
//         ),
//       email: yup
//         .string()
//         .required(getFieldValue(fields, "emailIsRequired", false))
//         .matches(
//           EMAIL_REGEX_CONTACT,
//           getFieldValue(fields, "emailValid", false)
//         ),
//       message: yup
//         .string()
//         .required(getFieldValue(fields, "messageIsRequired", false)),
//     });
//   },
// })(ContactSendaMessage);

// export default FormikSendAMessage;
