import React, { useContext } from "react";
import { AppContext } from "./../../contexts/app.context";
import { getFieldValue } from "@sitecore-jss/sitecore-jss-react";
import NavLink from "./NavLink";
import Program from "./Program";
import { isNYMDCHP } from "./../../utils/helpers";
import {
  DEPENDANT_18AndOVER,
  DEPENDANT_ACCESS_GRANTED_ROLEID,
  PRIMARY_SUBSCRIBER_ROLEID,
  SUBSCRIBER,
  NY,
  MEDICAID,
  NY_MD_CHP_PROGRAMID,
  NY_MD_NMC_PROGRAMID,
  IL,
  CA,
  CA_PROGID_LIST_HIDE_MYDOC,
  IL_MLTSS_PROGRAM,
} from "../../define.constants";

const NavigationLinks = (props) => {
  const { fields, tabshow, onMyBillClick } = props;

  const {
    isSwitchedToDependent,
    isDependentLoggedIn,
    roleId,
    isSwitchedView,
    switchedUserDependentCat,
    switchedUserType,
    MemberStateName,
    memberLOB,
    programId,
  } = useContext(AppContext);
  const isNYChipMedicaid =
    memberLOB?.toLowerCase() === MEDICAID?.toLowerCase() &&
    MemberStateName === NY &&
    programId === NY_MD_CHP_PROGRAMID; //|| programId === NY_MD_NMC_PROGRAMID);
  const healthRecordVisibility = () => {
    if (
      (isDependentLoggedIn &&
        isSwitchedView &&
        switchedUserType === SUBSCRIBER) || //Conditions for 1486 & 1490
      (!isDependentLoggedIn &&
        isSwitchedView &&
        switchedUserDependentCat === DEPENDANT_18AndOVER) //Conditions for 1489
    ) {
      return false;
    } else {
      return true;
    }
  };
  const isMyBillDisabled = () => {
    if (
      roleId === PRIMARY_SUBSCRIBER_ROLEID ||
      roleId == DEPENDANT_ACCESS_GRANTED_ROLEID ||
      isSwitchedToDependent === true ||
      isNYChipMedicaid
    ) {
      return false;
    } else {
      return true;
    }
  };
  const urlDisabled = isMyBillDisabled();

  const isMedicaidMember = memberLOB?.toLowerCase() === MEDICAID?.toLowerCase();
  /* Medthod returning flag to Hide MyDoctor for 
  IL - MLTSS Medicaid
  CA - EAE Medicaid
  CA - MMP Opt Out & Dual Opt Out converted to Medicaid ProgIDs  
  */
  const showMyDoctor = () => {
    let showMyDoctorFlag = true;
    // Medicaid Common check
    const isMedicaidCommonCheck = isMedicaidMember && programId;
    const trimmedProgramID = programId?.trim();

    // State & ProgramID wise check to hide MyDoctor
    if (isMedicaidCommonCheck) {
      switch (MemberStateName.trim()) {
        case CA:
          showMyDoctorFlag = CA_PROGID_LIST_HIDE_MYDOC.includes(
            trimmedProgramID
          )
            ? false
            : true;
          break;
        case IL:
          showMyDoctorFlag =
            trimmedProgramID === IL_MLTSS_PROGRAM ? false : true;
          break;
        default:
          showMyDoctorFlag = true;
      }
    }

    return showMyDoctorFlag;
  };

  return (
    <>
      {!urlDisabled &&
        ((getFieldValue(fields, "MyBill", false)?.href &&
          !getFieldValue(fields, "MyBill", false)?.programId) ||
          (getFieldValue(fields, "MyBill", false)?.href &&
            getFieldValue(fields, "MyBill", false)?.programId?.includes(
              programId
            ))) && (
          <>
            <NavLink
              onClick={onMyBillClick}
              className={`cursor-pointer navbar-brand custom-tooltip ${
                urlDisabled ? "disabled-link" : ""
              }`.trim()}
            >
              {getFieldValue(fields, "MyBill", false)?.text}
              {/* {(!isSwitchedToDependent ||  */}
              {/* {isDependentLoggedIn && ( */}
              <span className="custom-tooltip-text">
                {getFieldValue(fields, "myBillInfo", false) ?? {}}
              </span>
              {/* )} */}
            </NavLink>
          </>
        )}
      <NavLink
        href={getFieldValue(fields, "MyCoverage", false)?.href}
        className="navbar-brand custom-tooltip"
      >
        {getFieldValue(fields, "MyCoverage", false)?.text}
        <span className="custom-tooltip-text">
          {getFieldValue(fields, "myCoverageInfo", false) ?? {}}
        </span>
      </NavLink>
      <NavLink
        href={getFieldValue(fields, "MyIDCard", false)?.href}
        className="navbar-brand custom-tooltip"
      >
        {getFieldValue(fields, "MyIDCard", false)?.text}
        <span className="custom-tooltip-text">
          {getFieldValue(fields, "myIDCardInfo", false) ?? {}}
        </span>
      </NavLink>
      {tabshow == true && showMyDoctor() && (
        <>
          {Program(fields, tabshow)}
          <NavLink
            href={getFieldValue(fields, "MyDoctor", false)?.href}
            className="navbar-brand custom-tooltip"
          >
            {getFieldValue(fields, "MyDoctor", false)?.text}
            <span className="custom-tooltip-text">
              {getFieldValue(fields, "myDoctorInfo", false) ?? {}}
            </span>
          </NavLink>
        </>
      )}
      {healthRecordVisibility() && (
        <NavLink
          href={getFieldValue(fields, "MyHealthRecords", false)?.href}
          className="navbar-brand custom-tooltip"
        >
          {getFieldValue(fields, "MyHealthRecords", false)?.text}
          <span className="custom-tooltip-text">
            {getFieldValue(fields, "myHealthRecordsInfo", false) ?? {}}
          </span>
        </NavLink>
      )}

      <NavLink
        href={getFieldValue(fields, "MyResources", false)?.href}
        className="navbar-brand custom-tooltip"
      >
        {getFieldValue(fields, "MyResources", false)?.text}
        <span className="custom-tooltip-text">
          {getFieldValue(fields, "myResourcesInfo", false) ?? {}}
        </span>
      </NavLink>
      <NavLink
        href={getFieldValue(fields, "MyWellness", false)?.href}
        className="navbar-brand custom-tooltip"
      >
        {!isNYMDCHP(memberLOB, MemberStateName, programId)
          ? getFieldValue(fields, "MyWellness", false)?.text
          : "My Wellness"}
        <span className="custom-tooltip-text">
          {getFieldValue(fields, "myWellnessInfo", false) ?? {}}
        </span>
      </NavLink>
      {fields["RxSS"] ? (
        fields["RxSS"].value?.href !== "" ? (
          <NavLink
            href={getFieldValue(fields, "RxSS", false)?.href}
            className="navbar-brand custom-tooltip"
          >
            {getFieldValue(fields, "RxSS", false)?.text}
            <span className="custom-tooltip-text">
              {getFieldValue(fields, "rxSSInfo", false) ?? {}}
            </span>
          </NavLink>
        ) : (
          ""
        )
      ) : (
        ""
      )}
    </>
  );
};
export default NavigationLinks;
