import React, { useContext, useEffect, useState } from "react";
import { Text, getFieldValue } from "@sitecore-jss/sitecore-jss-react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import LinksList from "./LinksList";
import {
  ICON_PATH,
  NM,
  MEDICAID,
  NM_MCAID_PROGID,
  GLOBAL_VARIABLE,
} from "../../define.constants";
import { AppContext } from "./../../contexts/app.context";
import { getStateLobItemListConfiguration } from "./../../utils/helpers";
import { SSORedirectProvider } from "../../contexts/SSORedirect.context";

const HomeHelpfulLinks = ({ fields }) => {
  let { memberLOB, MemberStateName, isTermedMember, programId } =
    useContext(AppContext);

  const [helpfulLinksList, setHelpfulLinksList] = useState([]);
  GLOBAL_VARIABLE.genesysIntent = getFieldValue(fields, "intent");

  useEffect(() => {
    setTimeout(function () {
      if (
        document.querySelector(".home-helpful-links .slick-dots") === undefined
      )
        document.querySelector(
          ".home-helpful-links .carousel-btns"
        ).style.display = "none";
    }, 500);
  }, []);

  useEffect(() => {
    if (MemberStateName && memberLOB && programId) {
      setHelpfulLinksList(
        getStateLobItemListConfiguration(
          fields,
          MemberStateName,
          memberLOB,
          programId
        )
      );
    }
  }, [MemberStateName, memberLOB, programId]);

  // Flag to show message for termed members instead of HelpfulLinks
  let isSpcialTermedMember = false;

  // MEDICAID Check- NM
  const isMedicaidMember = memberLOB?.toLowerCase() === MEDICAID;
  isSpcialTermedMember =
    isMedicaidMember &&
    MemberStateName?.toUpperCase() === NM &&
    programId === NM_MCAID_PROGID &&
    isTermedMember;

  return (
    <SSORedirectProvider>
      <>
        <div className="row home-helpful-links">
          <div className="col-md-12">
            <div className="card-title">
              <Text field={fields.heading} />

              {!isSpcialTermedMember ? (
                <div className="carousel-btns">
                  <a
                    className="carousel-btn-prev"
                    onClick={() =>
                      document
                        .querySelector(
                          ".home-helpful-links .slick-slider .slick-prev"
                        )
                        .click()
                    }
                  >
                    <img
                      src={`${ICON_PATH}/Group_2349_2.svg`}
                      alt={getFieldValue(fields, "previousAlt")}
                    />
                  </a>{" "}
                  <a
                    className="carousel-btn-next"
                    onClick={() =>
                      document
                        .querySelector(
                          ".home-helpful-links .slick-slider .slick-next"
                        )
                        .click()
                    }
                  >
                    <img
                      src={`${ICON_PATH}/Group_2344.svg`}
                      alt={getFieldValue(fields, "nextAlt")}
                    />
                  </a>
                </div>
              ) : null}
            </div>
            {isSpcialTermedMember ? (
              <Text field={fields.TermedMemberMessage} />
            ) : (
              <LinksList helpfulLinksList={helpfulLinksList} />
            )}
          </div>
        </div>
      </>
    </SSORedirectProvider>
  );
};

export default HomeHelpfulLinks;
