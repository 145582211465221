import { useContext, useEffect, useState } from "react";
import { SpinnerContext } from "../contexts/spinner.context";
import { AppContext } from "../contexts/app.context";
import * as CTContactInforAccess from "../services/HeathRecordAPI";
import { ErrorContext } from "../contexts/error.context";
import { MY_HEALTH_RECORD_CONTACT_INFO } from "../define.widgets";
const qs = require("qs");

const useCTContactInforAccess = (
  page = 1,
  recordsPerPage,
  fetchData = true,
  memberNumber,
  Language
) => {
  const { setInnerSpinner } = useContext(SpinnerContext);
  const { setError } = useContext(ErrorContext);
  const {
    MemberStateName,
    memberRKSID,
    programId,
    planId,
    memberLOB,
    memberId,
    prefLang,
  } = useContext(AppContext);
  const [isCTContactInfoLoaded, setCTContactInfoLoaded] = useState(false);
  const [totalRecords, setTotalRecords] = useState(0);
  const [totalHistoryRecords, setTotalHistoryRecords] = useState(0);
  const [ctInfoAccessList, setCTInfoAccessList] = useState([]);
  const [careCoordinationHistory, setCareCoordinationHistory] = useState([]);
  const [requestingEntityList, setRequestingEntityList] = useState([]);
  const [careTeamFullObj, setCareTeamFullObj] = useState([]);
  const [careCoordinationTX, setCareCoordinationTX] = useState([]);
  const [isSubmitAllowed, setisSubmitAllowed] = useState(false);
  const [refreshAPI, setRefreshAPI] = useState(false);

  const setData = (data) => {
    setCareTeamFullObj(data);
    setCareCoordinationHistory(
      data?.ResultData?.memberHealthDtls?.OHCareCoordinationHistory
    );
    setCTInfoAccessList(
      data?.ResultData?.memberHealthDtls?.OHCareTeamContactInfo
    );
    setRequestingEntityList(data?.ResultData?.RequestingEntity);
    setCTContactInfoLoaded(true);
    if (
      data?.ResultData?.memberHealthDtls?.OHCareTeamContactInfo !== null &&
      data?.ResultData?.memberHealthDtls?.OHCareTeamContactInfo !== undefined
    ) {
      setTotalRecords(
        data?.ResultData?.memberHealthDtls?.OHCareTeamContactInfo.length
      );
    }
    if (
      data?.ResultData?.memberHealthDtls?.OHCareCoordinationHistory !== null &&
      data?.ResultData?.memberHealthDtls?.OHCareCoordinationHistory !==
        undefined
    )
      setTotalHistoryRecords(
        data.ResultData?.memberHealthDtls.OHCareCoordinationHistory.length
      );
  };
  const setTXData = (data) => {
    setCareCoordinationTX(data?.ResultData?.requestCareCoordinations);
    setisSubmitAllowed(data?.ResultData?.isSubmitAllowed);
    setTotalHistoryRecords(data.ResultData?.requestCareCoordinations.length);
  };
  useEffect(() => {
    if (refreshAPI) {
      callApi();
    } else {
      callApi();
    }
  }, [refreshAPI]);

  const callApi = () => {
    let startDate = "",
      endDate = "",
      provName = "";
    let query = {
      Page: 1,
      RecordsPerPage: 1,
      PreferredLang: prefLang,
    };

    if (fetchData) {
      setInnerSpinner(true, MY_HEALTH_RECORD_CONTACT_INFO);
      CTContactInforAccess.getCareTeamContactInfoAndAccess(
        {
          Page: "1",
          RecordsPerPage: "2",
          PreferredLang: prefLang,
        },
        qs.stringify(query, { addQueryPrefix: true })
      )
        .then((response) => {
          if (response != undefined && response != null) {
            //console.log(response);
            if (
              MemberStateName == "TX" ||
              (MemberStateName == "NM" && programId == "QMXREG020003")
            ) {
              setTXData(response);
              if (refreshAPI) {
                setRefreshAPI(false);
              }
            } else {
              setData(response);
            }
          }
        })
        .catch(function (err) {
          setError({ err });
        })
        .finally(function () {
          setInnerSpinner(false, MY_HEALTH_RECORD_CONTACT_INFO);
        });
    }
  };
  const refreshAPIDataAll = () => {
    setRefreshAPI(true);
  };

  return {
    careCoordinationHistory,
    careCoordinationTX,
    ctInfoAccessList,
    requestingEntityList,
    isCTContactInfoLoaded,
    totalRecords,
    careTeamFullObj,
    totalHistoryRecords,
    isSubmitAllowed,
    refreshAPIDataAll,
  };
};

export { useCTContactInforAccess };
