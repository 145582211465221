import React, { useContext } from "react";
import { IMAGE_PATH, LA_PLAN_ID } from "../../../../../define.constants";
import { AppContext } from "../../../../../contexts/app.context";
import { Text, RichText } from "@sitecore-jss/sitecore-jss-react";

const ViewIdCardCAMedicareCHPEAE = ({
  fields,
  result,
  details,
  idCard,
  drugInfo,
}) => {
  const { planId } = useContext(AppContext);
  console.log("BineRX", result?.RxBin);
  const formatToday = () => {
    let date = new Date();
    return `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`;
  };
  return (
    <>
      <div className="col-xxl-6 col-xl-6 col-lg-12 col-md-12 col-sm-12 col-xs-12">
        <p className="text-align-center">
          <Text field={fields.lblFront} />
        </p>
        <div
          style={{
            backgroundImage: `url(${IMAGE_PATH}/id/NonMP/CA/Medicare/CA-Medicare-CHP-EAE-Front.png)`,
          }}
          className="background-idcard CHP-front-id"
          id="containerCACHP"
        >
          <table
            width="100%"
            border="0"
            cellPadding="0"
            cellSpacing="0"
            id="memberCurrentID"
          >
            <tbody>
              <tr>
                <td nowrap="nowrap" className="pb-1" colspan="2">
                  <b>
                    <Text field={fields.lblPlan} />
                  </b>
                  &nbsp;{idCard?.PlanName}
                </td>
              </tr>
              <tr>
                <td nowrap="nowrap" className="pb-1" colspan="2">
                  <b>
                    {result?.PortalDeductiblesMappingDetails[0]?.ContractPlanId}
                  </b>
                </td>
              </tr>
              <tr>
                <td nowrap="nowrap" className="pb-1" colspan="2">
                  <b>
                    <Text field={fields.lblMember} />
                  </b>
                  &nbsp; {idCard?.Member_Name}
                </td>
              </tr>

              <tr>
                <td className="pb-1">
                  <b>
                    <Text field={fields.lblID} />
                  </b>
                  &nbsp; {idCard?.OriginalMemberProfile?.Number}
                </td>
                <td>
                  <b>
                    <Text field={fields.lblIssueDate} />
                  </b>
                  &nbsp;{details.MemberEffectiveDate}
                </td>
              </tr>

              <tr>
                <td nowrap="nowrap" className="pb-1" colspan="2">
                  <b>
                    <Text field={fields.lblIssuer} />
                  </b>
                  &nbsp; <Text field={fields.lblIssuerNo} />
                </td>
              </tr>

              <tr>
                <td className="pb-1">
                  <b>
                    <Text field={fields.lblPcp} />
                  </b>
                  &nbsp;
                  {idCard?.PCPName_Current}
                </td>
                <td>
                  <b>
                    <Text field={fields.lblPrimaryCareNumber} />
                  </b>
                  &nbsp;
                  {idCard?.PCPPhone_Current}
                </td>
              </tr>
              <tr>
                <td className="pb-1">
                  <b>
                    <Text field={fields.lblGRPAndIPA} />
                  </b>
                  &nbsp;
                  {result?.Group_IPA}
                </td>
                <td>
                  <b>
                    <Text field={fields.lblPrimaryCareNumber} />
                  </b>
                  &nbsp;
                  {result?.PAYOR_PHONE}
                </td>
              </tr>
              <tr>
                <td nowrap="nowrap" className="pb-1" colspan="2">
                  <b>
                    <Text field={fields.lblCareCordinatorPH} />
                  </b>
                  &nbsp;
                  <Text field={fields.lblCareCordinatorPHData} />
                </td>
              </tr>
              <tr>
                <td className="pb-1" colspan="2">
                  <RichText field={fields.lblPrescDrugPlan} />
                </td>
              </tr>
              <tr>
                <td className="pb-1" colspan="2">
                  <Text field={fields.lblGroup} />
                  &nbsp;{result?.PortalDeductiblesMappingDetails[0]?.RxGroup}
                </td>
              </tr>
              <tr>
                <td className="pb-1" colspan="2">
                  <Text field={fields.lblBin} />
                  &nbsp;{result?.PortalDeductiblesMappingDetails[0]?.RxBIN}
                  &nbsp;&nbsp;&nbsp;
                  <Text field={fields.lblPCN} />
                  &nbsp;{result?.PortalDeductiblesMappingDetails[0]?.RxPCN}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div className="col-xxl-6 col-xl-6 col-lg-12 col-md-12 col-sm-12 col-xs-12">
        <p className="text-align-center">
          <Text field={fields.lblBack} />
        </p>
        <div
          style={{
            backgroundImage: `url(${IMAGE_PATH}/id/NonMP/CA/Medicare/CA-Medicare-CHP-NON_EAE-Back.png)`,
          }}
          className="member-id-card-back chp-back-id"
        >
          <div>
            <table width="100%" border="0" cellPadding="0" cellSpacing="0">
              <tr>
                <td colspan="2">
                  <div className="row-space member-id-card-semibold">
                    <Text field={fields?.lblIDBackHeading} />
                  </div>
                </td>
              </tr>
              <tr>
                <td className="pb-2" colspan="2">
                  <div className="row-space member-id-card-semibold">
                    <Text field={fields?.lblIDBackProvider} />
                  </div>
                </td>
              </tr>
              <tr>
                <td colspan="2">
                  <div>
                    <b>
                      <Text field={fields?.lblIDBackServices} />
                    </b>
                  </div>
                </td>
              </tr>
              <tr>
                <td className="pb-2" colspan="2">
                  <div className="row-space">
                    <b>
                      <Text field={fields?.lblIDBackServicesData} />
                    </b>
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <div className="row-space">
                    <b>
                      <Text field={fields?.lblMedicalClaims} />
                    </b>
                  </div>
                </td>
                <td>
                  <div className="row-space">
                    <b>
                      <Text field={fields?.lblbackPharmacy} />
                    </b>
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <div className="row-space">{result.PAYOR_NAME}</div>
                </td>
                <td>
                  <div className="row-space">
                    <Text field={fields?.lblbackPharmacyCVS} />
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <div className="row-space">{result.PAYOR_ADDRESS1}</div>
                </td>
                <td>
                  <div className="row-space">
                    <Text field={fields?.lblbackPharmacyAddress} />
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <div className="row-space">
                    {result.PAYOR_CITY}, &nbsp; {result.PAYOR_STATE}
                  </div>
                </td>
                <td>
                  <div className="row-space">
                    <Text field={fields?.lblbackPharmacyCity} />
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <div className="row-space">{result.PAYOR_ZIP}</div>
                </td>
                <td>
                  <div className="row-space">
                    <Text field={fields?.lblbackPharmacyHelpDesk} />
                  </div>
                </td>
              </tr>
              <tr>
                <td className="pb-1">
                  {result.PAYOR_PHONE}, &nbsp;
                  <Text field={fields?.lblTTY} />
                </td>
              </tr>
              <tr>
                <td>
                  <div className="row-space">
                    <b>
                      <Text field={fields?.lblchplink} />
                    </b>
                  </div>
                </td>
              </tr>
            </table>
          </div>
        </div>
      </div>
    </>
  );
};

export default ViewIdCardCAMedicareCHPEAE;
