import React from "react";
import { File } from "@sitecore-jss/sitecore-jss-react";
import {
  ICON_PATH,
  SUPPORT_USER,
  TERMED_MEMBER,
  MEDICARE,
  DUALS,
  MEDICAID,
  UT_PROGRAMID_26,
  NY,
  KY,
  CA,
  NY_FORMS_DOCUMENTS_LINK_ID,
  MS,
  MI,
  SC,
  ID,
  TX,
  IL,
  CA_MEDICAID_IMAGE,
  OH,
  WA,
  VA,
  year_2023,
  New_MEDICARE_CY2025_STATES,
} from "../../define.constants";
import { WhoIsAuthorized } from "../../common/WhoIsAuthorized";
export var createFileIconNMP = (
  formDoc,
  index,
  language,
  className,
  multiLanguage,
  tabName,
  cls_vname
) => {
  return formDoc &&
    formDoc.value !== null &&
    formDoc.value !== undefined &&
    formDoc.value !== "" ? (
    <File
      key={index}
      id="a_download1"
      field={formDoc}
      // className="dropdown-item"
      className={className}
      cls_vname={cls_vname}
      target="_blank"
    >
      {multiLanguage ? (
        <span>{language.value}</span>
      ) : (
        <img
          src={`${ICON_PATH}/Group_2641.svg`}
          alt={tabName?.value}
          id={`downloadIcon1${index}`}
        />
      )}
    </File>
  ) : multiLanguage ? (
    <span key={index} className={className}>
      {language.value}
    </span>
  ) : (
    <div>
      <a
        key={`a_download1${index}`}
        id="a_download1"
        href="#"
        className="list-icon-download"
        cls_vname={cls_vname}
      >
        <img
          src={`${ICON_PATH}/Group_2641.svg`}
          alt={tabName?.value}
          id={`downloadIcon2${index}`}
        />
      </a>
    </div>
  );
};

export var createLinkIconNMP = ({
  link,
  fromFile,
  index,
  language,
  className,
  multiLanguage,
  imageName,
  tabName,
  distItem,
  MemberStateName,
  memberLOB,
  name,
  planId,
  programId,
  selectedYear,
  isYearSpecific,
  byPassAccessControl = false,
  isNYAffinityMember,
  cy2024ImplementationYear,
  customPopupContent,
  cls_vname,
  isCABrightcareMember,
  enrollmentEffDate,
}) => {
  let helpfulLink = "";
  let actionUnavailableForHelpfulLink = false;
  if (link?.value?.href !== "" || link?.value?.href !== "http://#") {
    helpfulLink = link?.value?.href;
  } else {
    helpfulLink = "#";
  }
  if (
    (helpfulLink === "#" || helpfulLink === "" || helpfulLink === undefined) &&
    language < new Date().getFullYear()
  ) {
    actionUnavailableForHelpfulLink = true;
  }

  return name ? (
    getPlanDocIconNMP(
      distItem,
      MemberStateName,
      memberLOB,
      name,
      planId,
      programId,
      selectedYear,
      isYearSpecific,
      index,
      isNYAffinityMember,
      cy2024ImplementationYear,
      fromFile,
      cls_vname,
      imageName,
      isCABrightcareMember,
      enrollmentEffDate
    )
  ) : link &&
    ((link.value !== null && link.value !== undefined && link.value !== "") ||
      (link !== "" &&
        (link.value === null ||
          link.value === undefined ||
          link.value === ""))) ? (
    //     link.value === "")) ||
    // (link !== "" &&
    //   (link?.value?.href == "" || link?.value?.href == "http://#"))) ? (
    // <Link aria-label={link?.value?.href} field={link} className={className}>

    <WhoIsAuthorized
      type="link"
      href={helpfulLink}
      authorize={{
        [SUPPORT_USER]: true,
        [TERMED_MEMBER]: true,
      }}
      byPassAccessControl={byPassAccessControl}
      actionUnavailable={actionUnavailableForHelpfulLink}
      customMessage={customPopupContent}
    >
      {({ handleClick, href }) => {
        return (
          <a
            id={`a_downloadIcon${index}`}
            href={href}
            rel="noopener noreferrer"
            className={className}
            onClick={handleClick}
            cls_vname={cls_vname}
            {...(link.value !== null &&
            link.value !== undefined &&
            link.value !== ""
              ? link?.value?.href != "" &&
                link?.value?.href != "#" &&
                link?.value?.target !== null &&
                link?.value?.target !== undefined &&
                link?.value?.target !== ""
                ? { target: "_blank" }
                : {}
              : {})}
          >
            {multiLanguage ? (
              <span>
                {" "}
                {language.value != undefined ? language.value : language}
              </span>
            ) : (
              <img
                src={`${ICON_PATH}/${imageName}`}
                alt={tabName?.value}
                id={`downloadIcon1${index}`}
              />
            )}
          </a>
        );
      }}
    </WhoIsAuthorized>
  ) : // </Link>
  multiLanguage ? (
    <span key={index} className={className}>
      {language.value != undefined ? language.value : language}
    </span>
  ) : (
    <div>
      <a
        key={`a_download1${index}`}
        id="a_download1"
        href="#"
        className={className}
        cls_vname={cls_vname}
      >
        <img
          src={`${ICON_PATH}/${imageName}`}
          alt={tabName?.value}
          id={`downloadIcon2${index}`}
        />
      </a>
    </div>
  );
};

export var createLinkIconNMP_CY2024 = ({
  link,
  fromFile,
  index,
  language,
  className,
  multiLanguage,
  imageName,
  tabName,
  distItem,
  MemberStateName,
  memberLOB,
  name,
  planId,
  programId,
  selectedYear,
  isYearSpecific,
  byPassAccessControl = false,
  isNYAffinityMember,
  customPopupContent,
  cls_vname,
}) => {
  let helpfulLink = "";
  let actionUnavailableForHelpfulLink = false;
  if (link?.value?.href !== "" || link?.value?.href !== "http://#") {
    helpfulLink = link?.value?.href;
  } else {
    helpfulLink = "#";
  }
  if (
    (helpfulLink === "#" || helpfulLink === "" || helpfulLink === undefined) &&
    language < new Date().getFullYear()
  ) {
    actionUnavailableForHelpfulLink = true;
  }

  return name ? (
    getPlanDocIconNMP_CY2024(
      distItem,
      MemberStateName,
      memberLOB,
      name,
      planId,
      programId,
      selectedYear,
      isYearSpecific,
      index,
      isNYAffinityMember,
      fromFile,
      imageName
    )
  ) : link &&
    ((link.value !== null && link.value !== undefined && link.value !== "") ||
      (link !== "" &&
        (link.value === null ||
          link.value === undefined ||
          link.value === ""))) ? (
    //     link.value === "")) ||
    // (link !== "" &&
    //   (link?.value?.href == "" || link?.value?.href == "http://#"))) ? (
    // <Link aria-label={link?.value?.href} field={link} className={className}>

    <WhoIsAuthorized
      type="link"
      href={helpfulLink}
      authorize={{
        [SUPPORT_USER]: true,
        [TERMED_MEMBER]: true,
      }}
      byPassAccessControl={byPassAccessControl}
      actionUnavailable={actionUnavailableForHelpfulLink}
      customMessage={customPopupContent}
    >
      {({ handleClick, href }) => {
        return (
          <a
            id={`a_downloadIcon${index}`}
            href={href}
            rel="noopener noreferrer"
            className={className}
            onClick={handleClick}
            cls_vname={cls_vname}
            {...(link.value !== null &&
            link.value !== undefined &&
            link.value !== ""
              ? link?.value?.href != "" &&
                link?.value?.href != "#" &&
                link?.value?.target !== null &&
                link?.value?.target !== undefined &&
                link?.value?.target !== ""
                ? { target: "_blank" }
                : {}
              : {})}
          >
            {multiLanguage ? (
              <span>
                {" "}
                {language.value != undefined ? language.value : language}
              </span>
            ) : (
              <img
                src={`${ICON_PATH}/${imageName}`}
                alt={tabName?.value}
                id={`downloadIcon1${index}`}
              />
            )}
          </a>
        );
      }}
    </WhoIsAuthorized>
  ) : // </Link>
  multiLanguage ? (
    <span key={index} className={className}>
      {language.value != undefined ? language.value : language}
    </span>
  ) : (
    <div>
      <a
        key={`a_download1${index}`}
        id="a_download1"
        href="#"
        className={className}
        cls_vname={cls_vname}
      >
        <img
          src={`${ICON_PATH}/${imageName}`}
          alt={tabName?.value}
          id={`downloadIcon2${index}`}
        />
      </a>
    </div>
  );
};

const getPlanUrlNMP = (
  keys,
  distItem,
  param,
  param1,
  isYearSpecific,
  currentyear,
  name
) => {
  let planUrl = "#";
  if (keys !== null && keys !== undefined) {
    keys.forEach((item) => {
      if (
        item
          .toLocaleLowerCase()
          .includes((param + currentyear).toLocaleLowerCase()) ||
        (item
          .toLocaleLowerCase()
          .includes((param1 + "~" + currentyear).toLocaleLowerCase()) &&
          name === "Handbook") ||
        ((item.toLocaleLowerCase().includes(param.toLocaleLowerCase()) ||
          (item.toLocaleLowerCase().includes(param1.toLocaleLowerCase()) &&
            name === "Handbook")) &&
          item.includes("Common"))
      ) {
        planUrl = distItem?.phrases[item];
        return planUrl;
      }
      return planUrl;
    });
    return planUrl;
  }
};

const getPlanUrlNMP_CY2024 = (
  keys,
  distItem,
  param,
  param1,
  isYearSpecific,
  currentyear,
  name
) => {
  let planUrl = "#";
  const cy2024_Year = "2025";
  if (keys !== null && keys !== undefined) {
    keys.forEach((item) => {
      if (
        item
          .toLocaleLowerCase()
          .includes((param + cy2024_Year).toLocaleLowerCase()) ||
        (item
          .toLocaleLowerCase()
          .includes((param1 + "~" + cy2024_Year).toLocaleLowerCase()) &&
          name === "Handbook") ||
        ((item.toLocaleLowerCase().includes(param.toLocaleLowerCase()) ||
          (item.toLocaleLowerCase().includes(param1.toLocaleLowerCase()) &&
            name === "Handbook")) &&
          item.includes("Common"))
      ) {
        planUrl = distItem?.phrases[item];
        return planUrl;
      }
      return planUrl;
    });
    return planUrl;
  }
};

const getPlanDocIconNMP_CY2024 = (
  distItem,
  MemberStateName,
  memberLOB,
  name,
  planId,
  programId,
  selectedYear,
  isYearSpecific,
  index,
  isNYAffinityMember,
  fromFile,
  cls_vname,
  imageName
) => {
  var keys = distItem !== null ? Object.keys(distItem?.phrases) : null;
  let currentyear = new Date().getFullYear();
  let planurl = "#";
  let param1 = MemberStateName + `~` + memberLOB + `~` + name;
  let param = "";
  if (memberLOB.toLocaleLowerCase() == MEDICARE.toLocaleLowerCase()) {
    param =
      MemberStateName +
      `~` +
      memberLOB +
      `~` +
      name +
      `~` +
      programId +
      `~` +
      planId +
      `~`;
  } else if (memberLOB.toLocaleLowerCase() == DUALS.toLocaleLowerCase()) {
    if (MemberStateName == "TX") {
      param =
        MemberStateName +
        `~` +
        memberLOB +
        `~` +
        name +
        `~` +
        programId +
        `~` +
        planId +
        `~`;
    }
  } else if (memberLOB.toLocaleLowerCase() == MEDICAID.toLocaleLowerCase()) {
    param =
      MemberStateName +
      `~` +
      memberLOB +
      `~` +
      name +
      `~` +
      programId +
      `~` +
      planId +
      `~`;
  }

  if (memberLOB.toLocaleLowerCase() == MEDICAID.toLocaleLowerCase()) {
    if (programId == UT_PROGRAMID_26) {
      param =
        MemberStateName + `~` + memberLOB + `~` + name + `~` + programId + `~`;
    }
  }
  if (memberLOB.toLocaleLowerCase() == MEDICAID.toLocaleLowerCase()) {
    if (MemberStateName == NY) {
      param =
        MemberStateName +
        `~` +
        memberLOB +
        `~` +
        name +
        `~` +
        programId +
        `~` +
        isNYAffinityMember +
        `~`;
    }
  }
  if (memberLOB.toLocaleLowerCase() == MEDICAID.toLocaleLowerCase()) {
    if (
      MemberStateName == CA ||
      MemberStateName == IL ||
      MemberStateName == WA
    ) {
      param =
        MemberStateName + `~` + memberLOB + `~` + name + `~` + programId + `~`;
    }
  }

  if (memberLOB.toLocaleLowerCase() == MEDICAID.toLocaleLowerCase()) {
    if (MemberStateName == SC) {
      param =
        MemberStateName + `~` + memberLOB + `~` + name + `~` + programId + `~`;
    }
  }

  if (memberLOB.toLocaleLowerCase() == MEDICARE.toLocaleLowerCase()) {
    if (MemberStateName == KY) {
      param =
        MemberStateName + `~` + memberLOB + `~` + name + `~` + programId + `~`;
    }
  }
  if (memberLOB.toLocaleLowerCase() == DUALS.toLocaleLowerCase()) {
    if (MemberStateName == MI) {
      param =
        MemberStateName + `~` + memberLOB + `~` + name + `~` + programId + `~`;
    }
  }
  if (memberLOB.toLocaleLowerCase() == DUALS.toLocaleLowerCase()) {
    if (MemberStateName == ID) {
      param =
        MemberStateName + `~` + memberLOB + `~` + name + `~` + programId + `~`;
    }
  }
  if (memberLOB.toLocaleLowerCase() == MEDICAID.toLocaleLowerCase()) {
    if (MemberStateName == TX) {
      param =
        MemberStateName + `~` + memberLOB + `~` + name + `~` + programId + `~`;
    }
  }
  if (memberLOB.toLocaleLowerCase() == DUALS.toLocaleLowerCase()) {
    if (MemberStateName == IL) {
      param =
        MemberStateName + `~` + memberLOB + `~` + name + `~` + programId + `~`;
    }
  }
  if (memberLOB.toLocaleLowerCase() == DUALS.toLocaleLowerCase()) {
    if (MemberStateName == SC) {
      param =
        MemberStateName + `~` + memberLOB + `~` + name + `~` + programId + `~`;
    }
  }
  if (memberLOB.toLocaleLowerCase() == DUALS.toLocaleLowerCase()) {
    if (MemberStateName == OH) {
      param =
        MemberStateName + `~` + memberLOB + `~` + name + `~` + programId + `~`;
    }
  }

  planurl = getPlanUrlNMP_CY2024(
    keys,
    distItem,
    param,
    param1,
    isYearSpecific,
    currentyear,
    name
  );

  if (currentyear == distItem?.phrases.comingSoonYear && planurl === "#") {
    let comingSoonKey = MemberStateName + "~comingSoonYearUrl";
    planurl = distItem?.phrases[comingSoonKey];
  }
  let actionUnavailableForDocs = false;
  if (
    currentyear !== distItem?.phrases.comingSoonYear &&
    currentyear < new Date().getFullYear() &&
    planurl === "#"
  ) {
    actionUnavailableForDocs = true;
  }
  // for pdfs
  if (fromFile) {
    planurl = ICON_PATH + "/" + planurl;
  }
  return (
    <>
      <WhoIsAuthorized
        type="link"
        href={planurl}
        actionUnavailable={actionUnavailableForDocs}
      >
        {({ handleClick, href }) => {
          return (
            <a
              id={`a_downloadPlanIcon${index}`}
              href={planurl}
              {...(planurl !== "#" ? { target: "_blank" } : {})}
              onClick={handleClick}
              rel="noopener noreferrer"
              className="list-icon-download"
              cls_vname={cls_vname}
            >
              <img src={`${ICON_PATH}/${imageName}`} />
            </a>
          );
        }}
      </WhoIsAuthorized>
    </>
  );
};

const getPlanDocIconNMP = (
  distItem,
  MemberStateName,
  memberLOB,
  name,
  planId,
  programId,
  selectedYear,
  isYearSpecific,
  index,
  isNYAffinityMember,
  cy2024ImplementationYear,
  fromFile,
  cls_vname,
  imageName,
  isCABrightcareMember,
  enrollmentEffDate
) => {
  var keys = distItem !== null ? Object.keys(distItem?.phrases) : null;
  let currentyear = isCABrightcareMember
    ? new Date(enrollmentEffDate).getFullYear()
    : new Date().getFullYear();
  let planurl = "#";
  let param1 = MemberStateName + `~` + memberLOB + `~` + name;
  let param = "";
  if (memberLOB.toLocaleLowerCase() == MEDICARE.toLocaleLowerCase()) {
    param =
      MemberStateName +
      `~` +
      memberLOB +
      `~` +
      name +
      `~` +
      programId +
      `~` +
      planId +
      `~`;
  } else if (memberLOB.toLocaleLowerCase() == DUALS.toLocaleLowerCase()) {
    if (MemberStateName == "TX") {
      param =
        MemberStateName +
        `~` +
        memberLOB +
        `~` +
        name +
        `~` +
        programId +
        `~` +
        planId +
        `~`;
    }
  } else if (memberLOB.toLocaleLowerCase() == MEDICAID.toLocaleLowerCase()) {
    param =
      MemberStateName +
      `~` +
      memberLOB +
      `~` +
      name +
      `~` +
      programId +
      `~` +
      planId +
      `~`;
  }

  if (memberLOB.toLocaleLowerCase() == MEDICAID.toLocaleLowerCase()) {
    if (programId == UT_PROGRAMID_26) {
      param =
        MemberStateName + `~` + memberLOB + `~` + name + `~` + programId + `~`;
    }
  }
  if (memberLOB.toLocaleLowerCase() == MEDICAID.toLocaleLowerCase()) {
    if (MemberStateName == NY) {
      param =
        MemberStateName +
        `~` +
        memberLOB +
        `~` +
        name +
        `~` +
        programId +
        `~` +
        isNYAffinityMember +
        `~`;
    }
  }
  if (memberLOB.toLocaleLowerCase() == MEDICAID.toLocaleLowerCase()) {
    if (MemberStateName == IL || MemberStateName == WA) {
      param =
        MemberStateName + `~` + memberLOB + `~` + name + `~` + programId + `~`;
    }
  }

  if (memberLOB.toLocaleLowerCase() == MEDICAID.toLocaleLowerCase()) {
    if (MemberStateName == SC) {
      param =
        MemberStateName + `~` + memberLOB + `~` + name + `~` + programId + `~`;
    }
  }

  if (memberLOB.toLocaleLowerCase() == MEDICARE.toLocaleLowerCase()) {
    if (MemberStateName == KY) {
      param =
        MemberStateName + `~` + memberLOB + `~` + name + `~` + programId + `~`;
    }
  }
  if (memberLOB.toLocaleLowerCase() == DUALS.toLocaleLowerCase()) {
    if (MemberStateName == MI) {
      param =
        MemberStateName + `~` + memberLOB + `~` + name + `~` + programId + `~`;
    }
  }
  if (memberLOB.toLocaleLowerCase() == DUALS.toLocaleLowerCase()) {
    if (MemberStateName == ID) {
      param =
        MemberStateName + `~` + memberLOB + `~` + name + `~` + programId + `~`;
    }
  }
  if (memberLOB.toLocaleLowerCase() == MEDICAID.toLocaleLowerCase()) {
    if (MemberStateName == TX) {
      param =
        MemberStateName + `~` + memberLOB + `~` + name + `~` + programId + `~`;
    }
  }
  if (memberLOB.toLocaleLowerCase() == DUALS.toLocaleLowerCase()) {
    if (MemberStateName == IL) {
      param =
        MemberStateName + `~` + memberLOB + `~` + name + `~` + programId + `~`;
    }
  }
  if (memberLOB.toLocaleLowerCase() == DUALS.toLocaleLowerCase()) {
    if (MemberStateName == SC) {
      param =
        MemberStateName + `~` + memberLOB + `~` + name + `~` + programId + `~`;
    }
  }
  if (memberLOB.toLocaleLowerCase() == DUALS.toLocaleLowerCase()) {
    if (MemberStateName == OH) {
      param =
        MemberStateName + `~` + memberLOB + `~` + name + `~` + programId + `~`;
    }
  }

  planurl =
    memberLOB.toLocaleLowerCase() == MEDICARE.toLocaleLowerCase() &&
    New_MEDICARE_CY2025_STATES?.includes(MemberStateName?.trim()?.toLowerCase())
      ? getPlanUrlNMP_CY2024(
          keys,
          distItem,
          param,
          param1,
          isYearSpecific,
          currentyear,
          name
        )
      : getPlanUrlNMP(
          keys,
          distItem,
          param,
          param1,
          isYearSpecific,
          currentyear,
          name
        );

  if (currentyear == distItem?.phrases.comingSoonYear && planurl === "#") {
    let comingSoonKey = MemberStateName + "~comingSoonYearUrl";
    planurl = distItem?.phrases[comingSoonKey];
  }
  let actionUnavailableForDocs = false;
  if (
    currentyear !== distItem?.phrases.comingSoonYear &&
    currentyear < new Date().getFullYear() &&
    planurl === "#"
  ) {
    actionUnavailableForDocs = true;
  }
  // for pdfs
  if (fromFile) {
    planurl = ICON_PATH + "/" + planurl;
  }
  return (
    <>
      <WhoIsAuthorized
        type="link"
        href={planurl}
        actionUnavailable={actionUnavailableForDocs}
      >
        {({ handleClick, href }) => {
          return (
            <a
              id={`a_downloadPlanIcon${index}`}
              href={planurl}
              {...(planurl !== "#" ? { target: "_blank" } : {})}
              onClick={handleClick}
              rel="noopener noreferrer"
              className="list-icon-download"
              cls_vname={cls_vname}
            >
              <img src={`${ICON_PATH}/${imageName}`} />
            </a>
          );
        }}
      </WhoIsAuthorized>
    </>
  );
};
// Plan Resources - Helpful Link
export var createFileIconNMPHelpfulLink = (
  formDoc,
  index,
  language,
  className,
  multiLanguage,
  tabName,
  cls_vname
) => {
  return formDoc &&
    formDoc.value !== null &&
    formDoc.value !== undefined &&
    formDoc.value !== "" ? (
    <File
      key={index}
      id="a_download1"
      field={formDoc}
      // className="dropdown-item"
      className={className}
      target="_blank"
      cls_vname={cls_vname}
    >
      {multiLanguage ? (
        <span>{language.value}</span>
      ) : (
        <img
          src={`${ICON_PATH}/Group_2641.svg`}
          alt={tabName?.value}
          id={`downloadIcon1${index}`}
        />
      )}
    </File>
  ) : multiLanguage ? (
    <span key={index} className={className}>
      {language.value}
    </span>
  ) : (
    <div>
      <a
        key={`a_download1${index}`}
        id="a_download1"
        href="#"
        className="list-icon-download"
        cls_vname={cls_vname}
      >
        <img
          src={`${ICON_PATH}/Group_2641.svg`}
          alt={tabName?.value}
          id={`downloadIcon2${index}`}
        />
      </a>
    </div>
  );
};

export var createLinkIconNMPHelpfulLink = ({
  link,
  index,
  language,
  className,
  multiLanguage,
  imageName,
  tabName,
  distItem,
  MemberStateName,
  memberLOB,
  name,
  planId,
  programId,
  selectedYear,
  isYearSpecific,
  byPassAccessControl = false,
  isNYAffinityMember,
  cls_vname,
  isCABrightcareMember,
}) => {
  let helpfulLink = "";
  let actionUnavailableForHelpfulLink = false;
  if (link?.value?.href !== "" || link?.value?.href !== "http://#") {
    helpfulLink = link?.value?.href;
  } else {
    helpfulLink = "#";
  }
  if (
    (helpfulLink === "#" || helpfulLink === "" || helpfulLink === undefined) &&
    language < new Date().getFullYear()
  ) {
    actionUnavailableForHelpfulLink = true;
  }

  return name ? (
    getPlanDocIconNMPHelpfulLink(
      distItem,
      MemberStateName,
      memberLOB,
      name,
      planId,
      programId,
      selectedYear,
      isYearSpecific,
      index,
      isNYAffinityMember,
      cls_vname,
      isCABrightcareMember,
      imageName
    )
  ) : link &&
    ((link.value !== null && link.value !== undefined && link.value !== "") ||
      (link !== "" &&
        (link.value === null ||
          link.value === undefined ||
          link.value === ""))) ? (
    //     link.value === "")) ||
    // (link !== "" &&
    //   (link?.value?.href == "" || link?.value?.href == "http://#"))) ? (
    // <Link aria-label={link?.value?.href} field={link} className={className}>

    <WhoIsAuthorized
      type="link"
      href={helpfulLink}
      authorize={{
        [SUPPORT_USER]: true,
        [TERMED_MEMBER]: true,
      }}
      byPassAccessControl={byPassAccessControl}
      actionUnavailable={actionUnavailableForHelpfulLink}
    >
      {({ handleClick, href }) => {
        return (
          <a
            id={`a_downloadIcon${index}`}
            href={href}
            rel="noopener noreferrer"
            className={className}
            cls_vname={cls_vname}
            onClick={handleClick}
            {...(link.value !== null &&
            link.value !== undefined &&
            link.value !== ""
              ? link?.value?.href != "" &&
                link?.value?.href != "#" &&
                link?.value?.target !== null &&
                link?.value?.target !== undefined &&
                link?.value?.target !== ""
                ? { target: "_blank" }
                : {}
              : {})}
          >
            {multiLanguage ? (
              <span>
                {" "}
                {language.value != undefined ? language.value : language}
              </span>
            ) : (
              <img
                src={`${ICON_PATH}/${imageName}`}
                alt={tabName?.value}
                id={`downloadIcon1${index}`}
              />
            )}
          </a>
        );
      }}
    </WhoIsAuthorized>
  ) : // </Link>
  multiLanguage ? (
    <span key={index} className={className}>
      {language.value != undefined ? language.value : language}
    </span>
  ) : (
    <div>
      <a
        key={`a_download1${index}`}
        id="a_download1"
        href="#"
        className={className}
        cls_vname={cls_vname}
      >
        <img
          src={`${ICON_PATH}/${imageName}`}
          alt={tabName?.value}
          id={`downloadIcon2${index}`}
        />
      </a>
    </div>
  );
};

export const NYInclusion = (
  id,
  programId,
  MemberStateName,
  memberLOB,
  isNYAffinityMember
) => {
  const formDocLinks = NY_FORMS_DOCUMENTS_LINK_ID;
  let linkIds = [];

  // check if member is NY medicaid
  if (
    MemberStateName.trim().toUpperCase() != NY.trim().toUpperCase() ||
    memberLOB.trim().toUpperCase() != MEDICAID.trim().toUpperCase()
  ) {
    return true; // not subject to NY exclusion
  }

  // find matching entry in define.constants for form and documents
  formDocLinks.forEach((link) => {
    if (link.id === id?.value) {
      linkIds = link.supportedPrograms;
    }
  });

  // no match found
  if (linkIds.length < 1) {
    return true; //this is not an excuded item
  }

  let include = false;
  linkIds.forEach((program) => {
    // check if member program Id matches one in the fomrs and docs entry
    if (
      program.programId.trim().toUpperCase() === programId.trim().toUpperCase()
    ) {
      // check if program has the appropriate affinity statues
      if (
        (program.Affinity === "Y" && isNYAffinityMember) ||
        (program.Affinity === "N" && !isNYAffinityMember) ||
        program.Affinity === "B"
      ) {
        include = true; //link is applicable for this user
      }
    }
  });
  return include; //link is not applicable for this user
};

const getPlanUrlNMPHelpfulLink = (
  keys,
  distItem,
  param,
  param1,
  isYearSpecific,
  currentyear,
  name
) => {
  let planUrl = "#";
  if (keys !== null && keys !== undefined) {
    keys.forEach((item) => {
      if (
        item
          .toLocaleLowerCase()
          .includes((param + currentyear).toLocaleLowerCase()) ||
        (item
          .toLocaleLowerCase()
          .includes((param1 + "~" + currentyear).toLocaleLowerCase()) &&
          name === "Handbook") ||
        ((item.toLocaleLowerCase().includes(param.toLocaleLowerCase()) ||
          (item.toLocaleLowerCase().includes(param1.toLocaleLowerCase()) &&
            name === "Handbook")) &&
          item.includes("Common"))
      ) {
        planUrl = distItem?.phrases[item];
        return planUrl;
      }
      return planUrl;
    });
    return planUrl;
  }
};

const getPlanDocIconNMPHelpfulLink = (
  distItem,
  MemberStateName,
  memberLOB,
  name,
  planId,
  programId,
  selectedYear,
  isYearSpecific,
  index,
  isNYAffinityMember,
  cls_vname,
  isCABrightcareMember,
  imageName
) => {
  var keys = distItem !== null ? Object.keys(distItem?.phrases) : null;
  let planurl = "#";
  let param1 = MemberStateName + `~` + memberLOB + `~` + name;
  let param = "";
  if (memberLOB.toLocaleLowerCase() == MEDICARE.toLocaleLowerCase()) {
    param =
      MemberStateName +
      `~` +
      memberLOB +
      `~` +
      name +
      `~` +
      programId +
      `~` +
      planId +
      `~`;
  } else if (memberLOB.toLocaleLowerCase() == DUALS.toLocaleLowerCase()) {
    param =
      MemberStateName +
      `~` +
      memberLOB +
      `~` +
      name +
      `~` +
      programId +
      `~` +
      planId +
      `~`;
  } else if (memberLOB.toLocaleLowerCase() == MEDICAID.toLocaleLowerCase()) {
    param =
      MemberStateName +
      `~` +
      memberLOB +
      `~` +
      name +
      `~` +
      programId +
      `~` +
      planId +
      `~`;
  }
  if (memberLOB.toLocaleLowerCase() == MEDICAID.toLocaleLowerCase()) {
    if (programId == UT_PROGRAMID_26) {
      param =
        MemberStateName + `~` + memberLOB + `~` + name + `~` + programId + `~`;
    }
  }
  if (memberLOB.toLocaleLowerCase() == MEDICAID.toLocaleLowerCase()) {
    if (MemberStateName == NY) {
      param =
        MemberStateName +
        `~` +
        memberLOB +
        `~` +
        name +
        `~` +
        programId +
        `~` +
        isNYAffinityMember +
        `~`;
    }
  }
  if (memberLOB.toLocaleLowerCase() == MEDICARE.toLocaleLowerCase()) {
    if (MemberStateName == KY) {
      param =
        MemberStateName + `~` + memberLOB + `~` + name + `~` + programId + `~`;
    }
  }
  if (memberLOB.toLocaleLowerCase() == MEDICAID.toLocaleLowerCase()) {
    if (MemberStateName == MS) {
      param =
        MemberStateName + `~` + memberLOB + `~` + name + `~` + programId + `~`;
    }
  }
  if (memberLOB.toLocaleLowerCase() == DUALS.toLocaleLowerCase()) {
    if (MemberStateName == MI) {
      param =
        MemberStateName + `~` + memberLOB + `~` + name + `~` + programId + `~`;
    }
  }
  if (memberLOB.toLocaleLowerCase() == DUALS.toLocaleLowerCase()) {
    if (MemberStateName == ID) {
      param =
        MemberStateName + `~` + memberLOB + `~` + name + `~` + programId + `~`;
    }
  }
  if (memberLOB.toLocaleLowerCase() == DUALS.toLocaleLowerCase()) {
    if (MemberStateName == SC) {
      param =
        MemberStateName + `~` + memberLOB + `~` + name + `~` + programId + `~`;
    }
  }
  if (memberLOB.toLocaleLowerCase() == DUALS.toLocaleLowerCase()) {
    if (MemberStateName == IL) {
      param =
        MemberStateName + `~` + memberLOB + `~` + name + `~` + programId + `~`;
    }
  }
  if (memberLOB.toLocaleLowerCase() == DUALS.toLocaleLowerCase()) {
    if (MemberStateName == OH) {
      param =
        MemberStateName + `~` + memberLOB + `~` + name + `~` + programId + `~`;
    }
  }
  if (memberLOB.toLocaleLowerCase() == MEDICAID.toLocaleLowerCase()) {
    if (MemberStateName == WA) {
      param =
        MemberStateName + `~` + memberLOB + `~` + name + `~` + programId + `~`;
    }
  }
  let currentyear = new Date().getFullYear();
  if (isCABrightcareMember) {
    currentyear = selectedYear;
  }
  planurl = getPlanUrlNMPHelpfulLink(
    keys,
    distItem,
    param,
    param1,
    isYearSpecific,
    currentyear,
    name
  );

  if (currentyear == distItem?.phrases.comingSoonYear && planurl === "#") {
    let comingSoonKey = MemberStateName + "~comingSoonYearUrl";
    planurl = distItem?.phrases[comingSoonKey];
  }
  let actionUnavailableForDocs = false;
  if (
    currentyear !== distItem?.phrases.comingSoonYear &&
    currentyear < new Date().getFullYear() &&
    planurl === "#"
  ) {
    actionUnavailableForDocs = true;
  }
  return (
    <>
      <WhoIsAuthorized
        type="link"
        href={planurl}
        actionUnavailable={actionUnavailableForDocs}
      >
        {({ handleClick, href }) => {
          return (
            <a
              id={`a_downloadPlanIcon${index}`}
              href={planurl}
              {...(planurl !== "#" ? { target: "_blank" } : {})}
              onClick={handleClick}
              rel="noopener noreferrer"
              className="list-icon-newtab"
              cls_vname={cls_vname}
            >
              <img src={`${ICON_PATH}/${imageName}`} />
            </a>
          );
        }}
      </WhoIsAuthorized>
    </>
  );
};
