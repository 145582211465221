import { Box } from "../../common/Box";
import { BoxContainer } from "../../common/BoxContainer";
import { IMAGE_PATH, ICON_PATH } from "../../define.constants";
import React from "react";
import { Text, RichText } from "@sitecore-jss/sitecore-jss-react";

const CHPSpotLight = ({ props }) => {
  const { fields } = props;
  return (
    <div className="row trackmyspending vb">
      <BoxContainer>
        <Box title={fields.headingMedicare}>
          <div className="col-lg-12">
            <div className="row">
              <div className="col-lg-8 col-sm-12 mb-4">
                <div className="molina-progress-bar-list memberUsefulTools">
                  <table
                    width="100%"
                    cellPadding="0"
                    cellSpacing="0"
                    border="0"
                  >
                    <tr>
                      <td
                        nowrap="nowrap"
                        style={{
                          width: "3%",
                          font: "normal normal .72rem/.92rem campton-book",
                          fontSize: "12pt",
                        }}
                      >
                        <b>
                          <Text field={fields?.teleDocCHP} />
                        </b>
                      </td>
                      <td
                        style={{
                          paddingLeft: "3%",
                          font: "normal normal .72rem/.92rem campton-book",
                          fontSize: "12pt",
                        }}
                      >
                        <RichText field={fields?.teleDOCValueCHP} />
                        <a
                          href={fields?.teleDOCLinkCHP?.value?.href}
                          target="blank"
                        >
                          <Text field={fields?.learrningMore} />
                          <img
                            className="arr-icon"
                            src={`${ICON_PATH}/arrow-right-small.svg`}
                          />
                        </a>
                      </td>
                    </tr>
                    <tr>
                      <td
                        style={{
                          width: "3%",
                          font: "normal normal .72rem/.92rem campton-book",
                          fontSize: "12pt",
                        }}
                      >
                        <b>
                          <Text field={fields?.visionCHP} />
                        </b>
                      </td>
                      <td
                        style={{
                          paddingLeft: "3%",
                          font: "normal normal .72rem/.92rem campton-book",
                          fontSize: "12pt",
                        }}
                      >
                        <RichText field={fields?.visionValueCHP} />
                        <a
                          href={fields?.visionLinkCHP?.value?.href}
                          target="blank"
                        >
                          <Text field={fields?.visionSuffixCHP} />
                        </a>
                        <RichText field={fields?.visionAdditionalCHP} />
                      </td>
                    </tr>
                    <tr>
                      <td
                        style={{
                          width: "3%",
                          font: "normal normal .72rem/.92rem campton-book",
                          fontSize: "12pt",
                        }}
                      >
                        <b>
                          <Text field={fields?.annualScreeningsCHP} />
                        </b>
                      </td>
                      <td
                        style={{
                          paddingLeft: "3%",
                          font: "normal normal .72rem/.92rem campton-book",
                          fontSize: "12pt",
                        }}
                      >
                        <RichText field={fields?.annualScreeningsValueCHP} />
                      </td>
                    </tr>
                    <tr>
                      <td
                        style={{
                          width: "3%",
                          font: "normal normal .72rem/.92rem campton-book",
                          fontSize: "12pt",
                        }}
                      >
                        <b>
                          <Text field={fields?.healthyActsRewardPrgCHP} />
                        </b>
                      </td>
                      <td
                        style={{
                          paddingLeft: "3%",
                          font: "normal normal .72rem/.92rem campton-book",
                          fontSize: "12pt",
                        }}
                      >
                        <RichText
                          field={fields?.healthyActsRewardPrgValueCHP}
                        />
                      </td>
                    </tr>
                    <tr style={{ fontSize: "15px" }}>
                      <td className="h-100" style={{ width: "18%" }}>
                        <ul className="list-inline memberSocialIcon my-auto">
                          <li className="list-inline-item">
                            <a
                              rel="noreferrer"
                              href={fields?.linkedInLinkCHP?.value?.href}
                              target="_blank"
                            >
                              <img
                                src={`${IMAGE_PATH}/CHP_Capture4.png`}
                                className="img-fluid"
                              />
                            </a>
                          </li>
                          <li className="list-inline-item">
                            <a
                              href={fields?.facebookLinkCHP?.value?.href}
                              target="blank"
                            >
                              <img
                                src={`${IMAGE_PATH}/CHP_Capture1.png`}
                                className="img-fluid"
                              />
                            </a>
                          </li>
                        </ul>
                      </td>
                      <td
                        style={{
                          paddingLeft: "3%",
                          font: "normal normal .72rem/.92rem campton-book",
                          fontSize: "12pt",
                        }}
                      >
                        <Text field={fields?.SocialMediaCHP} />
                      </td>
                    </tr>
                  </table>
                </div>
              </div>
              <div className="col-lg-4 col-sm-12 mb-4">
                <div className="molina-progress-bar-list">
                  <div>
                    <picture className="img-mobile-app">
                      <img
                        src={`${IMAGE_PATH}/CHP-app-image.png`}
                        className="img-fluid spotlight img-mobile-app"
                      />
                    </picture>{" "}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Box>
      </BoxContainer>
    </div>
  );
};

export default CHPSpotLight;
