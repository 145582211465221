import { Box } from "../../common/Box";
import { BoxContainer } from "../../common/BoxContainer";
import {
  MEDICARE,
  IMAGE_PATH,
  ICON_PATH,
  NM,
  ID,
  IL,
  GLOBAL_VARIABLE,
  DUALS,
} from "../../define.constants";
import React, { useContext } from "react";
import { AppContext } from "../../contexts/app.context";
import { Text, File, getFieldValue } from "@sitecore-jss/sitecore-jss-react";
import CHPSpotLight from "../Home-SpotLight/CHPSpotLight";

const SpotLight = (props) => {
  const { fields } = props;
  const { memberLOB, MemberStateName, isCABrightcareMember } =
    useContext(AppContext);
  const isMedicare =
    memberLOB.toLowerCase() === MEDICARE || memberLOB.toLowerCase() === DUALS;

  const isMedicareSpotLight = memberLOB.toLowerCase() === MEDICARE;
  const isNMMedicare =
    memberLOB.toLowerCase() === MEDICARE && MemberStateName === NM;
  GLOBAL_VARIABLE.genesysIntent = getFieldValue(props?.fields, "intent");
  return isMedicare && !isCABrightcareMember ? (
    <div className="row trackmyspending vb">
      <BoxContainer>
        <Box title={fields.headingMedicare}>
          <div className="col-lg-12">
            <div className="row">
              <div className="col-lg-6 col-sm-12 mb-4">
                <div className="molina-progress-bar-list">
                  {isMedicareSpotLight ? (
                    <div
                      style={{
                        font: "normal normal .72rem/.92rem campton-book",
                        fontSize: "14pt",
                        textAlign: "center",
                        lineHeight: "26px",
                        marginBottom: "1rem",
                      }}
                      className="card-title"
                    >
                      <b>
                        <File
                          id="a_download1"
                          field={fields.formDoc}
                          // className="dropdown-item"
                          target="_blank"
                        >
                          <Text field={fields?.NewsLetter} />
                        </File>
                      </b>
                    </div>
                  ) : (
                    ""
                  )}

                  <table>
                    <tr style={{ fontSize: "15px" }}>
                      <td
                        nowrap="nowrap"
                        style={{
                          width: "28%",
                          font: "normal normal .72rem/.92rem campton-book",
                          fontSize: "12pt",
                        }}
                      >
                        <b>
                          <Text field={fields?.careConnection} />
                        </b>
                      </td>
                      <td
                        style={{
                          paddingLeft: "3%",
                          font: "normal normal .72rem/.92rem campton-book",
                          fontSize: "12pt",
                        }}
                      >
                        <Text field={fields?.careConnectionValue} />
                        <a href="https://www.MolinaCC.com" target="blank">
                          <Text field={fields?.lblMolinaMCCValue} />
                        </a>
                        <Text field={fields?.careConnectionSuffix} />
                        {/* <Text field={fields?.careConnectionValue} /> */}
                      </td>
                    </tr>
                    <div>&nbsp;</div>
                    <tr style={{ fontSize: "15px" }}>
                      <td
                        style={{
                          width: "3%",
                          font: "normal normal .72rem/.92rem campton-book",
                          fontSize: "12pt",
                        }}
                      >
                        <b>
                          <Text field={fields?.careGiving} />
                        </b>
                      </td>
                      <td
                        style={{
                          paddingLeft: "3%",
                          font: "normal normal .72rem/.92rem campton-book",
                          fontSize: "12pt",
                        }}
                      >
                        <Text field={fields?.careGivingValue} />
                        <br />
                        <a
                          href="https://www.molinacaregiving.com/"
                          target="blank"
                        >
                          <Text field={fields?.careGivingSuffix} />
                        </a>
                      </td>
                    </tr>
                    <div>&nbsp;</div>
                    {isNMMedicare ? (
                      <tr style={{ fontSize: "15px" }}>
                        <td style={{ width: "3%" }}>
                          <picture>
                            <img
                              src={`${IMAGE_PATH}/Pyx-Health-Logo-Color-Tagline.png`}
                              className="img-fluid"
                            />
                          </picture>{" "}
                        </td>
                        <td
                          style={{
                            paddingLeft: "3%",
                            font: "normal normal .72rem/.92rem campton-book",
                            fontSize: "12pt",
                          }}
                        >
                          <Text field={fields?.PYXHealthAppsHeadingValue} />
                          <a href="https://www.pyxhealth.com/" target="blank">
                            <Text field={fields?.PYXHealthAppHeadingSuffix} />
                          </a>
                        </td>
                      </tr>
                    ) : null}
                    <div>&nbsp;</div>
                    <tr style={{ fontSize: "15px" }}>
                      <td style={{ width: "3%" }}>
                        <picture>
                          <img
                            src={`${IMAGE_PATH}/psychhub-icon.png`}
                            className="img-fluid"
                            style={{ height: "32px", width: "114px" }}
                          />
                        </picture>{" "}
                      </td>
                      <td
                        style={{
                          paddingLeft: "3%",
                          font: "normal normal .72rem/.92rem campton-book",
                          fontSize: "12pt",
                        }}
                      >
                        <Text field={fields?.PsychHub} />
                        <a href="https://psychhub.com/" target="blank">
                          <Text field={fields?.learrningMore} />
                          <img
                            className="arr-icon"
                            src={`${ICON_PATH}/arrow-right-small.svg`}
                          />
                        </a>
                      </td>
                    </tr>

                    <div>&nbsp;</div>
                    {!isNMMedicare ? (
                      <tr style={{ fontSize: "15px" }}>
                        <td
                          style={{
                            width: "3%",
                            font: "normal normal .72rem/.92rem campton-book",
                            fontSize: "12pt",
                          }}
                        >
                          <b>
                            <Text field={fields?.fluAndVaccineHeading} />
                          </b>
                        </td>
                        <td
                          style={{
                            paddingLeft: "3%",
                            font: "normal normal .72rem/.92rem campton-book",
                            fontSize: "12pt",
                          }}
                        >
                          <Text field={fields?.fluAndVaccineValue} />
                        </td>
                      </tr>
                    ) : null}
                    <div>&nbsp;</div>
                    <tr style={{ fontSize: "15px" }}>
                      <td className="h-100" style={{ width: "18%" }}>
                        <ul className="list-inline memberSocialIcon my-auto">
                          <li className="list-inline-item">
                            {MemberStateName === IL && (
                              <a
                                href="https://www.facebook.com/MolinaHealthcareIL"
                                target="blank"
                              >
                                <img
                                  src={`${IMAGE_PATH}/Capture1.png`}
                                  className="img-fluid"
                                />
                              </a>
                            )}
                            {MemberStateName === ID && (
                              <a
                                href="https://www.facebook.com/MolinaHealthID"
                                target="blank"
                              >
                                <img
                                  src={`${IMAGE_PATH}/Capture1.png`}
                                  className="img-fluid"
                                />
                              </a>
                            )}
                            {MemberStateName === NM && (
                              <a
                                href="https://www.facebook.com/MolinaHealthNM"
                                target="blank"
                              >
                                <img
                                  src={`${IMAGE_PATH}/Capture1.png`}
                                  className="img-fluid"
                                />
                              </a>
                            )}
                          </li>
                          <li className="list-inline-item">
                            <a
                              href="https://www.youtube.com/user/MolinaHealthcare"
                              target="blank"
                            >
                              <img
                                src={`${IMAGE_PATH}/Capture5.png`}
                                className="img-fluid"
                              />
                            </a>
                          </li>
                          <li className="list-inline-item">
                            <a
                              href="https://twitter.com/molinahealth"
                              target="blank"
                            >
                              <img
                                src={`${IMAGE_PATH}/Capture2.png`}
                                className="img-fluid"
                              />
                            </a>
                          </li>
                          <li className="list-inline-item">
                            <a
                              href="https://www.instagram.com/molinahealth/"
                              target="blank"
                            >
                              <img
                                src={`${IMAGE_PATH}/Capture3.png`}
                                className="img-fluid"
                              />
                            </a>
                          </li>
                          <li className="list-inline-item">
                            <a
                              rel="noreferrer"
                              href={fields?.linkedInLink?.value?.href}
                              target="_blank"
                            >
                              <img
                                src={`${IMAGE_PATH}/Capture4.png`}
                                className="img-fluid"
                              />
                            </a>
                          </li>
                        </ul>
                      </td>
                      <td
                        style={{
                          paddingLeft: "3%",
                          font: "normal normal .72rem/.92rem campton-book",
                          fontSize: "12pt",
                        }}
                      >
                        <Text field={fields?.SocialMedia} />
                      </td>
                    </tr>
                  </table>
                </div>
              </div>
              <div className="col-lg-6 col-sm-12 mb-4">
                <div className="molina-progress-bar-list">
                  <div>
                    <picture className="img-mobile-app">
                      <img
                        src={`${IMAGE_PATH}/Mobile-app-image.png`}
                        className="img-fluid spotlight img-mobile-app"
                      />
                      <a href="https://apps.apple.com/us/app/my-molina/id1087995311">
                        <img
                          src={`${IMAGE_PATH}/Appstore.png`}
                          className="img-fluid spotlight img-appstore"
                        />
                      </a>
                      <a href="https://play.google.com/store/apps/details?id=com.molina.mobile.myhealthinhand&hl=en_US&gl=US&pli=1">
                        <img
                          src={`${IMAGE_PATH}/Google-PlayStore.png`}
                          className="img-fluid spotlight img-playstore"
                        />
                      </a>
                    </picture>{" "}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Box>
      </BoxContainer>
    </div>
  ) : isCABrightcareMember ? (
    <CHPSpotLight props={props} />
  ) : null;
};

export default SpotLight;
