import { getFieldValue, Text } from "@sitecore-jss/sitecore-jss-react";
import React, { useState, useContext, useEffect } from "react";
import { AppContext } from "./../../contexts/app.context";
import { Box } from "../../common/Box";
import { BoxContainer } from "../../common/BoxContainer";
import { InnerSpinner } from "../../common/Spinner";
import { useMessageAPI } from "../../hooks/useMessageAPI";
import { ICON_PATH } from "../../define.constants";
import {
  getSanitisedHTML,
  getFormattedDate,
  brightCarePortal,
} from "../../utils/helpers";
import { INBOX, GLOBAL_VARIABLE } from "../../define.constants";
import { SpinnerContext } from "../../contexts/spinner.context";
import { HOME_MY_MESSAGE_CARD } from "../../define.widgets";
const HomeMyMessages = ({ fields }) => {
  const { unreadInboxMsgCount, isCABrightcareMember } = useContext(AppContext);
  const { innerSpinnerHTML } = useContext(SpinnerContext);
  const { inboxMessageList, isLoading } = useMessageAPI(3, INBOX, 1, "Date");
  const defaultCount = getFieldValue(fields, "defaulCount");
  let { isTermedMember } = useContext(AppContext);
  let count =
    unreadInboxMsgCount === 0
      ? ""
      : unreadInboxMsgCount > parseInt(defaultCount)
      ? defaultCount
      : unreadInboxMsgCount;
  const learnMore = getFieldValue(fields, "learnMore");
  const sendMessagelink = getFieldValue(fields, "sendMessagelink");
  const viewAll = getFieldValue(fields, "viewAll");
  const handleRedirect = (message) => {
    location = `${learnMore.href}?mid=${message.MessageID}&iid=${message.InteractionId}&sfid=${message?.SFMessageID}`;
  };
  GLOBAL_VARIABLE.genesysIntent = getFieldValue(fields, "intent");

  const [isBrightCare, setIsBrightCare] = useState(null);

  useEffect(async () => {
    setIsBrightCare(await brightCarePortal());
  }, []);

  return (
    <BoxContainer
      classForWidth={"col-xl-4 col-lg-12 col-sm-12"}
      sectionClass={""}
    >
      <Box title={fields.heading} cardSize={"small"} count={count}>
        {innerSpinnerHTML?.[HOME_MY_MESSAGE_CARD]}
        <div className="message-send-msg">
          <a href="CreateNewMessage" className="mess-send-link">
            <Text field={fields.sendMessagelink} />
          </a>
        </div>{" "}
        {inboxMessageList?.length > 0 ? (
          <>
            <div className="messages-row">
              {inboxMessageList.slice(0, 3)?.map((message, index) => {
                let icon;
                if (isBrightCare != null && message.Status === "N") {
                  if (isBrightCare) {
                    icon = "CHP_Group_2647.svg";
                  } else {
                    icon = "Group_2647.svg";
                  }
                } else {
                  icon = "email-read.svg";
                }

                // let icon =  {isBrightCare != null &&
                //   message.Status === "N"
                //     ? isBrightCare
                //       ? "CHP_Group_2647.svg"
                //       : "Group_2647.svg"
                //     : "email-read.svg" };
                let messageIconClass =
                  message.Status === "N"
                    ? "img-molina-mail state-color"
                    : "img-molina-mail-read";
                return (
                  <React.Fragment key={message.MessageID}>
                    <div className="row">
                      <div
                        className="col-md-12 col-sm-12"
                        onClick={() => handleRedirect(message)}
                      >
                        <div className="card-msg-subject">
                          <img
                            alt={message.Subject}
                            className={messageIconClass}
                            src={`${ICON_PATH}/${icon}`}
                          />
                          <div>{message.Subject}</div>
                          <div className="messages-dt">
                            {getFormattedDate(message.SentDate, "Mon dd")}
                          </div>
                        </div>
                        {inboxMessageList.length === 1 && message.Body && (
                          <div className="card-msg-body">
                            {getSanitisedHTML(message.Body)}
                          </div>
                        )}
                        <div className="link-wrapper">
                          <a
                            href="#"
                            className="card-link custom-tooltip"
                            aria-label={learnMore.text + " Messages"}
                          >
                            {learnMore.text}
                            <span className="custom-tooltip-text">
                              <Text field={fields.learnMoreInfo} />
                            </span>
                            <img
                              alt={learnMore.text}
                              className="arr-icon"
                              src={`${ICON_PATH}/arrow-right-small.svg`}
                            />
                          </a>
                        </div>
                      </div>
                    </div>
                    {inboxMessageList[index + 1] && index < 2 && <hr />}
                  </React.Fragment>
                );
              })}
            </div>
            <div className="row card-link-cta-block">
              <div className="col-md-12 col-sm-12 text-center">
                <a
                  href={viewAll.href}
                  className="card-link"
                  aria-label={fields.text}
                >
                  {viewAll.text}
                  <img
                    alt={viewAll.text}
                    className="link-icon2"
                    src={`${ICON_PATH}/arrow-right.svg`}
                  />
                </a>
              </div>
            </div>
          </>
        ) : (
          <>
            <div className="row mb-5 mt-5">
              <div className="col-md-12 col-sm-12 pt-2">
                <h6 className="text-muted font-weight-normal text-align-center">
                  <Text field={fields.noMessage} />
                </h6>
              </div>
            </div>
            <div className="row card-link-cta-block">
              <div className="col-md-12 col-sm-12 text-center viewallnormalfont">
                <div>{getFieldValue(fields, "viewAll", false).text}</div>
              </div>
            </div>
          </>
        )}
      </Box>
    </BoxContainer>
  );
};

export default HomeMyMessages;
//againtocommitchanges
