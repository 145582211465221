import React, { useContext, useEffect, useState } from "react";
import AccountSettingsPersonalConfirmPopUp from "./../AccountSettings-Personal-ConfirmPopUp";
import { Text, getFieldValue } from "@sitecore-jss/sitecore-jss-react";
import { Formik, Form, Field } from "formik";
import { useMemberAPI } from "../../hooks/useMemberAPI";
import { SSORedirectProvider } from "../../contexts/SSORedirect.context";
import { AppContext } from "../../contexts/app.context";
import { SpinnerContext } from "../../contexts/spinner.context";
import { ACCOUNT_SETTINGS_MY_PROFILE } from "../../define.widgets";
import { useViewCommunicationPreferences } from "../../hooks/useViewCommunicationPreferences";
import { useViewContactPreferences } from "../../hooks/useViewContactPreferences";
import { CustomSelect } from "../../common/Select";
import {
  MARKETPLACE,
  MEDICAID,
  MEDICARE,
  SUCCESS_RESPONSE,
  SUCCESS_RESULT_CODE,
  TERMED_MEMBER,
  SUPPORT_USER,
  GLOBAL_VARIABLE,
} from "../../define.constants";
import * as AccountSettingsProfileAPI from "../../services/AccountSettingsProfileAPI";
import { ErrorContext } from "../../contexts/error.context";
import { ModalContext } from "./../../contexts/modal.context";
import { WhoIsAuthorized } from "../../common/WhoIsAuthorized";

function PersonalInformation(props) {
  let {
    profileList,
    isDependentLoggedIn,
    memberLOB,
    refreshGetAccountProfileAPI,
    isTermedMember,
    isCABrightcareMember,
  } = useContext(AppContext);
  const { communicationData, refreshMessageInfoAll } =
    useViewCommunicationPreferences();
  const { MemberStateName } = useMemberAPI();
  const isNDDflagEnabled = communicationData.LsMemberAPIInfo?.NDD;
  const [isEditable, setIsEditable] = useState(false);
  const { openModal, closeModal } = useContext(ModalContext);
  const { innerSpinnerHTML, setSpinner } = useContext(SpinnerContext);
  const { setError } = useContext(ErrorContext);
  const accountStateMyProfile = props?.fields?.MyProfileList[1] ?? [];
  const accountDetailMyProfile = props?.fields?.MyProfileList[0] ?? [];
  const initialValues = { txtCity: profileList.City };
  const { contactData, refreshMSGInfoAll, isViewContactLoaded } =
    useViewContactPreferences();

  const onSubmit = () => {
    updateAddress();
  };
  const [hiddenDisclaimer, setHiddenDisclaimer] = useState(false);

  const [address, setAddress] = useState("");
  const [aptSuite, setAptSuite] = useState("");
  const [city, setCity] = useState("");
  const [stateName, setStateName] = useState("");
  const [zipCode, setZipCode] = useState("");
  const cityOptionsArray = [];
  const [cityOptions, setCityOptions] = useState([]);
  const [addressSuggestions, setAddressSuggestions] = useState([]);
  const [validZipCode, setValidZipCode] = useState(true);

  GLOBAL_VARIABLE.genesysIntent = getFieldValue(props?.fields, "intent");

  const getCityList = () => {
    setSpinner(true);
    AccountSettingsProfileAPI.getMemberCitiesForState({
      Statename: stateName,
    })
      .then((data) => {
        if (data) {
          if (
            data.Message === SUCCESS_RESPONSE &&
            data.ResultCode === SUCCESS_RESULT_CODE
          ) {
            data.ResultData?.map((item) => {
              cityOptionsArray.push({
                value: item.CityName === "Select" ? "" : item.CityName,
                label: item.CityName,
                id: item.CityName,
              });
            });
          }

          setCityOptions([...cityOptionsArray]);
        }
      })
      .catch((err) => {
        setError({ err });
      })
      .finally(() => {
        setSpinner(false);
      });
  };

  const updateAddress = () => {
    if (
      address == null ||
      address?.trim()?.length < 1 ||
      address?.trim()?.length > 60
    )
      setAddress(profileList.MailingAddress);
    if (
      zipCode == null ||
      zipCode?.trim()?.length < 5 ||
      zipCode?.trim()?.length > 9
    )
      setZipCode(profileList.Zip);
    setSpinner(true);
    AccountSettingsProfileAPI.updateAddress({
      ChangedAddr1: address?.trim(),
      ChangedAddr2: aptSuite?.trim(),
      ChangedCity: city?.trim(),
      ChangedState: stateName?.trim(),
      ChangedZip: zipCode?.trim(),
    })
      .then((data) => {
        if (data) {
          if (data.ResultCode === SUCCESS_RESULT_CODE) {
            refreshGetAccountProfileAPI();
            setIsEditable(false);
            showModal(true);
          } else {
            showModal(false);
            setValidZipCode(false);
          }
        }
      })
      .catch((err) => {
        setError({ err });
      })
      .finally(() => {
        setSpinner(false);
      });
  };

  const getZipCodeChange = (cityVal) => {
    setSpinner(true);
    AccountSettingsProfileAPI.getZipCodeChange({
      state: stateName,
      city: cityVal,
    })
      .then((data) => {
        if (data) {
          if (
            data.Message === SUCCESS_RESPONSE &&
            data.ResultCode === SUCCESS_RESULT_CODE &&
            data.ResultData
          ) {
            setZipCode(data.ResultData?.trim());
            setValidZipCode(true);
          }
        }
      })
      .catch((err) => {
        setError({ err });
      })
      .finally(() => {
        setSpinner(false);
      });
  };

  const addressAutoComplete = (addressVal) => {
    let suggestions = [];
    let streetLineList = [];
    AccountSettingsProfileAPI.addressAutoComplete({
      StateCode: stateName,
      Statename: stateName,
      AddressLine1: addressVal,
      City: city,
    })
      .then((data) => {
        if (data) {
          if (
            data.Message === SUCCESS_RESPONSE &&
            data.ResultCode === SUCCESS_RESULT_CODE &&
            data.ResultData
          ) {
            suggestions = data.ResultData.suggestions;
            suggestions.map((item) => streetLineList.push(item.street_line));
            setAddressSuggestions(streetLineList);
          }
        }
      })
      .catch((err) => {
        setError({ err });
      });
  };

  const getZipCodeFromAutoSuggestionList = (addressVal) => {
    setSpinner(true);
    AccountSettingsProfileAPI.getZipCodeFromAutoSuggestionList({
      StateCode: stateName,
      Statename: stateName,
      AddressLine1: addressVal,
      City: city,
    })
      .then((data) => {
        if (data) {
          if (
            data.Message === SUCCESS_RESPONSE &&
            data.ResultCode === SUCCESS_RESULT_CODE &&
            data.ResultData &&
            data.ResultData.zipcode
          ) {
            setZipCode(data.ResultData.zipcode?.trim());
          }
        }
      })
      .catch((err) => {
        setError({ err });
      })
      .finally(() => {
        setSpinner(false);
      });
  };

  const handleMailAddressChange = (e) => {
    setAddress(e.target.value);
    addressAutoComplete(e.target.value);
  };
  const handleMailAddress2Change = (e) => {
    setAptSuite(e.target.value);
  };
  const handleCityChange = (e) => {
    setCity(e.value);
    getZipCodeChange(e.value);
  };
  const handleZipChange = (e) => {
    const numberOnly = /^[0-9]{1,10}$/;
    const value = e.target.value;
    if (numberOnly.test(value) || value == "") {
      setZipCode(value);
      if (value?.length < 5 || value?.length > 9) setValidZipCode(false);
      else setValidZipCode(true);
    }
  };
  const handleSelectSuggestion = (suggestion) => {
    setAddress(suggestion);
    setAddressSuggestions([]);
    getZipCodeFromAutoSuggestionList(suggestion);
  };

  useEffect(() => {
    if (!isEditable) {
      setAddress(profileList.MailingAddress);
      setAptSuite(profileList.ApptSuiteOther);
      setCity(profileList.City);
      setStateName(profileList.State);
      setZipCode(profileList.Zip);
      setValidZipCode(true);
    }
  });

  const updateAddressModal = (success) => (
    <>
      <div className="account-modal">
        <div className="update-header">
          <p>
            {success ? (
              <Text
                field={accountDetailMyProfile?.fields?.lblSuccessMessage ?? ""}
              />
            ) : (
              <Text
                field={accountDetailMyProfile?.fields?.lblZipCodeMismatch ?? ""}
              />
            )}
          </p>
        </div>
        <div className="comm-btns">
          <button
            type="button"
            className="btn continue-button"
            data-mdb-dismiss="modal"
            onClick={closeModal}
          >
            <Text field={accountDetailMyProfile?.fields?.lblClose ?? ""} />
          </button>
        </div>
      </div>
    </>
  );
  const showModal = (success) => {
    openModal({
      content: updateAddressModal(success),
      version: 1,
    });
  };

  useEffect(() => {
    if (isDependentLoggedIn === true) {
      setHiddenDisclaimer(true);
    }
  }, [isDependentLoggedIn]);
  const isMarketplaceMember = memberLOB?.toLowerCase() === MARKETPLACE;
  const isMedicareMember = memberLOB?.toLowerCase() === MEDICARE;
  const isMedicaidMember = memberLOB?.toLowerCase() === MEDICAID;
  const isShowMailingAddress =
    !(
      isNDDflagEnabled &&
      (MemberStateName === "CA" || MemberStateName === "U2")
    ) &&
    (isMedicaidMember || isMedicareMember);
  useEffect(() => {
    if (memberLOB && !isMarketplaceMember) {
      getCityList();
    }
  }, [memberLOB, isMarketplaceMember]);
  return (
    <>
      <Formik initialValues={initialValues} enableReinitialize="true">
        {() => {
          return (
            <Form>
              <li className="list-group-item">
                {innerSpinnerHTML?.[ACCOUNT_SETTINGS_MY_PROFILE]}
                <div className="row">
                  <div className="col-xl-8 col-md-4">
                    <h5 className="tab-sub-title">
                      <label htmlFor="personalInformationTitle">
                        <Text
                          field={
                            accountDetailMyProfile?.fields
                              ?.personalInformationTitle
                          }
                        />
                      </label>
                    </h5>
                  </div>
                  <div className="col-xl-4 col-md-8">
                    {isMarketplaceMember && (
                      <SSORedirectProvider>
                        <AccountSettingsPersonalConfirmPopUp
                          {...accountStateMyProfile}
                        />
                      </SSORedirectProvider>
                    )}

                    {!isMarketplaceMember && !isEditable && (
                      <button
                        className="btn btn-primary update-button"
                        onClick={() => setIsEditable(true)}
                        type="button"
                        disabled={isTermedMember}
                      >
                        <Text
                          field={
                            accountDetailMyProfile?.fields?.updateButtonText
                          }
                        />
                      </button>
                    )}
                    {!isMarketplaceMember && isEditable && (
                      <>
                        <div
                          className="personal-details-btns"
                          style={{ float: "right" }}
                        >
                          <WhoIsAuthorized
                            authorize={{
                              [SUPPORT_USER]: false,
                              [TERMED_MEMBER]: false,
                            }}
                            handleClick={onSubmit}
                          >
                            {({ handleClick, disabledClass }) => {
                              return (
                                <button
                                  className="btn btn-primary m-3 w-md-35 w-sm-100"
                                  onClick={handleClick}
                                  disabled={
                                    disabledClass ||
                                    address?.length < 1 ||
                                    zipCode?.length < 5
                                  }
                                >
                                  <Text
                                    field={
                                      accountDetailMyProfile?.fields
                                        ?.saveButtonText
                                    }
                                  />
                                </button>
                              );
                            }}
                          </WhoIsAuthorized>
                          <button
                            className="btn btn-default m-sm-3 my-md-3 ml-md-3 w-md-35 w-sm-100 "
                            type="button"
                            onClick={() => setIsEditable(false)}
                          >
                            <Text
                              field={accountDetailMyProfile?.fields?.lblCancel}
                            />
                          </button>
                        </div>
                      </>
                    )}
                  </div>
                </div>
                <div className="row field1 common-input">
                  <div className="col-xxl-2 col-xl-2 col-md-6 col-sm-12">
                    <div className="row-title">
                      <label htmlFor="memberLastName">
                        <Text
                          field={accountDetailMyProfile?.fields?.lastName}
                        />
                      </label>
                    </div>
                    <div className="row-details">
                      <div
                        id="memberLastName"
                        name="memberLastName"
                        value={profileList.LastName}
                      >
                        {profileList.LastName}
                      </div>
                    </div>
                  </div>
                  <div className="col-xxl-2 col-xl-2 col-md-6 col-sm-12">
                    <div className="row-title">
                      <label htmlFor="memberFirstName">
                        <Text
                          field={accountDetailMyProfile?.fields?.firstName}
                        />
                      </label>
                    </div>
                    <div className="row-details">
                      <div
                        id="memberFirstName"
                        name="memberFirstName"
                        value={profileList.FirstName}
                      >
                        {profileList.FirstName}
                      </div>
                    </div>
                  </div>
                  <div className="col-xxl-2 col-xl-2 col-md-6 col-sm-12">
                    <div className="row-title">
                      <label htmlFor="dateOfBirth">
                        <Text
                          field={accountDetailMyProfile?.fields?.dateOfBirth}
                        />
                      </label>
                    </div>
                    <div className="row-details">
                      <div
                        id="dateOfBirth"
                        name="dateOfBirth"
                        value={profileList.DateOfBirth}
                      >
                        {profileList.DateOfBirth}
                      </div>
                    </div>
                  </div>
                  <div className="col-xxl-2 col-xl-2 col-md-6 col-sm-12">
                    <div className="row-title">
                      <label htmlFor="memberId">
                        <Text
                          field={accountDetailMyProfile?.fields?.memberIDNumber}
                        />
                      </label>
                    </div>
                    <div className="row-details">
                      <div
                        id="memberId"
                        name="memberId"
                        value={profileList.MemberId}
                      >
                        {profileList.MemberId}
                      </div>
                    </div>
                  </div>
                  <div className="col-xxl-2 col-xl-2 col-md-6 col-sm-12">
                    <div className="row-title">
                      <label htmlFor="gender">
                        <Text
                          field={
                            accountDetailMyProfile?.fields?.sexAssignedAtBirth
                          }
                        />
                      </label>
                    </div>
                    <div className="row-details">
                      <div
                        id="gender"
                        name="gender"
                        value={
                          contactData?.IsContactPreferences?.SexAssignedatBirth
                        }
                      >
                        {contactData?.IsContactPreferences?.SexAssignedatBirth}
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 d-none d-sm-none d-xl-none d-lg-block d-md-block">
                    <div className="row-title">
                      <label htmlFor="mailingAdd">
                        <Text
                          field={accountDetailMyProfile?.fields?.mailingAddress}
                        />
                      </label>
                    </div>
                    <div className="row-details">
                      <div
                        id="mailingAdd"
                        name="mailingAdd"
                        value={profileList.MailingAddress}
                      >
                        {profileList.MailingAddress}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row field2 common-input">
                  <div className="col-xxl-2 col-xl-2 col-md-6 col-sm-12 d-lg-none d-md-none d-xl-block">
                    <div className="row-title">
                      <label htmlFor="mailingAddress">
                        <Text
                          field={accountDetailMyProfile?.fields?.mailingAddress}
                        />
                      </label>
                    </div>
                    <div
                      className="row-details"
                      style={{ position: "relative" }}
                    >
                      {!isEditable ? (
                        <div
                          id="mailingAddress"
                          name="mailingAddress"
                          value={profileList.MailingAddress}
                        >
                          {profileList.MailingAddress}
                        </div>
                      ) : (
                        <Field
                          name="txtmailingAddress1"
                          id="txtmailingAddress1"
                          type="text"
                          value={address}
                          maxLength={60}
                          className={`form-control ${
                            address ? null : "is-invalid"
                          }`}
                          onChange={(e) => handleMailAddressChange(e)}
                          onBlur={() => {
                            setTimeout(() => setAddressSuggestions([]), 500);
                          }}
                          style={{
                            border: address
                              ? "1px solid #ccc"
                              : "1px solid red",
                          }}
                          disabled={!isShowMailingAddress}
                        />
                      )}
                      {addressSuggestions?.length > 0 && (
                        <div
                          className="dropdown-menu show"
                          style={{
                            position: "absolute",
                            overflow: "auto",
                            border: "1px solid #cbcbcb",
                            zIndex: "10",
                            maxHeight: "300px",
                          }}
                        >
                          {addressSuggestions?.length > 0 &&
                            addressSuggestions.map((suggestion, index) => (
                              <div
                                key={index}
                                className="dropdown-item"
                                onClick={() =>
                                  handleSelectSuggestion(suggestion)
                                }
                              >
                                {suggestion}
                              </div>
                            ))}
                        </div>
                      )}
                    </div>

                    {addressSuggestions &&
                      addressSuggestions.map((item, index) => {
                        <div key={index} style={{ display: "block" }}>
                          {item}
                        </div>;
                      })}
                  </div>
                  <div className="col-xxl-2 col-xl-2 col-md-6 col-sm-12">
                    <div className="row-title">
                      <label htmlFor="mailingAddress2">
                        <Text
                          field={
                            accountDetailMyProfile?.fields?.mailingAddress2
                          }
                        />
                      </label>
                    </div>
                    <div className="row-details">
                      {!isEditable ? (
                        <div
                          id="mailingAddress2"
                          name="mailingAddress2"
                          value={profileList.ApptSuiteOther}
                        >
                          {profileList.ApptSuiteOther}
                        </div>
                      ) : (
                        <Field
                          name="txtmailingAddress"
                          id="txtmailingAddress"
                          type="text"
                          value={aptSuite}
                          maxLength={60}
                          className="form-control"
                          onChange={(e) => handleMailAddress2Change(e)}
                          style={{ border: "1px solid #ccc" }}
                          disabled={!isShowMailingAddress}
                        />
                      )}
                    </div>
                  </div>
                  <div className="col-xxl-2 col-xl-2 col-md-6 col-sm-12">
                    <div className="row-title">
                      <label htmlFor="city">
                        <Text field={accountDetailMyProfile?.fields?.city} />
                      </label>
                    </div>
                    <div className="row-details">
                      {!isEditable ? (
                        <div id="city" name="city" value={profileList.City}>
                          {profileList.City}
                        </div>
                      ) : (
                        <Field
                          name="txtCity"
                          id="txtCity"
                          //as="select"
                          className="form-select"
                          options={cityOptions}
                          //value={city}
                          style={{ display: "block" }}
                          onChange={(e) => handleCityChange(e)}
                          component={CustomSelect}
                          isDisabled={!isShowMailingAddress}
                        />
                      )}
                    </div>
                  </div>
                  <div className="col-xxl-2 col-xl-2 col-md-6 col-sm-12">
                    <div className="row-title">
                      <label htmlFor="state">
                        <Text field={accountDetailMyProfile?.fields?.state} />
                      </label>
                    </div>
                    <div className="row-details">
                      <div id="state" name="state" value={profileList.State}>
                        {profileList.State}
                      </div>
                    </div>
                  </div>
                  <div className="col-xxl-2 col-xl-2 col-md-6 col-sm-12 button-space">
                    <div className="row-title">
                      <label htmlFor="zip">
                        <Text field={accountDetailMyProfile?.fields?.zip} />
                      </label>
                    </div>
                    <div className="row-details">
                      {!isEditable ? (
                        <div id="zip" name="zip" value={profileList.Zip}>
                          {profileList.Zip}
                        </div>
                      ) : (
                        <Field
                          name="txtzip"
                          id="txtzip"
                          type="text"
                          value={zipCode}
                          maxLength={9}
                          className={`form-control ${
                            validZipCode ? null : "is-invalid"
                          }`}
                          style={{
                            border: validZipCode
                              ? "1px solid #ccc"
                              : "1px solid red",
                          }}
                          onChange={(event) => {
                            handleZipChange(event);
                          }}
                          disabled={!isShowMailingAddress}
                        />
                      )}
                    </div>
                  </div>
                  <div className="col-sm-12">
                    {hiddenDisclaimer && (
                      <div className="btn-disclaimer-text">
                        {getFieldValue(
                          accountDetailMyProfile,
                          "btnDisclaimer",
                          false
                        )}
                      </div>
                    )}
                    <div className="btn-disclaimer-text">
                      {isCABrightcareMember ? (
                        <Text
                          field={
                            accountDetailMyProfile?.fields
                              ?.disclaimerMessageNonMPCHP
                          }
                        />
                      ) : isShowMailingAddress ? (
                        <Text
                          field={
                            accountDetailMyProfile?.fields
                              ?.disclaimerMessageNonMP
                          }
                        />
                      ) : (
                        <div>
                          <Text
                            field={
                              accountDetailMyProfile?.fields
                                ?.disclaimerMessageNonMP
                            }
                          />
                          <br />
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </li>
            </Form>
          );
        }}
      </Formik>
    </>
  );
}

export default PersonalInformation;
