import React, { useState, useContext } from "react";
import {
  Text,
  Link,
  RichText,
  getFieldValue,
} from "@sitecore-jss/sitecore-jss-react";
import { AppContext } from "./../../contexts/app.context";
import { getStateLobConfigurationItems } from "./../../utils/helpers";
import { useThirdPartyAppsAPI } from "../../hooks/useThirdPartyAppsAPI";
import { Formik, Form, Field } from "formik";
import { ModalContext } from "./../../contexts/modal.context";
import { SpinnerContext } from "../../contexts/spinner.context";
import { withModalProvider } from "../../hoc/withModalProvider";
import { ACCSETTINGS_MANAGE_DATA_SHARING } from "../../define.widgets";
import * as ManageDataSharingApplicationAPI from "../../services/ManageDataSharingApplicationAPI";
import {
  ICON_PATH,
  SUCCESS_RESPONSE,
  SUCCESS_RESULT_CODE,
  OPTIN_FlAG_Y,
  OPTIN_FlAG_N,
  DATE_MM_DD_YYYY,
  SUPPORT_USER,
  TERMED_MEMBER,
  GLOBAL_VARIABLE,
} from "../../define.constants";
import { getFormattedDate } from "../../utils/helpers";
import { ErrorContext } from "../../contexts/error.context";
import { WhoIsAuthorized } from "../../common/WhoIsAuthorized";

const AccountSettingsManageDataSharing = (props) => {
  const { setSpinner } = useContext(SpinnerContext);
  const { setError } = useContext(ErrorContext);
  const { openModal, closeModal } = useContext(ModalContext);
  let {
    memberLOB,
    MemberStateName,
    isTermedMember,
    isCABrightcareMember,
    programId,
  } = useContext(AppContext);
  const [saveButtonEditable, setSaveButtonEditable] = useState(true);
  const sharingLink = getStateLobConfigurationItems(
    props.fields,
    MemberStateName,
    memberLOB,
    programId
  );
  GLOBAL_VARIABLE.genesysIntent = getFieldValue(props?.fields, "intent");
  const { thirdPartyApps, refreshMessageInfoAll } = useThirdPartyAppsAPI();
  const { innerSpinnerHTML } = useContext(SpinnerContext);
  let lblYes = props.fields.lblYesButton;
  let lblNo = props.fields.lblNoButton;
  let radioButtonSelected = "";
  if (thirdPartyApps.OptInFlag === OPTIN_FlAG_Y) {
    radioButtonSelected = lblYes.value;
  } else if (thirdPartyApps.OptInFlag === OPTIN_FlAG_N) {
    radioButtonSelected = lblNo.value;
  } else {
    radioButtonSelected = "";
  }
  let initialValues = {
    radiobtn: radioButtonSelected,
  };
  const postApiDataForOptin = (value) => {
    let yesOptInFlag = OPTIN_FlAG_Y;
    setSpinner(true);
    ManageDataSharingApplicationAPI.SelectedOptin({
      OptInFlag: yesOptInFlag,
    })
      .then((response) => {
        setSpinner(false);
        if (response?.ResultData?.Response === SUCCESS_RESPONSE) {
          refreshMessageInfoAll();
          setSpinner(true);
          closeModal();
          setSaveButtonEditable(true);
        }
      })
      .catch((err) => {
        setSpinner(false);
        setError({ err });
      })
      .finally(function () {
        setSpinner(false);
      });
  };
  const postApiDataForOptOut = (value) => {
    let noOptInFlag = OPTIN_FlAG_N;
    setSpinner(true);
    ManageDataSharingApplicationAPI.SelectedOptOut({
      OptInFlag: noOptInFlag,
    })
      .then((response) => {
        setSpinner(false);
        if (response?.ResultData?.Response === SUCCESS_RESPONSE) {
          refreshMessageInfoAll();
          setSpinner(true);
          closeModal();
          setSaveButtonEditable(true);
        }
      })
      .catch((err) => {
        setSpinner(false);
        setError({ err });
      })
      .finally(function () {
        setSpinner(false);
      });
  };
  const agreeToOptIN = (value) => (
    <>
      <div className="datasharing-modal">
        <div className="update-header">
          <div className="head">
            <h4 className="state-color-txt">
              {" "}
              <img
                className="confirm-info"
                src={`${ICON_PATH}/error_outline-24px.svg`}
              />
              <Text field={props?.fields?.lblConfirmationPopUpTitle ?? ""} />
            </h4>
          </div>
        </div>
        <div className="datasharing-para">
          <RichText
            field={props?.fields?.lblConfirmationDescriptionAgreeOptIn ?? ""}
          />
        </div>
        <div className="datasharing-btns">
          <button
            type="button"
            className="btn btn-default"
            onClick={closeModal}
          >
            <Text field={props?.fields?.lblCancelButton ?? ""} />
          </button>
          <WhoIsAuthorized
            authorize={{
              [SUPPORT_USER]: false,
              [TERMED_MEMBER]: true,
            }}
            handleClick={() => postApiDataForOptin(value)}
          >
            {({ handleClick, disabledClass }) => {
              return (
                <button
                  type="button"
                  className="btn btn-primary"
                  data-mdb-dismiss="modal"
                  onClick={handleClick}
                >
                  <Text field={props?.fields?.lblOkayButton ?? ""} />
                </button>
              );
            }}
          </WhoIsAuthorized>
        </div>
      </div>
    </>
  );
  const notAgreeToOptIN = (value) => (
    <>
      <div className="datasharing-modal">
        <div className="update-header">
          <div className="head">
            <h4 className="state-color-txt">
              {" "}
              <img
                className="confirm-info"
                src={`${ICON_PATH}/error_outline-24px.svg`}
              />
              <Text field={props?.fields?.lblConfirmationPopUpTitle ?? ""} />
            </h4>
          </div>
        </div>
        <div className="datasharing-para">
          <RichText
            field={props?.fields?.lblConfirmationDescriptionDisagreeOptIn ?? ""}
          />
        </div>
        <div className="datasharing-btns">
          <button
            type="button"
            className="btn btn-default"
            onClick={closeModal}
          >
            <Text field={props?.fields?.lblCancelButton ?? ""} />
          </button>
          <WhoIsAuthorized
            authorize={{
              [SUPPORT_USER]: false,
              [TERMED_MEMBER]: true,
            }}
            handleClick={() => postApiDataForOptOut(value)}
          >
            {({ handleClick, disabledClass }) => {
              return (
                <button
                  type="button"
                  className="btn btn-primary"
                  data-mdb-dismiss="modal"
                  onClick={handleClick}
                >
                  <Text field={props?.fields?.lblOkayButton ?? ""} />
                </button>
              );
            }}
          </WhoIsAuthorized>
        </div>
      </div>
    </>
  );
  const OnRevokePopup = (value) => (
    <>
      <div className="datasharing-modal">
        <div className="update-header">
          <div className="head">
            <Text field={props?.fields?.lblConfirmationPopUpTitle ?? ""} />
          </div>
        </div>
        <div className="datasharing-para">
          <RichText field={props?.fields?.lblRevokeDescription ?? ""} />
        </div>
        <div className="datasharing-btns">
          <button
            type="button"
            className="btn btn-default"
            onClick={closeModal}
          >
            <Text field={props?.fields?.lblCancelButton ?? ""} />
          </button>
          <WhoIsAuthorized
            authorize={{
              [SUPPORT_USER]: false,
              [TERMED_MEMBER]: true,
            }}
            handleClick={() => onRevokeButtonPost(value)}
          >
            {({ handleClick, disabledClass }) => {
              return (
                <button
                  type="button"
                  className="btn btn-primary"
                  data-mdb-dismiss="modal"
                  onClick={handleClick}
                >
                  <Text field={props?.fields?.lblOkayButton ?? ""} />
                </button>
              );
            }}
          </WhoIsAuthorized>
        </div>
      </div>
    </>
  );
  const OptoutPopupModal = (value) => {
    openModal({
      content: notAgreeToOptIN(value),
      version: 1,
    });
  };
  const OptINPopupModal = (value) => {
    openModal({
      content: agreeToOptIN(value),
      version: 1,
    });
  };
  const OnRevokePopupModal = (value) => {
    openModal({
      content: OnRevokePopup(value),
      version: 1,
    });
  };
  const onFormikSubmit = (value) => {
    if (value.radiobtn === lblYes.value) {
      OptINPopupModal(value);
    } else {
      OptoutPopupModal(value);
    }
  };
  const onRevokeButtonPost = (uniqueId) => {
    if (uniqueId !== null) {
      setSpinner(true);
      ManageDataSharingApplicationAPI.RevokeMemberConsentApp({
        UniqueId: uniqueId,
      })
        .then((response) => {
          setSpinner(false);
          if (response?.ResultCode === SUCCESS_RESULT_CODE) {
            refreshMessageInfoAll();
            setSpinner(true);
            closeModal();
          }
        })
        .catch((err) => {
          setSpinner(false);
          setError({ err });
        })
        .finally(function () {
          setSpinner(false);
        });
    }
  };
  return (
    <>
      <Formik
        initialValues={initialValues}
        onSubmit={onFormikSubmit}
        enableReinitialize="true"
      >
        {({ setFieldValue, handleSubmit }) => (
          <Form>
            <div>
              <div className="row">
                <div className="col-12">
                  <div className="tab-content" id="myTabContent">
                    <div
                      className="tab-pane fade show active"
                      id="formsDocs"
                      role="tabpanel"
                      aria-labelledby="tab-5"
                    >
                      <div className="row no-tab-title data-sharing">
                        <div className="col-md-12">
                          <div className="card lg-card">
                            <div className="card-body">
                              <div className="row">
                                {sharingLink?.map((link, index) => (
                                  <>
                                    <div className="col-md-12">
                                      <Link
                                        key={index}
                                        className="btn btn-primary sharing-btn text-white"
                                        field={link.fields.lblWhatIsSharing}
                                        target="_blank"
                                      />
                                    </div>
                                  </>
                                ))}
                              </div>
                              <div className="row question-block">
                                <div className="col-lg-10">
                                  {thirdPartyApps.OptInFlag === OPTIN_FlAG_Y ? (
                                    <h5 className="tab-sub-title">
                                      <Text
                                        field={props.fields.lblOptedForSharing}
                                      />
                                    </h5>
                                  ) : (
                                    <h5 className="tab-sub-title">
                                      <Text
                                        field={
                                          props.fields
                                            .lblDataSharingOptInQuestion
                                        }
                                      />
                                    </h5>
                                  )}
                                </div>
                                <div
                                  role="group"
                                  className="col-lg-12 col-md-8"
                                >
                                  <div className="form-check form-check-inline">
                                    <Field
                                      name="radiobtn"
                                      id="radioYes"
                                      type="radio"
                                      value={lblYes.value}
                                      onChange={(event) => {
                                        setFieldValue(
                                          `radiobtn`,
                                          event?.target?.value
                                        );
                                        setSaveButtonEditable(false);
                                      }}
                                      className="form-check-input radio"
                                    />
                                    <label
                                      htmlFor="data_sharing_yes"
                                      className="label-radio"
                                    >
                                      {lblYes.value}
                                    </label>
                                  </div>
                                  <div className="form-check form-check-inline">
                                    <Field
                                      name="radiobtn"
                                      id="radioNo"
                                      type="radio"
                                      value={lblNo.value}
                                      onChange={(event) => {
                                        setFieldValue(
                                          `radiobtn`,
                                          event?.target?.value
                                        );
                                        setSaveButtonEditable(false);
                                      }}
                                      className="form-check-input radio"
                                    />
                                    <label
                                      htmlFor="data_sharing_no"
                                      className="label-radio"
                                    >
                                      {lblNo.value}
                                    </label>
                                  </div>
                                </div>
                                <div className="col-lg-12 col-md-12 sharing-save">
                                  <button
                                    type="button"
                                    className={
                                      isTermedMember
                                        ? " btn btn-primary update-button disabled"
                                        : "btn btn-primary update-button"
                                    }
                                    disabled={saveButtonEditable}
                                    onClick={handleSubmit}
                                  >
                                    <Text
                                      field={props.fields.saveButton ?? ""}
                                    />
                                  </button>
                                </div>
                                {isCABrightcareMember ? (
                                  <div className="btn-disclaimer-text mt-2">
                                    <Text
                                      field={props.fields.lblSubsidiaryCHP}
                                    />
                                  </div>
                                ) : null}
                              </div>
                              <div>
                                {
                                  innerSpinnerHTML?.[
                                    ACCSETTINGS_MANAGE_DATA_SHARING
                                  ]
                                }
                                <div className="row">
                                  {thirdPartyApps?.MemAppDet?.MemRegAppResponseList?.map(
                                    (data, index) => {
                                      return (
                                        <div
                                          key={index}
                                          className="col-xl-4 col-lg-6 col-md-6 col-sm-12"
                                        >
                                          <div className="data-sharing-access">
                                            <div className="row-title">
                                              {data.appName}
                                            </div>
                                            <div className="row-details">
                                              <Text
                                                field={
                                                  props?.fields
                                                    ?.lblEffectiveDate ?? ""
                                                }
                                              />
                                              {":"}
                                              <span>
                                                {getFormattedDate(
                                                  data.effectiveStartDate,
                                                  DATE_MM_DD_YYYY
                                                )}
                                              </span>
                                            </div>
                                            <WhoIsAuthorized
                                              authorize={{
                                                [SUPPORT_USER]: false,
                                                [TERMED_MEMBER]: true,
                                              }}
                                              handleClick={(e) => {
                                                OnRevokePopupModal(data?.id);
                                              }}
                                            >
                                              {({
                                                handleClick,
                                                disabledClass,
                                              }) => {
                                                return (
                                                  <button
                                                    type="button"
                                                    className="btn btn-primary"
                                                    onClick={handleClick}
                                                  >
                                                    <Text
                                                      field={
                                                        props.fields
                                                          .lblRevokeButton ?? ""
                                                      }
                                                    />
                                                  </button>
                                                );
                                              }}
                                            </WhoIsAuthorized>
                                          </div>
                                        </div>
                                      );
                                    }
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            {thirdPartyApps?.OptInFlag === OPTIN_FlAG_Y &&
                            thirdPartyApps?.MemAppDet?.MemRegAppResponseList ===
                              null ? (
                              <div className="col-md-12 sharing-note">
                                <Text
                                  field={props.fields.lblDataSharingNoConsent}
                                />
                              </div>
                            ) : null}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
};
export default withModalProvider(AccountSettingsManageDataSharing);
