import React, { useContext, useState, useEffect } from "react";
import { Text, getFieldValue } from "@sitecore-jss/sitecore-jss-react";
import { AppContext } from "./../../contexts/app.context";
import { InnerSpinner } from "../../common/Spinner";
import { ModalContext } from "./../../contexts/modal.context";
import { withModalProvider } from "../../hoc/withModalProvider";
import {
  ICON_PATH,
  MEDICAID,
  MEDICARE,
  NM,
  PLANTOGGLE_MEDICAID,
  PLANTOGGLE_MEDICARE,
} from "../../define.constants";
import StatusModal from "./StatusModal";
import {
  DEFAULT_LOB,
  MARKETPLACE,
  DEPENDENT,
  SUBSCRIBER,
  GLOBAL_VARIABLE,
  CHPMedicare,
} from "../../define.constants";
import {
  COVERED,
  TERMINATED,
  EFFECTIVE,
  GRACE,
} from "../Home-Layout/Constants";
import { getFormattedDate, getStatusData } from "../../utils/helpers";
import { useGraceDetails } from "../../hooks/useGraceDetails";
import { SSORedirectProvider } from "../../contexts/SSORedirect.context";

const HomeMembershipStatus = (props) => {
  const {
    userType,
    memberLOB,
    MemberStateName,
    coverageEffDt,
    planTerminationDt,
    isMemInfoResponseLoaded,
    isTermedMember,
    DSNPdetails,
    changeDSNPView,
    isSupportUser,
    supportUserId,
    isCABrightcareMember,
  } = useContext(AppContext);
  const { graceStatus, isLoading } = useGraceDetails();
  const { openModal } = useContext(ModalContext);
  const { isSwitchedToDependent } = useContext(AppContext);
  const { fields } = props;

  let status = null;
  let statusClass = null;
  let values = null;
  let slist = null;
  let statusMatch = null;
  let lob = memberLOB.toLocaleLowerCase();
  let stateCode = MemberStateName.toLocaleLowerCase();
  const covDate = new Date(coverageEffDt);
  const termDate = new Date(planTerminationDt);
  const curDate = new Date();
  const isMarketPlaceMember = memberLOB.toLocaleLowerCase() === MARKETPLACE;

  GLOBAL_VARIABLE.genesysIntent = getFieldValue(fields, "intent");
  const [togglePosition, setTogglePosition] = useState("");
  const [medicaidToggleClass, setMedicaidToggleClass] = useState("");
  const [medicareToggleClass, setMedicareToggleClass] = useState("");
  const isNMMedicareMember =
    MemberStateName === NM && memberLOB?.toLowerCase() == MEDICARE;

  if (isMemInfoResponseLoaded && !isLoading) {
    fields.statusTypes.map((list) => {
      const statusList = list.fields;
      const actualStatus = getFieldValue(statusList, "status");

      const statusValue = getFieldValue(
        statusList,
        "statusMatch",
        null
      ).toLocaleLowerCase();
      if (graceStatus && lob === MARKETPLACE) {
        if (statusValue.includes(GRACE)) {
          userType === DEPENDENT || isSwitchedToDependent
            ? (lob = DEPENDENT)
            : (lob = SUBSCRIBER);
          status = actualStatus;
          statusClass = "inline-status-grace-prd-ylw";
          slist = statusList;
          statusMatch = statusValue;
        }
      } else if (!isMarketPlaceMember && !isTermedMember) {
        if (covDate < curDate) {
          if (statusValue === COVERED) {
            status = actualStatus;
            lob = DEFAULT_LOB;
            statusClass = "inline-status";
            slist = statusList;
            statusMatch = statusValue;
          }
        }
        if (covDate > curDate) {
          if (statusValue.includes(EFFECTIVE)) {
            status = actualStatus;
            lob = DEFAULT_LOB;
            statusClass = "inline-status-pending";
            slist = statusList;
            statusMatch = statusValue;
          }
        }
      } else if (covDate < curDate && termDate > curDate) {
        if (statusValue === COVERED) {
          status = actualStatus;
          if (lob !== MARKETPLACE) lob = DEFAULT_LOB;
          statusClass = "inline-status";
          slist = statusList;
          statusMatch = statusValue;
        }
      } else if (covDate < curDate && termDate < curDate) {
        if (statusValue === TERMINATED) {
          if (isCABrightcareMember) {
            status = actualStatus;
            lob = CHPMedicare;
            statusClass = "inline-status-terminated";
            slist = statusList;
            statusMatch = statusValue;
          } else {
            status = actualStatus;
            lob = DEFAULT_LOB;
            statusClass = "inline-status-terminated";
            slist = statusList;
            statusMatch = statusValue;
          }
        }
      } else if (covDate > curDate && termDate > curDate) {
        if (statusValue.includes(EFFECTIVE)) {
          status = actualStatus;
          if (lob !== MARKETPLACE) lob = DEFAULT_LOB;
          statusClass = "inline-status-pending";
          slist = statusList;
          statusMatch = statusValue;
        }
      }
    });
    values = getStatusData(lob, slist);
  }

  const modalContent = (
    <>
      <SSORedirectProvider>
        <StatusModal
          {...values?.statusData}
          date={coverageEffDt}
          status={statusMatch}
        />
      </SSORedirectProvider>
    </>
  );

  useEffect(() => {
    if (memberLOB.toLowerCase() == MEDICAID) {
      setTogglePosition("0%");
      setMedicaidToggleClass("switch-button-case left active-case");
      setMedicareToggleClass("switch-button-case right");
    } else {
      setTogglePosition("46%");
      setMedicaidToggleClass("switch-button-case left");
      setMedicareToggleClass("switch-button-case right active-case");
    }
  }, [memberLOB]);

  const handleToggleSwitch = (e) => {
    let dsnpSelectedPlan = null;
    if (e.target.name == "medicaid-toggle") {
      setTogglePosition("0%");
      setMedicaidToggleClass("switch-button-case left active-case");
      setMedicareToggleClass("switch-button-case right");
      dsnpSelectedPlan = DSNPdetails?.find(
        (e) => e.LOB.toLowerCase() == MEDICAID
      );
    } else {
      setTogglePosition("46%");
      setMedicaidToggleClass("switch-button-case left");
      setMedicareToggleClass("switch-button-case right active-case");
      dsnpSelectedPlan = DSNPdetails?.find(
        (e) => e.LOB.toLowerCase() == MEDICARE
      );
    }

    if (dsnpSelectedPlan) {
      changeDSNPView({
        memberId: dsnpSelectedPlan?.MemberID,
        memberNumber: dsnpSelectedPlan?.MemberNo,
        stateCode: dsnpSelectedPlan?.StateCode,
        isSupportUser: isSupportUser && supportUserId,
      });
    }
  };

  return (
    <div className="row">
      {DSNPdetails?.length > 1 && !isNMMedicareMember && (
        <div className="col-sm-12 col-md-12 col-lg-4">
          <div className="toggleblock">
            <ul className="nav">
              <li className="nav-item">
                <span className="plantext">
                  <Text field={fields.selectYourPlan} />
                </span>
              </li>
              <li className="nav-item">
                <div className="switch-button">
                  <span
                    className="active"
                    style={{ left: togglePosition }}
                  ></span>
                  <button
                    className={medicaidToggleClass}
                    name="medicaid-toggle"
                    onClick={(e) => {
                      handleToggleSwitch(e);
                    }}
                  >
                    {PLANTOGGLE_MEDICAID}
                  </button>
                  <button
                    className={medicareToggleClass}
                    name="medicare-toggle"
                    onClick={(e) => {
                      handleToggleSwitch(e);
                    }}
                  >
                    {PLANTOGGLE_MEDICARE}
                  </button>
                </div>
              </li>
            </ul>
          </div>
        </div>
      )}
      <div
        className={
          DSNPdetails?.length > 1 && !isNMMedicareMember
            ? "col-sm-12 col-md-12 col-lg-8"
            : "col-12"
        }
        style={{
          textAlign:
            DSNPdetails?.length > 1 && !isNMMedicareMember ? "" : "center",
        }}
      >
        <div className="home-title">
          {(isLoading || !isMemInfoResponseLoaded) && <InnerSpinner />}
          <div
            className={
              isLoading || !isMemInfoResponseLoaded ? "visibility-hidden" : ""
            }
          >
            <Text field={fields.heading} />
            <span className={statusClass}>
              {statusMatch?.toLocaleLowerCase()?.includes(EFFECTIVE)
                ? `${status} ${getFormattedDate(coverageEffDt, "mm/dd/yyyy")}`
                : status}
            </span>{" "}
            <a
              className="learnmoreonclick"
              href="#"
              aria-label={fields.learnMore.value + " Membership Status"}
              onClick={(e) => {
                e.preventDefault();
                openModal({ content: modalContent });
              }}
            >
              <Text field={fields.learnMore} />
              <img
                className="link-icon img-link-icon"
                src={`${ICON_PATH}/arrow-right-md.svg`}
                alt={fields.learnMore}
              />
            </a>{" "}
          </div>
        </div>
      </div>
    </div>
  );
};

export default withModalProvider(HomeMembershipStatus);
