import React, { useContext, useEffect, useState } from "react";
import { MultiStepContext } from "../../contexts/Steps.context";
import { getFieldValue, Text } from "@sitecore-jss/sitecore-jss-react";
import { ICON_PATH } from "../../define.constants";
import { brightCarePortal } from "../../utils/helpers";

const StepProgressBar = (props) => {
  const { currentStep } = useContext(MultiStepContext);
  const [isBrightCare, setIsBrightCare] = useState(null);

  useEffect(async () => {
    setIsBrightCare(await brightCarePortal());
  }, []);

  const displayProgressBar = (step) => {
    let progress100 = (
      <div className="progress">
        <div
          className="progress-bar"
          role="progressbar"
          style={{ width: "100%" }}
          aria-valuenow="50"
          aria-valuemin="0"
          aria-valuemax="100"
        ></div>
      </div>
    );
    let progress50 = (
      <div className="progress">
        <div
          className="progress-bar"
          role="progressbar"
          style={{ width: "50%" }}
          aria-valuenow="50"
          aria-valuemin="0"
          aria-valuemax="100"
        ></div>
      </div>
    );
    let progress0 = (
      <div className="progress">
        <div
          className="progress-bar"
          role="progressbar"
          style={{ width: "0%" }}
          aria-valuenow="50"
          aria-valuemin="0"
          aria-valuemax="100"
        ></div>
      </div>
    );

    return (
      <div className="col-lg-12 text-center mb-5">
        {step === 1 ? (
          <>
            <div
              className="step active"
              data-desc={getFieldValue(props.fields, "memberInformation")}
            >
              1
            </div>
            {progress50}
          </>
        ) : (
          <>
            <div
              className="step tick active "
              data-desc={getFieldValue(props.fields, "memberInformation")}
            >
              <img
                src={`${ICON_PATH}/tick-mark.svg`}
                alt={getFieldValue(props.fields, "memberInformation")}
              />
            </div>
            {progress100}
          </>
        )}

        {step < 2 ? (
          <>
            <div
              className="step"
              data-desc={getFieldValue(props.fields, "userIDnPwd")}
            >
              2
            </div>
            {progress0}
          </>
        ) : step === 2 ? (
          <>
            <div
              className="step active"
              data-desc={getFieldValue(props.fields, "userIDnPwd")}
            >
              2
            </div>
            {progress50}
          </>
        ) : (
          <>
            <div
              className="step tick active "
              data-desc={getFieldValue(props.fields, "userIDnPwd")}
            >
              <img
                src={`${ICON_PATH}/tick-mark.svg`}
                alt={getFieldValue(props.fields, "userIDnPwd")}
              />
            </div>
            {progress100}
          </>
        )}

        {step < 3 ? (
          <>
            <div
              className="step"
              data-desc={getFieldValue(props.fields, "authentication")}
            >
              3
            </div>
            {progress0}
          </>
        ) : step === 3 ? (
          <>
            <div
              className="step active"
              data-desc={getFieldValue(props.fields, "authentication")}
            >
              3
            </div>
            {progress0}
          </>
        ) : (
          <>
            <div
              className="step tick active "
              data-desc={getFieldValue(props.fields, "authentication")}
            >
              <img
                src={`${ICON_PATH}/tick-mark.svg`}
                alt={getFieldValue(props.fields, "authentication")}
              />
            </div>
            {progress100}
            <div
              className="step tick active "
              data-desc={getFieldValue(props.fields, "activateYourAccount")}
            >
              <img
                src={`${ICON_PATH}/tick-mark.svg`}
                alt={getFieldValue(props.fields, "activateYourAccount")}
              />
            </div>
          </>
        )}

        {step < 4 ? (
          <div
            className="step"
            data-desc={getFieldValue(props.fields, "activateYourAccount")}
          >
            4
          </div>
        ) : null}
      </div>
    );
  };
  return (
    <>
      <h5 className="text-center">
        <Text
          field={
            isBrightCare != null
              ? isBrightCare
                ? props.fields.createCHPAccount
                : props.fields.createMyMolinaAccount
              : ""
          }
        />
      </h5>
      <div className="col-lg-12 text-center mb-5">
        {displayProgressBar(currentStep)}
      </div>
    </>
  );
};

export default StepProgressBar;
