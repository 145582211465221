import React, { useContext } from "react";
import { getFieldValue, Text } from "@sitecore-jss/sitecore-jss-react";
import { getFormattedDate } from "../../utils/helpers";
import { BoxContainer } from "../../common/BoxContainer";
import { Box } from "../../common/Box";
import { useGetDDLYearsAPI } from "../../hooks/useGetDDLYearsAPI";
import { SpinnerContext } from "../../contexts/spinner.context";
import { COVERAGE_ELIGIBILITY } from "../../define.widgets";
import { AppContext } from "../../contexts/app.context";
import {
  MEDICAID,
  MS,
  MS_CHIP_PROGRAMID,
  NY,
  NY_MD_HarpAndMMC_LIST,
  NE,
  FLAG_YES,
} from "../../define.constants";

const EligibilityNonMP = ({ fields }) => {
  const { planData } = useGetDDLYearsAPI();
  const { innerSpinnerHTML } = useContext(SpinnerContext);
  const {
    memberLOB,
    MemberStateName,
    programId,
    RedeterminationDate,
    isTermedMember,
    isCABrightcareMember,
    profileList,
  } = useContext(AppContext);

  let redirectionLink = getFieldValue(fields, "lblRedirectionLink");

  const handleRedirectionToDocuments = (e) => {
    e.preventDefault();
    location = redirectionLink.href;
  };

  // Showing Redetermination only for NonMp-Medicaid
  let showRedeterminationDate =
    RedeterminationDate && memberLOB?.toLowerCase() === MEDICAID?.toLowerCase()
      ? true
      : false;

  // Col span of Eligibility section fields
  let divNumber = 4;
  const getDivSpanNumber = (showTermDateFlag) => {
    if (showRedeterminationDate && showTermDateFlag) {
      divNumber = 3;
    } else if (!showRedeterminationDate && !showTermDateFlag) {
      divNumber = 6;
    } else if (!showRedeterminationDate || !showTermDateFlag) {
      divNumber = 4;
    } else {
      divNumber = 4;
    }
  };

  //Hide Eligibility text for MS-Chip
  let showEligibilityMsg =
    MemberStateName?.toLowerCase() === MS?.toLowerCase() &&
    programId &&
    programId === MS_CHIP_PROGRAMID
      ? false
      : true;

  // Medicare termed member don't show termdate
  let showTermDate = (termDate) => {
    let showTermDateFlag;

    showTermDateFlag =
      !termDate || (termDate && termDate?.trim().length == 0) || !isTermedMember
        ? false
        : true;

    getDivSpanNumber(showTermDateFlag);
    return showTermDateFlag;
  };

  // For NY Medicaid MCC or Harp extended disclaimer.
  let showExtendedEligibilityMsg =
    MemberStateName?.toLowerCase() === NY?.toLowerCase() &&
    memberLOB?.toLowerCase() === MEDICAID?.toLowerCase() &&
    programId &&
    NY_MD_HarpAndMMC_LIST.includes(programId);
  //NE implementation-start
  let RenewalDate =
    MemberStateName?.toLowerCase() === NE?.toLowerCase()
      ? "lblRenewalDate"
      : "lblRedeterminationDate";
  //NE implementation-End

  return (
    <>
      <div className="row">
        <div className="col-xxl-3 col-lg-4 col-md-6 col-sm-12">
          <div className="page-heading">
            <Text field={fields.heading} />
          </div>
        </div>
      </div>
      <div className="row eligibility-block">
        <div className="col-md-12 col-sm-12">
          <section className="d-flex justify-content-center">
            <BoxContainer>
              <Box
                title={fields.lblEligibility}
                subTitle={fields.lblRedirectionLink}
                onClickHandler={handleRedirectionToDocuments}
              >
                {innerSpinnerHTML?.[COVERAGE_ELIGIBILITY]}
                <>
                  {planData && (
                    <div className="row eligibility-details">
                      {[
                        {
                          label: "lblPlanName",
                          item:
                            planData.PlanDescription &&
                            isCABrightcareMember &&
                            profileList?.isCHPDentalSupplemental == FLAG_YES
                              ? planData.PlanDescription +
                                " " +
                                getFieldValue(fields, "lblDentalBuyUp")
                              : planData.PlanDescription
                              ? planData.PlanDescription
                              : null,
                          showProperty: true,
                        },
                        {
                          label: "lblEffDate",
                          item: planData.EffectiveDate
                            ? planData.EffectiveDate
                            : null,
                          showProperty: true,
                        },
                        {
                          label: RenewalDate,
                          item: RedeterminationDate
                            ? getFormattedDate(
                                RedeterminationDate,
                                "mm/dd/yyyy"
                              )
                            : null,
                          showProperty: showRedeterminationDate,
                        },
                        {
                          label: "lblTermDate",
                          item: planData.TermDate ? planData.TermDate : null,
                          showProperty: showTermDate(planData.TermDate),
                        },
                      ].map(({ label, item, showProperty }) =>
                        showProperty ? (
                          <div
                            className={`col-sm-12 col-md-6 col-xl-${divNumber}`}
                            key={label}
                          >
                            <div className="row-title">
                              <Text field={fields[label]} />
                            </div>
                            <div className="row-details">{item}</div>
                          </div>
                        ) : null
                      )}
                    </div>
                  )}
                  {showEligibilityMsg && planData && (
                    <div className="row-details">
                      {showExtendedEligibilityMsg ? (
                        <Text field={fields["lblExtendedEligibilityMsg"]} />
                      ) : (
                        <Text field={fields["lblEligibilityMsg"]} />
                      )}
                    </div>
                  )}
                </>
              </Box>
            </BoxContainer>
          </section>
        </div>
      </div>
    </>
  );
};

export default EligibilityNonMP;
