import { getFieldValue } from "@sitecore-jss/sitecore-jss-react";
import {
  DEFAULT_STATE,
  DEFAULT_LOB,
  ENGLISH_LANG_CODE,
  NM,
  WA,
  CA,
  MS,
  SC,
  FL,
  MI,
  OH,
  TX,
  UT,
  WI,
  IL,
  ID,
  KY,
  IA,
  NV,
  VA,
  NE,
  AZ,
  NY,
  MA,
  SCRIPT_REGEX,
  EXPIRY_DATE_FOR_NM_STATE_POPUP,
  POD_SAPPHIRE_SSO,
  HPS_SSO,
  PDHI_SSO,
  HEALTH_SHERPA_SSO,
  CVS_ACCORDANT_SSO,
  LEGACY_PORTAL_URL,
  LEGACY_PORTAL_MOBILE_URL,
  ADMIN_PORTAL_URL,
  INSTAMED_SSO,
  OIDC_CLIENT_ID,
  OIDC_AUTH_BASEURL,
  SPANISH_LANG_PATH,
  ENGLISH_LANG_PATH,
  MyMolinaLoginUrl,
  NY_MD_EP_PROGRAMID_LIST,
} from "../define.constants";
import { routePatterns } from "../AppRoot";
import { matchPath } from "react-router-dom";
import { MARKETPLACE } from "../define.constants";
import { MEDICAID } from "../define.constants";
import { MEDICARE } from "../define.constants";

import {
  DUALS,
  SUBSCRIBER,
  DEPENDENT,
  DEPENDENT_WITH_ACCESS,
  DEPENDANT_ACCESS_GRANTED_ROLEID,
  DEPENDANT_NO_ACCESS_ROLEID,
} from "../define.constants";
import { serverConstants } from "../utils/constants";
import * as commonAPI from "../services/Common";
/**
 *
 * @param {*} error
 * @param {*} touched
 * @returns className
 */

export const highlightBorderOnError = (error, touched) => {
  return error && touched ? "input-error" : "";
};
/**
 *
 * @param {string} value
 * @returns string | STRING to String
 */
export const formatName = (value) => {
  return (
    value?.trim().toLowerCase().charAt(0).toUpperCase() +
    value?.trim().toLowerCase().slice(1)
  );
};
/**
 *
 * @param {string} value
 * @returns {number} age of member
 */
export const memberAgeCalculation = (value) => {
  const oneDay = 24 * 60 * 60 * 1000;
  const birthDate = new Date(value);

  const systemDate = new Date(getFormattedDate(new Date()));

  const diffDays = Math.round(Math.abs((birthDate - systemDate) / oneDay));

  return diffDays / 365;
};

export const emptyString = "";
export const formsValidationMessage = "Required";

export const getStateConfiguration = (fields, MemberStateName, memberLOB) => {
  let items = null;
  fields?.stateLevelList?.map((stateList) => {
    const stateCode = getFieldValue(
      stateList?.fields,
      "stateName",
      null
    ).toLowerCase();
    stateCode === MemberStateName?.toLowerCase() ||
    stateCode === DEFAULT_STATE.toLowerCase()
      ? stateList?.fields.lobLevelList?.map((lobList) => {
          const lob = getFieldValue(
            lobList.fields,
            "lobName",
            null
          ).toLowerCase();
          lob === memberLOB?.toLowerCase() || lob === DEFAULT_LOB.toLowerCase()
            ? lobList?.fields.itemLevelList !== undefined
              ? (items = lobList?.fields.itemLevelList) //Check item level list exist.
              : (items = lobList)
            : null;
        })
      : null;
  });

  return items;
};
export const getStateLobItemListConfiguration = (
  fields,
  MemberStateName,
  memberLOB,
  programId
) => {
  let items = null;
  fields?.stateLevelList?.forEach((stateList) => {
    const stateCode = getFieldValue(
      stateList?.fields,
      "stateName",
      null
    ).toLowerCase();
    stateCode === MemberStateName?.toLowerCase() ||
    stateCode === DEFAULT_STATE.toLowerCase()
      ? stateList?.fields.lobLevelList?.forEach((lobList) => {
          const lob = getFieldValue(
            lobList.fields,
            "lobName",
            null
          ).toLowerCase();
          lob === (memberLOB?.trim() + "-" + programId)?.toLowerCase() ||
          lob === DEFAULT_LOB.toLowerCase()
            ? lobList?.fields.itemLevelList !== undefined
              ? (items = lobList?.fields.itemLevelList) //Check item level list exist.
              : (items = lobList)
            : null;
        })
      : null;
  });

  if (items === null) {
    fields?.stateLevelList?.forEach((stateList) => {
      const stateCode = getFieldValue(
        stateList?.fields,
        "stateName",
        null
      ).toLowerCase();
      stateCode === MemberStateName?.toLowerCase() ||
      stateCode === DEFAULT_STATE.toLowerCase()
        ? stateList?.fields.lobLevelList?.forEach((lobList) => {
            const lob = getFieldValue(
              lobList.fields,
              "lobName",
              null
            ).toLowerCase();
            lob === memberLOB.toLowerCase() || lob === DEFAULT_LOB.toLowerCase()
              ? lobList?.fields.itemLevelList !== undefined
                ? (items = lobList?.fields.itemLevelList) //Check item level list exist.
                : (items = lobList)
              : null;
          })
        : null;
    });
  }

  return items;
};

export const getStateLobConfiguration = (
  fields,
  MemberStateName,
  memberLOB,
  programId
) => {
  let items = null;
  let lobList = null;
  let itemsList = null;

  // State level Check starts
  lobList = getStateLobList(fields?.stateLevelList, MemberStateName);
  if (lobList === null) {
    lobList = getGlobalLobList(fields?.stateLevelList, MemberStateName);
  }
  // State level check ends
  // Lob level check start
  if (lobList !== null) {
    itemsList = getLobProgram(lobList, memberLOB, programId);
    if (itemsList === null) {
      itemsList = getLob(lobList, memberLOB);
    }
    if (itemsList === null) {
      itemsList = getCommonLob(lobList, memberLOB);
    }
    // Lob level check ends
    if (itemsList !== null) items = itemsList;
    return items;
  }
};

/**
 *  returns ItemLevelList
 * @param {*} fields
 * @param {*} MemberStateName
 * @param {*} memberLOB
 * @param {*} programId
 * @returns {*} lobListItems
 */

export const getStateLobConfigurationItems = (
  fields,
  MemberStateName,
  memberLOB,
  programId
) => {
  let items = null;
  let lobList = null;
  let itemsList = null;

  // State level Check starts
  lobList = getStateLobList(fields?.stateLevelList, MemberStateName);
  if (lobList === null) {
    lobList = getGlobalLobList(fields?.stateLevelList, MemberStateName);
  }
  // State level check ends
  // Lob level check start
  if (lobList !== null) {
    itemsList = getLobProgramItems(lobList, memberLOB, programId);
    if (itemsList === null) {
      itemsList = getLobItems(lobList, memberLOB);
    }
    if (itemsList === null) {
      itemsList = getCommonLobItems(lobList, memberLOB);
    }
    // Lob level check ends
    if (itemsList !== null) items = itemsList;
    return items;
  }
};

/**
 * returns LOBLevelList w.r.t to memberStateName
 * @param {*} stateLevelList
 * @param {*} MemberStateName
 * @returns {*} lobListItems
 */

export const getStateLobList = (stateLevelList, MemberStateName) => {
  let lobListItems = null;
  stateLevelList
    ?.filter(
      (o) =>
        o?.fields?.stateName?.value?.toLowerCase() ===
        MemberStateName?.toLowerCase()
    )
    .map((stateList) => {
      lobListItems = stateList?.fields?.lobLevelList;
    });

  return lobListItems;
};
/**
 *  returns Global LOBLevelList when memberStateName is not matching
 * @param {*} stateLevelList
 * @param {*} MemberStateName
 * @returns {*} lobListItems
 */

export const getGlobalLobList = (stateLevelList, MemberStateName) => {
  let lobListItems = null;
  stateLevelList
    ?.filter(
      (o) =>
        o?.fields?.stateName?.value?.toLowerCase() ===
        DEFAULT_STATE?.toLowerCase()
    )
    .map((stateList) => {
      lobListItems = stateList?.fields?.lobLevelList;
    });

  return lobListItems;
};
/**
 * returns State + LOB + Program ID specific ItemLevelList
 * @param {*} lobList
 * @param {*} memberLOB
 * @param {*} programId
 * @returns {*} itemList
 */

export const getLobProgramItems = (lobList, memberLOB, programId) => {
  let itemList = null;
  try {
    if (
      (memberLOB !== "" || memberLOB !== undefined) &&
      (programId !== "" || programId !== undefined)
    ) {
      lobList
        .filter(
          (o) =>
            o?.fields?.lobName?.value?.toLowerCase() ===
            (memberLOB?.trim() + "-" + programId)?.toLowerCase()
        )
        .map((lobData) => {
          itemList = lobData?.fields?.itemLevelList;
        });
      return itemList;
    }
  } catch (e) {
    console.error(e);
  }
};

export const getLobProgram = (lobList, memberLOB, programId) => {
  let itemList = null;
  try {
    if (
      (memberLOB !== "" || memberLOB !== undefined) &&
      (programId !== "" || programId !== undefined)
    ) {
      lobList
        .filter(
          (o) =>
            o?.fields?.lobName?.value?.toLowerCase() ===
            (memberLOB?.trim() + "-" + programId)?.toLowerCase()
        )
        .map((lobData) => {
          itemList = lobData;
        });
      return itemList;
    }
  } catch (e) {
    console.error(e);
  }
};
/**
 * returns  State + LOB specific ItemLevelList
 * @param {*} lobList
 * @param {*} memberLOB
 * @returns {*} itemList
 */

export const getLobItems = (lobList, memberLOB) => {
  let itemList = null;
  lobList
    ?.filter(
      (o) =>
        o?.fields?.lobName?.value?.toLowerCase() === memberLOB?.toLowerCase()
    )
    .map((lobData) => {
      itemList = lobData?.fields?.itemLevelList;
    });
  return itemList;
};

export const getLob = (lobList, memberLOB) => {
  let itemList = null;
  lobList
    ?.filter(
      (o) =>
        o?.fields?.lobName?.value?.toLowerCase() === memberLOB?.toLowerCase()
    )
    .map((lobData) => {
      itemList = lobData;
    });
  return itemList;
};
/**
 * returns  State specific Items
 * @param {*} MemberStateName
 * @param {*} fields
 * @returns {*} items
 */

export const getStateItems = (MemberStateName, fields) => {
  let items = null;
  fields?.stateLevelList?.map((stateList) => {
    const stateCode = getFieldValue(
      stateList?.fields,
      "stateName",
      null
    ).toLowerCase();
    stateCode === MemberStateName?.toLowerCase() ||
    stateCode === DEFAULT_STATE.toLowerCase()
      ? (items = stateList?.fields)
      : null;
  });

  return items;
};
/**
 * returns  State + Common specific ItemLevelList
 * @param {*} lobList
 * @param {*} memberLOB
 * @returns {*} itemList
 */

export const getCommonLobItems = (lobList, memberLOB) => {
  let itemList = null;
  lobList
    ?.filter(
      (o) =>
        o?.fields?.lobName?.value?.toLowerCase() === DEFAULT_LOB?.toLowerCase()
    )
    .map((lobData) => {
      itemList = lobData?.fields?.itemLevelList;
    });
  return itemList;
};

export const getCommonLob = (lobList, memberLOB) => {
  let itemList = null;
  lobList
    ?.filter(
      (o) =>
        o?.fields?.lobName?.value?.toLowerCase() === DEFAULT_LOB?.toLowerCase()
    )
    .map((lobData) => {
      itemList = lobData;
    });
  return itemList;
};

/**
 * Santise html tags in a given input
 *
 * @param {string} html
 * @returns
 */
export const getSanitisedHTML = (html) => {
  return html.replace(/<[^>]+>/g, "");
};

export const getStatusData = (memberLOB, statusList) => {
  let statusData = null;

  statusList?.lobTypes?.map((lobList) => {
    if (
      lobList.fields?.lob?.value.toLocaleLowerCase() ===
      memberLOB.toLocaleLowerCase()
    ) {
      statusData = lobList.fields;
    }
  });
  return {
    statusData,
  };
};

/**
 * returns true if user views application from a mobile or tablet screen
 *
 * @returns {boolean} check
 */
export const mobileAndTabletCheck = () => {
  let check = false;
  (function (a) {
    if (
      /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino|android|ipad|playbook|silk/i.test(
        a
      ) ||
      /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
        a.substr(0, 4)
      )
    )
      check = true;
  })(navigator.userAgent || navigator.vendor || window.opera);
  return check;
};

/**
 * To get formatted date
 *
 * @param {string} dateValue
 * @param {string} requiredFormat
 * @returns {string} formattedDate
 */
export const getFormattedDate = (
  dateValue,
  requiredFormat,
  prefLang = "en-US"
) => {
  if (prefLang !== "en-US") {
    requiredFormat = "dd/mm/yyyy";
  }
  let formattedDate = "";
  if (dateValue && dateValue !== "") {
    let options;
    switch (requiredFormat) {
      case "mm/dd/yyyy":
        options = {
          year: "numeric",
          month: "2-digit",
          day: "2-digit",
        };
        break;
      case "mm/dd":
        options = {
          month: "2-digit",
          day: "2-digit",
        };
        break;
      case "dd/mm":
        options = {
          month: "2-digit",
          day: "2-digit",
        };
        break;
      case "Day, Mon dd, hh:mm t":
        options = {
          weekday: "short",
          month: "short",
          day: "numeric",
          hour: "numeric",
          minute: "numeric",
          hour12: true,
        };
        break;
      case "Mon dd":
        options = {
          month: "short",
          day: "numeric",
        };
        break;
      case "Day, Mon dd, yyyy":
        options = {
          weekday: "long",
          year: "numeric",
          month: "short",
          day: "numeric",
        };
        break;
      case "mm/dd/yy":
        options = {
          year: "2-digit",
          month: "2-digit",
          day: "2-digit",
        };
        break;
      case "mm/yy":
        options = {
          month: "2-digit",
          year: "2-digit",
        };
        break;
      case "mm/dd/yy hh:mm:ss t":
        options = {
          year: "2-digit",
          month: "2-digit",
          day: "2-digit",
          hour: "numeric",
          minute: "numeric",
          second: "numeric",
          hour12: true,
        };
        break;
      //TODO: Add more formats as per the need.
    }
    formattedDate = new Date(dateValue).toLocaleString(prefLang, options);
  }
  return formattedDate;
};

export const formatDependentDOB = (dateValue) => {
  let formattedDependentDOB = "";
  const [year, month, day] = dateValue.split("-");
  formattedDependentDOB = `${month}/${day}/${year}`;
  // let dobInDateFormat = new Date(dateValue);
  // alert(dobInDateFormat);
  // formattedDependentDOB = `${dobInDateFormat.getMonth() + 1}/${
  //   dobInDateFormat.getDate() + 1
  // }/${dobInDateFormat.getFullYear()}`;
  return formattedDependentDOB;
};
// The following method identifies whether a user is Duals User through LOB parameter
export const isDualsUser = (memberLOB) => {
  return memberLOB.toLocaleLowerCase() === DUALS;
};

// The following method identifies IDCardType based on LOB
// In case of DUALS it will be LOB and State wise
export const getIDCardType = (memberLOB, MemberStateName) => {
  let idType;
  let stateName = MemberStateName?.trim()?.toUpperCase();
  switch (memberLOB?.toLowerCase()) {
    case MARKETPLACE:
      idType = 5;
      break;
    case MEDICAID:
      idType = 1;
      break;
    case MEDICARE:
      idType = 2;
      break;
    case DUALS:
      switch (stateName) {
        case MI:
          idType = 18;
          break;
        case TX:
          idType = 16;
          break;
        case ID:
          idType = 2;
          break;
        case IL:
          idType = 7;
          break;
        case OH:
          idType = 11;
          break;
        case SC:
          idType = 15;
          break;
        case CA:
          idType = 7;
          break;
      }
      break;

    //TODO: Add more LOBs as per the need.
  }
  return idType;
};

export const getCaptcha = () => {
  return document?.getElementsByTagName("body")[0].classList.add("gcaptcha");
};

/**
 * Parses an incoming url to match against the route table. This function is implicitly used
 * by node-headless-ssr-proxy when rendering the site in headless mode. It enables rewriting the incoming path,
 * say '/en-US/hello', to the path and language to pass to Layout Service (a Sitecore item path), say
 * { sitecoreRoute: '/hello', lang: 'en-US' }.
 * This function is _not_ used in integrated mode, as Sitecore's built in route parsing is used.
 * If no URL transformations are required (i.e. single language site), then this function can be removed.
 * @param {string} url The incoming URL to the proxy server
 * @returns { sitecoreRoute?: string, lang?: string }
 */
export function parseRouteUrl(url) {
  if (!url) {
    return null;
  }

  let result = null;

  // use react-router-dom to find the route matching the incoming URL
  // then return its match params
  // we are using .some() as a way to loop with a short circuit (so that we stop evaluating route patterns after the first match)
  routePatterns.some((pattern) => {
    const match = matchPath(url, { path: pattern });
    if (match && match.params) {
      if (!match.params.lang) match.params.lang = ENGLISH_LANG_CODE;
      result = match.params;
      return true;
    }

    return false;
  });

  return result;
}

export const getUserType = (memberRoleId) => {
  switch (memberRoleId) {
    case DEPENDANT_ACCESS_GRANTED_ROLEID:
      return DEPENDENT_WITH_ACCESS;
    case DEPENDANT_NO_ACCESS_ROLEID:
      return DEPENDENT;
    default:
      return SUBSCRIBER;
  }
};

export const onSecuredPage = () => {
  const parsedUrl = parseRouteUrl(location.pathname).sitecoreRoute;
  if (
    serverConstants.unsecuredPages.indexOf(`/${parsedUrl ? parsedUrl : ""}`) ===
    -1
  ) {
    return true;
  }
  return false;
};

export const redirectTo = (redirectUrl, forceReload = false, target = "") => {
  if (redirectUrl) {
    if (forceReload) {
      location.hash = redirectUrl;
      location.reload();
    } else if (target !== "") {
      window.open(redirectUrl, target);
    } else {
      location.href = redirectUrl;
    }
  } else {
    location.reload();
  }
};

/**
 * Return true if logged in user is under FFM(Federally Facilitated Marketplaces) state!
 *
 * @param {*} stateCode
 * @returns
 */
export const isStateUnderFFMCategory = (stateCode) => {
  const ffmStates = [FL, MI, MS, OH, SC, TX, UT, WI, IL];
  return ffmStates.includes(stateCode.toUpperCase());
};

/**
 * Return true if logged in user is under SBM(State-based Marketplace) state!
 *
 * @param {*} stateCode
 * @returns
 */
export const isStateUnderSBMCategory = (stateCode) => {
  const sbmStates = [CA, WA, NM, KY, ID];
  return sbmStates.includes(stateCode.toUpperCase());
};

/**
 * Return true if logged in user has the statcode that matches with the states applicable for THRA Assessments
 *
 * @param {*} stateCode
 * @returns
 */
export const isStateApplicableForTHRAssessment = (stateCode) => {
  const thraStates = [FL, MI, NM, TX, UT];
  return thraStates.includes(stateCode.toUpperCase());
};

/**
 * Return true if logged in user has the statcode that matches with the states applicable for My Health Perks Molina Wellness Assessment.
 *
 * @param {*} stateCode
 * @returns
 */
export const isStateApplicableMyAssessmentMyHealthPerks = (stateCode) => {
  const myAssessmentMyHealthPerksState = [CA, FL, MI, NM, OH, TX, UT, WA, WI];
  return myAssessmentMyHealthPerksState.includes(stateCode.toUpperCase());
};

export const isStateApplicableMemberRewardsMyHealthPerks = (stateCode) => {
  const memberRewardsMyHealthPerksState = [
    IA,
    MI,
    NV,
    NE,
    WI,
    IL,
    WA,
    OH,
    VA,
    KY,
    FL,
    TX,
    NM,
    NY,
    AZ,
  ];
  return memberRewardsMyHealthPerksState.includes(stateCode.toUpperCase());
};

export const isMedicareStateApplicableForEHRA = (stateCode) => {
  const medicareStatesApplicableForEHRA = [
    AZ,
    ID,
    KY,
    MI,
    NV,
    NY,
    MA,
    OH,
    SC,
    TX,
    UT,
    WA,
    WI,
    IL,
    NE,
    MS,
  ];

  return medicareStatesApplicableForEHRA.includes(stateCode.toUpperCase());
};

export const isDualStateApplicableForEHRA = (stateCode) => {
  const dualStatesApplicableForEHRA = [ID];

  return dualStatesApplicableForEHRA.includes(stateCode.toUpperCase());
};

/**
 * Return helpful link
 */
export const getHelpfulLink = ({
  year = "Common",
  yearsList = null,
  comingSoonYear = "",
  comingSoonYearUrl = "",
}) => {
  let url = "#";

  let result = yearsList?.find(
    (item) => item.fields.yearText?.value.toString() === year
  );

  if (result !== null && result !== undefined) {
    url = result.fields.helpfulLink;
  }

  if (url === "#") {
    if (year === comingSoonYear) url = comingSoonYearUrl;
  }

  return url;
};

/**
 * Return Videos link
 */
export const getMyVideoLink = ({
  year = "Common",
  yearsList = null,
  comingSoonYear = "",
  comingSoonYearUrl = "",
}) => {
  let url = "#";

  let result = yearsList?.find(
    (item) => item.fields.yearText?.value.toString() === year
  );

  if (result !== null && result !== undefined) {
    url = result.fields?.myvideos;
  }

  return url;
};

export const getConfigByKey = (key, data) => {
  const datasets = data?.[0]?.fields?.ConfigList;
  if (datasets !== undefined) {
    const [result] = datasets
      .filter((dataset) => getFieldValue(dataset.fields, "key") === key)
      .map((dataset) => dataset.fields.value);
    return result?.value;
  }
};

export const disableSSO = ({ isTermedMember, type = "link" }) => {
  if (type === "button") {
    return isTermedMember ? "disabled" : "";
  }
  return isTermedMember ? "disabled-link" : "";
};

export const getSCRIPTremovedHTML = (str) => {
  return str.replace(SCRIPT_REGEX, "");
};

export const clearData = () => {
  localStorage.removeItem("CLOSED_ALERTS");
  localStorage.removeItem("state");
  localStorage.removeItem("landingPage");
  localStorage.removeItem("messageQuery");
  localStorage.removeItem("channel");
  localStorage.removeItem("IsSupportUser_LocalStorage");
};

export const showMyBillPopup = ({ stateCode }) => {
  if (stateCode === NM && !isNMStatePopupExpired()) {
    return true;
  }
  return false;
};

export const isNMStatePopupExpired = () => {
  const currentDate = new Date();
  const expiryDate = new Date(EXPIRY_DATE_FOR_NM_STATE_POPUP);
  if (currentDate > expiryDate) {
    return true;
  }
  return false;
};

export const isAccessibleToSupportUser = (ssoEndPoint) => {
  if (allowedSSOForSupportUser().includes(ssoEndPoint)) {
    return true;
  }
  return false;
};

export const isAccessibleToTermedMember = (ssoEndPoint) => {
  if (allowedSSOForTermedMember().includes(ssoEndPoint)) {
    return true;
  }
  return false;
};

export const isAccessibleToSwitchedDependent = (ssoEndPoint) => {
  if (allowedSSOForSwitchedDependent().includes(ssoEndPoint)) {
    return true;
  }
  return false;
};

export const allowedSSOForSupportUser = () => {
  return [POD_SAPPHIRE_SSO, HPS_SSO, PDHI_SSO, INSTAMED_SSO];
};

export const allowedSSOForTermedMember = () => {
  return [HPS_SSO];
};

export const allowedSSOForSwitchedDependent = () => {
  return [
    HEALTH_SHERPA_SSO,
    POD_SAPPHIRE_SSO,
    HPS_SSO,
    CVS_ACCORDANT_SSO,
    PDHI_SSO,
    INSTAMED_SSO,
  ];
};

export const handleLogout = (
  isSupportUser = false,
  evnt = null,
  isCABrightcareMember = false
) => {
  evnt && evnt.preventDefault();

  if (!isSupportUser) {
    commonAPI.deleteSessionInfo().catch((e) => console.error(e));
  }

  var response;
  commonAPI
    .logout()
    .then((res) => {
      response = res;
      console.log(response);
      clearData();
    })
    .catch(function (e) {
      console.error(e);
    })
    .finally(function () {
      console.log(response);
      let postLogoutUrl = window.location.origin;
      if (response) {
        postLogoutUrl = isSupportUser
          ? response?.ADMIN_PORTAL_URL
          : isCABrightcareMember
          ? response?.BRIGHT_CARE_PORTAL_URL
          : response?.LEGACY_PORTAL_URL;
      }
      if (isSupportUser) {
        redirectTo(postLogoutUrl);
      } else {
        //Ending session from Ping
        getOidcClient().then((res) => {
          res.endSession(postLogoutUrl); //redirects to postLogoutUrl after signoff from Ping
        });
      }
    });
};

export const getLoginEnv = () => {
  var response = {
    OIDC_AUTH_BASEURL: OIDC_AUTH_BASEURL,
    OIDC_CLIENT_ID: OIDC_CLIENT_ID,
  };
  commonAPI
    .getLoginEnv()
    .then((res) => {
      response = res;
      console.log(response);
    })
    .catch(function (e) {
      console.error(e);
    });

  return response;
};

export const getOidcClient = async () => {
  const { OidcClient } = await import(
    "@pingidentity-developers-experience/ping-oidc-client-sdk"
  );
  const { v4: uuidv4 } = require("uuid");

  const langPath =
    window.location.pathname?.toLocaleLowerCase().indexOf("/es") > -1
      ? SPANISH_LANG_PATH
      : ENGLISH_LANG_PATH;
  const redirectURI = window.location.origin + langPath + MyMolinaLoginUrl;

  //const response = await commonAPI.getLoginEnv();

  var loginEnv = {
    OIDC_AUTH_BASEURL: OIDC_AUTH_BASEURL,
    OIDC_CLIENT_ID: OIDC_CLIENT_ID,
  };

  await commonAPI
    .getLoginEnv()
    .then((response) => {
      if (response && response?.OIDC_AUTH_BASEURL && response?.OIDC_CLIENT_ID) {
        loginEnv = response;
      }
    })
    .catch(() => {});

  const clientOptions = {
    client_id: loginEnv.OIDC_CLIENT_ID,
    redirect_uri: redirectURI,
    response_type: "code",
    usePkce: false,
  };

  const state = localStorage.getItem("state");

  if (state) {
    clientOptions["state"] = state;
  } else {
    clientOptions["state"] = uuidv4();
  }

  const client = await OidcClient.initializeFromOpenIdConfig(
    loginEnv.OIDC_AUTH_BASEURL,
    clientOptions
  );

  return client;
};

export const handleLogin = async (event = null) => {
  event && event.preventDefault();
  const client = await getOidcClient();
  await client.authorize();
};

export const brightCarePortal = async () => {
  let brightCareHostName = process.env.BRIGHT_CARE_HOST_NAME;
  const resp = await commonAPI.getBrightCareConfig();
  if (resp && resp?.BRIGHT_CARE_HOST_NAME) {
    brightCareHostName = resp?.BRIGHT_CARE_HOST_NAME;
  }

  return window.location.hostname.includes(brightCareHostName) ? true : false;
};

export const isNYMDCHP = (memberLOB, MemberStateName, programId) => {
  let isNYMDEP =
    memberLOB?.toLowerCase() === MEDICAID?.toLowerCase() &&
    MemberStateName === NY &&
    NY_MD_EP_PROGRAMID_LIST.includes(programId);
  return isNYMDEP;
};
