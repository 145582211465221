import React, { useContext } from "react";
import {
  Text,
  RichText,
  getFieldValue,
  Image,
} from "@sitecore-jss/sitecore-jss-react";
import { AppContext } from "../../contexts/app.context";
import {
  ICON_PATH,
  MARKETPLACE,
  MEDICAID,
  NM,
  NM_MCAID_PROGID,
  GLOBAL_VARIABLE,
} from "../../define.constants";
import { getStateConfiguration, getMyVideoLink } from "./../../utils/helpers";
import { createLinkIcon } from "../MyResources-FormDocs/Helper";
import { WhoIsAuthorized } from "../../common/WhoIsAuthorized";

const MyResourcesMyVideos = (props) => {
  let {
    MemberStateName,
    memberLOB,
    programId,
    isTermedMember,
    isCABrightcareMember,
  } = useContext(AppContext);
  GLOBAL_VARIABLE.genesysIntent = getFieldValue(props?.fields, "intent");

  const list = getStateConfiguration(props.fields, MemberStateName, memberLOB);

  // Flag to show message for termed members instead of HelpfulLinks
  let isSpcialTermedMember = false;

  // MEDICAID Check- NM
  const isMedicaidMember = memberLOB?.toLowerCase() === MEDICAID;
  isSpcialTermedMember =
    isMedicaidMember &&
    MemberStateName?.toUpperCase() === NM &&
    programId === NM_MCAID_PROGID &&
    isTermedMember;

  const isMarketPlaceMember = memberLOB?.toLowerCase() === MARKETPLACE;

  const displayCards = (classname, item, index) => {
    return (
      <div>
        {item.fields.popUpContent?.value != "" &&
        item.fields.popUpContent?.value != null &&
        item.fields.popUpContent?.value != undefined ? (
          <li
            className={`list-group-item cards-md-tab2 ${classname}`}
            key={index}
          >
            <div className="row">
              <div className="col-xxl-3 col-lg-3 col-md-4 list-title card-titles row">
                <div className="col-3 col-md-4 col-sm-3">
                  <Image
                    className="carousel-icon"
                    key={index}
                    field={item.fields?.image}
                  />
                </div>
                <div className="col-8 col-md-8 col-sm-7 ps-4">
                  <Text field={item.fields.myvideosTitle} />
                </div>
              </div>
              <div className="col-xxl-9 col-lg-8 col-md-7 list-desc2">
                <div className="card-desc content-md-tab2">
                  <RichText field={item.fields.myvideosDescription} />
                </div>
              </div>
              <div className="list-multi-link-drp">
                <a
                  id={`a_downloadIcon${index}`}
                  href="#"
                  rel="noopener noreferrer"
                  className="list-icon-newtab-myvidoes myvidoes-coming-soon"
                >
                  {item.fields.popUpContent?.value}
                </a>
              </div>
            </div>
          </li>
        ) : (
          <li
            className={`list-group-item cards-md-tab2 ${classname}`}
            key={index}
          >
            <div className="row">
              <div className="col-xxl-3 col-lg-3 col-md-4 list-title card-titles row">
                <div className="col-3 col-md-4 col-sm-3">
                  <Image
                    className="carousel-icon"
                    key={index}
                    field={item.fields?.image}
                  />
                </div>
                <div className="col-8 col-md-8 col-sm-7 ps-4">
                  <Text field={item.fields.myvideosTitle} />
                </div>
              </div>
              <div className="col-xxl-9 col-lg-8 col-md-8 list-desc2">
                <div className="card-desc content-md-tab2">
                  <RichText field={item.fields.myvideosDescription} />
                </div>
              </div>

              <div className="list-multi-link-drp">
                {item.fields.myvideosYearList &&
                item.fields.myvideosYearList != null &&
                item.fields.myvideosYearList != undefined ? (
                  <>
                    {createLinkIcon({
                      link: getMyVideoLink({
                        yearsList: item.fields.myvideosYearList,
                      }),
                      index,
                      language: "Common",
                      className: "list-icon-newtab",
                      multiLanguage: false,
                      imageName: "open_in_new-24px.svg",
                      cls_vname:
                        MemberStateName +
                        "_" +
                        memberLOB +
                        "_" +
                        item.fields.myvideosTitle.value.replace(/\s+/g, "_"),
                    })}
                  </>
                ) : null}
              </div>
            </div>
          </li>
        )}
      </div>
    );
  };

  return isMarketPlaceMember || isMedicaidMember || isCABrightcareMember ? (
    <>
      <br />
      <div style={{ font: "normal normal normal .8rem/.92rem Arial" }}>
        <Text field={props?.fields?.title} />
      </div>
    </>
  ) : (
    <div
      className="tab-pane fade show active my-resources-page"
      id="MyVideos"
      role="tabpanel"
      aria-labelledby="tab-2"
    >
      <div className="row no-tab-title cards-section-tab2">
        <div className="col-md-12">
          {isSpcialTermedMember ? (
            <Text field={props?.fields?.TermedMemberMessage} />
          ) : (
            <ul className="list-group">
              {Array.isArray(list) && list.length && (
                <>
                  {list?.map((item, index) => {
                    return displayCards("cards-tab2", item, index);
                  })}
                </>
              )}
            </ul>
          )}
        </div>
      </div>
    </div>
  );
};

export default MyResourcesMyVideos;
