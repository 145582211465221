import Axios from "./axios/provider";

export const getOneildigitalid = async (
  state,
  lob,
  memberId,
  type,
  planYear,
  TransactionId
) => {
  try {
    const response = await Axios.post(constants.apigeeURLs.Oneildigitalid, {
      state,
      lob,
      memberId,
      type,
      planYear,
      TransactionId,
    });
    return response;
  } catch (error) {
    throw error;
  }
};
