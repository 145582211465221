import React, { createContext, useContext, useEffect, useState } from "react";
import { KY } from "../define.constants";
import { onSecuredPage } from "../utils/helpers";
import { AppContext } from "./app.context";
import { brightCarePortal } from "../utils/helpers";

const ThemeContext = createContext({});

function ThemeProvider({ children }) {
  const { MemberStateName } = useContext(AppContext);
  const [themeVariant, setThemeVariant] = useState("state-default");
  const context = {};

  useEffect(() => {
    if (!onSecuredPage()) {
      setThemeVariant("");
    }
  }, []);

  useEffect(() => {
    if (MemberStateName) {
      // if (MemberStateName === KY) {
      //   setThemeVariant("state-KY");
      // } else {
      //   setThemeVariant("");
      // }
      // setThemeVariant("");
    }
  }, [MemberStateName]);

  useEffect(async () => {
    (await brightCarePortal())
      ? setThemeVariant("chp-CA")
      : setThemeVariant("");
  }, []);

  return (
    <ThemeContext.Provider value={context}>
      <div className={`theme-wrapper ${themeVariant}`.trim()}>{children}</div>
    </ThemeContext.Provider>
  );
}

export { ThemeContext, ThemeProvider };
