import React, { useContext, useEffect, useState } from "react";
import { CustomSelect } from "../../common/Select";
import { InlineFormError } from "../../common/InlineFormError";
import * as MessageAPI from "../../services/MessagesAPI";
import { useListServicesAPI } from "../../hooks/useListServicesAPI";
import { SpinnerContext } from "../../contexts/spinner.context";
import { ErrorContext } from "../../contexts/error.context";
import { AppContext } from "./../../contexts/app.context";
import { highlightBorderOnError } from "../../utils/helpers";
import * as Yup from "yup";
import * as CommonAPI from "../../services/Common";

import {
  getFieldValue,
  Text,
  RichText,
} from "@sitecore-jss/sitecore-jss-react";
import {
  ICON_PATH,
  SUCCESS_RESULT_CODE,
  SUCCESS_MESS_SENT_RESULT_CODE,
  CONTRACT_NAME,
  DISPLAY_POSITION,
  PHONE_VALID_REGEX,
  GENERALQUESTIONS,
  NURSEADVICELINE,
  MyMessagesUrl,
  HomePageUrl,
  EMAIL_REGEX_CONTACT,
  SPANISH_LANG_CODE,
  SPANISH_LANG_TEXT,
  ENGLISH_LANG_CODE,
} from "../../define.constants";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { separateOperations } from "graphql";
import isThisHour from "date-fns/isThisHour/index.js";
import CancelConfirmationDialog from "./CancelConfirmationDialog";
import { QueryDocumentKeys } from "graphql/language/visitor";
import FileUploadErrorDialog from "./FileUploadErrorDialog";

const CreateNewMessage = ({ fields, forDesktop = true, onPrint }) => {
  const { setSpinner } = useContext(SpinnerContext);
  const { unreadInboxMsgCount, prefLang } = useContext(AppContext);
  const { setError } = useContext(ErrorContext);
  const [checkContactMethod, setCheckContactMethod] = useState("");
  const [successMess, setSuccessMess] = useState(false);
  const { MemberStateName, isCABrightcareMember } = useContext(AppContext);
  const { MsgServices } = useListServicesAPI();
  const { isSupportUser } = useContext(AppContext);
  let generalQuestions = GENERALQUESTIONS;
  let nurseAdviceLine = NURSEADVICELINE;
  let uploadIcon = (
    <img
      src={`${ICON_PATH}/upload.svg`}
      width="18px"
      height="18px"
      alt={getFieldValue(fields, "lblPrint")}
    />
  );

  const langcode = `${
    SPANISH_LANG_TEXT === prefLang.toLowerCase() ||
    SPANISH_LANG_CODE === prefLang.toLowerCase()
      ? SPANISH_LANG_CODE
      : ENGLISH_LANG_CODE
  }`;

  let closeIcon = (
    <img
      src={`${ICON_PATH}/cross1.jpg`}
      width="18px"
      height="18px"
      alt={getFieldValue(fields, "lblPrint")}
      onClick={() => deleteAttachments()}
    />
  );
  const [selectedFile, setSelectedFile] = useState();
  const [selectedDetails, setSelectedDetails] = useState([]);
  const [fileUploadFlag, setFileUploadFlag] = useState();
  let listsOfBase64Strings = [];
  let strval = [];

  const [fileUploadErrorDialog, setFileUploadErrorDialog] = useState({
    message: "",
    isLoading: false,
  });

  const confirmToClose = (choose) => {
    handleFileUploadErrorDialog("", false);
  };
  const handleFileUploadErrorDialog = (message, isLoading) => {
    setFileUploadErrorDialog({
      message,
      isLoading,
    });
  };

  const supportedFileTypes: string[] = [
    "application/pdf",
    "image/png",
    "image/jpg",
    "image/jpeg",
    "application/doc",
    "application/ms-doc",
    "application/msword",
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    "application/excel",
    "application/vnd.ms-excel",
    "application/x-excel",
    "application/x-msexcel",
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  ];

  const sizeLimit: numer = 5 * 1048576;

  const changeHandler = async (event) => {
    let intval = 0;
    if (selectedDetails.length > 0) {
      intval = 3 - selectedDetails.length;
    } else if (event.target.files.length >= 3) {
      intval = 3;
    } else {
      intval = event.target.files.length;
    }

    for (let i = 0; i < intval; i++) {
      const fileTypeError = hasFileTypeError(
        event.target.files[i],
        supportedFileTypes
      );
      const fileSizeError = hasFileSizeError(event.target.files[i], sizeLimit);
      /*const fileUploadFlag = !(
        hasFileTypeError(event.target.files[i], supportedFileTypes) ||
        hasFileSizeError(event.target.files[i], sizeLimit)
      );*/

      const fileUploadFlag = hasFileTypeError(
        event.target.files[i],
        supportedFileTypes
      );

      //setFileUploadFlag(fileTypeError);

      if (fileTypeError) {
        handleFileUploadErrorDialog(
          <Text field={fields.FileErrorpopup} />,
          true
        );
      } else if (fileSizeError) {
        handleFileUploadErrorDialog(
          <Text field={fields.FileErrorSizePopup} />,
          true
        );
      } else {
        setSelectedFile(event.target.files[i]);
        if (selectedDetails.length > 0 && i == 0) {
          strval.push(selectedDetails[0]);
          if (selectedDetails.length > 1) {
            strval.push(selectedDetails[1]);
          }
        }
        if (
          event.target.files[i] &&
          selectedDetails.filter(
            (str) => str.name == event.target.files[i].name
          ).length == 0
        ) {
          strval.push(event.target.files[i]);
        }
        if (strval.length > 0) {
          setSelectedDetails(strval);
        }
      }
    }
  };

  const resetFormUpload = () => {
    setSelectedDetails([]);
  };

  const hasFileTypeError = (file: File, supportedFileTypes): boolean =>
    file && !supportedFileTypes.includes(file.type);

  const hasFileSizeError = (file: File, sizeLimit): boolean =>
    file && file.size > sizeLimit;

  const deleteAttachments = (val) => {
    setSelectedDetails(selectedDetails.filter((str) => str.name != val));
  };

  const SignupSchema = Yup.object().shape({
    category: Yup.string().required("*"),
    description: Yup.string().trim().required("*"),
    contactMethodOH: Yup.string().required("*"),
  });

  const WithoutSignupSchema = Yup.object().shape({
    category: Yup.string().required("*"),
    description: Yup.string().trim().required("*"),
  });

  const ResetMessForm = (values) => {
    values.category = "";
    values.subject = "";
    values.description = "";
    values.contactMethodOH = "";
    values.contactMethodOther = "";
    resetFormUpload();
  };
  const handleOnClick = () => {
    location =
      "/" +
      (prefLang !== "" &&
      (SPANISH_LANG_TEXT.toLowerCase() === prefLang.toLowerCase() ||
        SPANISH_LANG_CODE.toLocaleLowerCase() === prefLang.toLocaleLowerCase())
        ? SPANISH_LANG_CODE
        : ENGLISH_LANG_CODE) +
      MyMessagesUrl;
  };
  const handleOnClickBackHone = () => {
    location =
      "/" +
      (prefLang !== "" &&
      (SPANISH_LANG_TEXT.toLowerCase() === prefLang.toLowerCase() ||
        SPANISH_LANG_CODE.toLocaleLowerCase() === prefLang.toLocaleLowerCase())
        ? SPANISH_LANG_CODE
        : ENGLISH_LANG_CODE) +
      HomePageUrl;
  };
  const roundOff = (value) => {
    return Math.round(value * 100) / 100;
  };
  const countDisplay = (description) => {
    return description.trim() ? 32000 - description.trim().length : 32000;
  };
  const options = [
    { value: "", label: <Text field={fields.lblCategoryPH} />, id: "" },
  ];
  if (options.length === 1) {
    MsgServices?.map((data) => {
      if (!data?.Category.includes("NAL")) {
        options.push({
          value: data?.Category,
          id: data?.OptionID,
          label: data?.Category,
        });
      }
    });
  }

  const [dialog, setCancelConfirmationDialog] = useState({
    message: "",
    isLoading: false,
  });

  const handleDialog = (message, isLoading) => {
    setCancelConfirmationDialog({
      message,
      isLoading,
    });
  };

  const handleOnCancel = () => {
    handleDialog(<Text field={fields.Cancelpopup} />, true);
  };

  const confirmToCancel = (choose) => {
    if (choose) {
      //reset the form
      document.getElementById("create-message-form").reset();
      setSelectedDetails([]);

      handleDialog("", false);
    } else {
      handleDialog("", false);
    }
  };
  const getBase64String1 = (selectedFile) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(selectedFile);
      reader.onload = () => {
        resolve(reader.result.split(",")[1]);
        //resolve(reader.result);
      };
      reader.onerror = (error) => {
        console.log("Error::::" + error);
      };
    });
  };

  const onLoad = (fileString) => {
    console.log(fileString);
    listsOfBase64Strings.push(fileString);
  };
  return (
    <>
      <div className="container">
        <div className="row">
          <div className="col-xl-2 col-md-3">
            <div className="page-heading">
              <Text field={fields.heading} />
            </div>
          </div>
        </div>
      </div>
      <div className="row inbox-container">
        <div className="col-xl-2 col-md-4 message-left-panel d-none d-md-block">
          <div className="message-menu-list row">
            <div className="col-md-12  active" onClick={() => handleOnClick()}>
              <div className="message-menu ">
                <img src={`${ICON_PATH}/email-24px.svg`} alt="Inbox" />
                <Text field={fields.lblInbox} />
                {unreadInboxMsgCount != 0 && (
                  <span className="msg-counter">{unreadInboxMsgCount}</span>
                )}
              </div>
            </div>
            <div className="col-md-12 tab-disabled ">
              <div className="message-menu tab-disabled">
                <img
                  src={`${ICON_PATH}/archive-24px-dark.svg`}
                  alt="Archived"
                />
                <Text field={fields.lblArchived} />
              </div>
            </div>
          </div>
        </div>
        <div className="col-xl-10 col-md-8 mess-top-head">
          <Formik
            initialValues={{
              category: "",
              subject: "",
              description: "",
              contactMethodOH: "",
              contactMethodOther: "",
              Attachments: "",
            }}
            validationSchema={
              MemberStateName == "OH" ? SignupSchema : WithoutSignupSchema
            }
            onSubmit={async (values) => {
              console.log(selectedDetails);
              setSpinner(true);

              for (let i = 0; i < selectedDetails.length; i++) {
                const base64String = await getBase64String1(selectedDetails[i]);
                listsOfBase64Strings.push(base64String);
              }
              var res1 = await MessageAPI.CreateNewConversation({
                Subject: values?.subject,
                CategoryID: values?.category,
                Description: values?.description.trim(),
                NotificationChannel: values?.contactMethodOther,
                PreferredCommunication: values?.contactMethodOH,
                Attachments: "",
              }).catch((err) => {
                setSpinner(false);
                setError({ err });
              });
              var responseConversationId =
                res1?.ResultData?.conversation?.ConversationId;

              for (let i = 0; i < listsOfBase64Strings.length; i++) {
                var res2 = await MessageAPI.setAttachment({
                  ConversationId: responseConversationId,
                  Body: listsOfBase64Strings[i],
                  Name: selectedDetails[i].name,
                });
                var resfinal = res2;
              }
              var flag1 = res1?.Message;
              var flag2 = res2?.Message;

              if (
                listsOfBase64Strings.length == 0 &&
                res1?.Message === "Success"
              ) {
                setSuccessMess(true);
                setSpinner(false);
              } else if (
                listsOfBase64Strings.length > 0 &&
                res1?.Message === "Success" &&
                res2?.Message === "Success"
              ) {
                setSuccessMess(true);
                setSpinner(false);
              } else {
                setSuccessMess(false);
                setSpinner(true);
              }

              if (values.contactMethodOH === "Phone") {
                setCheckContactMethod(fields?.MessageSendSuccessOHPhone);
              } else {
                setCheckContactMethod(fields?.MessageSendSuccess);
              }
              ResetMessForm(values);
            }}
          >
            {({ touched, errors, values, setFieldValue }) => (
              <Form id="create-message-form">
                <div>
                  <div className="head-create-mess">
                    <div className="crt-mess-heading">
                      <Text field={fields.lblCreateMessage} />
                    </div>
                    <div className="subhead-crt-mess">
                      <RichText field={fields.needAssistance} />
                    </div>
                    <div className="subhead-crt-mess-text">
                      <RichText field={fields.AttachmentInfo} />
                    </div>
                    <div className="crt-mess-heading">
                      <Text field={fields?.messageDetails} />
                    </div>
                    <div className="row">
                      <div className="row-title">
                        <Text field={fields?.lblCategory} />
                        {options.lengths}
                        <ErrorMessage
                          name="category"
                          component={InlineFormError}
                        />
                      </div>
                      <div className="dropdown-menu-width">
                        <Field
                          as="select"
                          id="category"
                          name="category"
                          options={options}
                          value={
                            options
                              ? options.find(
                                  (option) => option.value === values.category
                                )
                              : ""
                          }
                          component={CustomSelect}
                          onChange={() => {}}
                          customclass={` ${highlightBorderOnError(
                            errors.category,
                            touched.category
                          )}`}
                          isFormik={true}
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="row-title">
                        <Text field={fields?.lblSubject} />
                      </div>
                      <div className="dropdown-menu-width">
                        <Field
                          id="subject"
                          name="subject"
                          placeholder={getFieldValue(fields, "lblSubjectPH")}
                          className="form-control"
                          maxLength={250}
                        />
                        <ErrorMessage
                          name="subject"
                          component={InlineFormError}
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="row-title">
                        <Text field={fields?.lblDescription} />{" "}
                        <ErrorMessage
                          name="description"
                          component={InlineFormError}
                        />
                      </div>
                      <div className="row-sub-text">
                        <Text field={fields?.lblDescinfo} />
                      </div>
                      <div className="txt-area-width">
                        <Field
                          name="description"
                          id="description"
                          as={`textarea`}
                          maxLength={32000}
                          rows={3}
                          placeholder={getFieldValue(
                            fields,
                            "lblDescriptionPH"
                          )}
                          className={`form-control ${highlightBorderOnError(
                            errors.description,
                            touched.description
                          )}`}
                        />
                        <div className="row-sub-text">
                          <Text field={fields?.lblMaxchars} />
                          {countDisplay(values.description)}{" "}
                          <Text field={fields?.lblremaining} />
                        </div>
                      </div>
                    </div>
                    {MemberStateName == "OH" && (
                      <div className="row">
                        <div className="row-title">
                          <Text field={fields?.lblPreferedContactMethod} />
                          <ErrorMessage
                            name="contactMethodOH"
                            component={InlineFormError}
                          />
                        </div>
                        <div className="row-sub-text">
                          <Text field={fields?.lblChoosereplymsg} />
                        </div>
                        <div className="txt-area-width">
                          <div role="group" aria-labelledby="my-radio-group">
                            <label className="label-radio">
                              <Field
                                type="radio"
                                name="contactMethodOH"
                                value="Message Center"
                              />
                              &nbsp;
                              <Text field={fields?.lblMessageCenter} />
                            </label>
                            <br />
                            <label className="label-radio">
                              <Field
                                type="radio"
                                name="contactMethodOH"
                                value="Phone"
                              />
                              &nbsp;
                              <Text field={fields?.lblPhone} />
                            </label>
                          </div>
                        </div>
                      </div>
                    )}
                    <div className="row">
                      <div className="row-title">
                        <Text field={fields?.lblAttachment} />
                      </div>
                      <div></div>
                      <div>
                        <label
                          className="custom-file-upload"
                          title={getFieldValue(fields, "lblchoosefilestt")}
                        >
                          <input
                            type="file"
                            name="uploadedfile"
                            id="uploadedfile"
                            multiple
                            disabled={
                              selectedDetails.length == 3 ? true : false
                            }
                            className="btn btn-upload"
                            onChange={changeHandler}
                          />
                          <i className="fa fa-cloud-upload" />
                          {uploadIcon}
                          <Text field={fields?.lblChoosefiles} />
                        </label>
                      </div>
                      <div className="label-attachments">
                        <Text field={fields?.InfoAttachment} />
                      </div>
                      <div className="row filedetails">
                        {selectedDetails?.map((file, index) => {
                          return (
                            <React.Fragment key={file.name}>
                              <div className="label-file-upload">
                                <table
                                  style={{
                                    border: "1px solid lightblue",
                                    margintop: "3px",
                                  }}
                                >
                                  <tr>
                                    <td> {file.name}</td>
                                    <td style={{ marginleft: "178px" }}>
                                      <img
                                        src={`${ICON_PATH}/cross1.jpg`}
                                        width="10px"
                                        height="13px"
                                        margin-left="178px"
                                        margin-top="4px"
                                        margin-bottom="3px"
                                        className="img-close"
                                        alt={getFieldValue(fields, "lblPrint")}
                                        onClick={() =>
                                          deleteAttachments(file.name)
                                        }
                                      />
                                    </td>
                                  </tr>
                                </table>
                              </div>
                            </React.Fragment>
                          );
                        })}
                      </div>
                    </div>

                    {((MemberStateName == "OH" &&
                      values.contactMethodOH === "Message Center") ||
                      MemberStateName != "OH") && (
                      <div className="row">
                        <div className="row-radio-btn-sub-text">
                          <Text field={fields?.lblSelectOption} />
                        </div>
                        <div className="txt-area-width">
                          <div role="group" aria-labelledby="my-radio-group">
                            <label className="label-radio">
                              <Field
                                type="radio"
                                name="contactMethodOther"
                                value="Send me a text message"
                              />
                              &nbsp;
                              <Text field={fields?.Sendmetext} />
                            </label>
                            <br />
                            <label className="label-radio">
                              <Field
                                type="radio"
                                name="contactMethodOther"
                                value="Send me an email"
                              />
                              &nbsp;
                              <Text field={fields?.Sendmeemail} />
                            </label>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                  <div className=" row create-mess-snd-btn">
                    {successMess != true && (
                      <div className="float-right">
                        <button
                          type="submit"
                          title={getFieldValue(fields, "lblSendtt")}
                          disabled={isSupportUser ? true : false}
                          className={
                            isSupportUser
                              ? "btn btn-primary btn-margin btn-send-termed"
                              : "btn btn-primary btn-margin btn-send"
                          }
                        >
                          <Text field={fields?.btnSend} />
                        </button>

                        <button
                          type="button"
                          onClick={handleOnCancel}
                          title={getFieldValue(fields, "lblCanceltt")}
                          className="btn btn-primary btn-margin btn-cancel"
                        >
                          <Text field={fields?.btnCancel} />
                        </button>
                        {dialog.isLoading && (
                          <CancelConfirmationDialog
                            onDialog={confirmToCancel}
                            message={dialog.message}
                            fields={fields}
                            caBrightcareMember={isCABrightcareMember}
                          ></CancelConfirmationDialog>
                        )}
                        {fileUploadErrorDialog.isLoading && (
                          <FileUploadErrorDialog
                            message={fileUploadErrorDialog.message}
                            onDialog={confirmToClose}
                            isCABrightcareMember={isCABrightcareMember}
                          ></FileUploadErrorDialog>
                        )}
                      </div>
                    )}
                    {successMess === true && (
                      <div className="float-right">
                        <button
                          type="button"
                          className="btn btn-primary btn-margin btn-send"
                          onClick={() => handleOnClickBackHone()}
                        >
                          <Text field={fields?.btnBacktohomepage} />
                        </button>
                      </div>
                    )}
                  </div>
                  {successMess == true && (
                    <div className="row success-msg">
                      <RichText field={checkContactMethod} />
                    </div>
                  )}
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </>
  );
};

export default CreateNewMessage;
