import {
  RichText,
  Text,
  getFieldValue,
} from "@sitecore-jss/sitecore-jss-react";
import { Form, Formik, Field, ErrorMessage } from "formik";
import { AppContext } from "../../contexts/app.context";
import { SWH_PROGRAMS } from "../../define.constants";
import React, { useContext } from "react";
import * as Yup from "yup";
import { PhoneFormat, ZipFormat } from "./helper";
import { InlineFormError } from "../../common/InlineFormError";
import { RequestProviderDirectoryHardCopy } from "../../services/MessagesAPI";
import { ModalContext } from "../../contexts/modal.context";
import { SpinnerContext } from "../../contexts/spinner.context";

const RequestProviderDirectory = (props) => {
  const { fields } = props;
  const { programId, MemberStateName, isCABrightcareMember } =
    useContext(AppContext);
  const { openModal, closeModal } = useContext(ModalContext);
  const { setSpinner } = useContext(SpinnerContext);
  const SWHPrograms = SWH_PROGRAMS;
  const requiredMark = <span style={{ color: "red" }}>*</span>;
  var heading = "";
  heading =
    isCABrightcareMember != "" ? (
      isCABrightcareMember ? (
        <h3
          className="tab-sub-title"
          style={{ color: "#005d63", paddingBottom: "0.76rem" }}
        >
          <Text field={fields.headingCHP} />
        </h3>
      ) : SWHPrograms.includes(programId) ? (
        <h3
          className="tab-sub-title"
          style={{ color: "#009ea0", paddingBottom: "0.76rem" }}
        >
          <Text field={fields.headingSWH} />
        </h3>
      ) : (
        <h3
          className="tab-sub-title"
          style={{ color: "#009ea0", paddingBottom: "0.76rem" }}
        >
          <Text field={fields.heading} />
        </h3>
      )
    ) : (
      ""
    );

  const RequestSchema = Yup.object().shape({
    phone: Yup.string()
      .required(getFieldValue(fields, "phoneRequired"))
      .test(
        "phoneLen",
        getFieldValue(fields, "phoneLen"),
        (val) => val?.length == 13 // three extra character for '(', ')', and '-'
      ),
    address: Yup.string().required(getFieldValue(fields, "addressRequired")),
    city: Yup.string().required(getFieldValue(fields, "cityRequired")),
    state: Yup.string().required(getFieldValue(fields, "stateRequired")),
    zip: Yup.string()
      .required(getFieldValue(fields, "zipRequired"))
      .test(
        "zipLen",
        getFieldValue(fields, "zipLen"),
        (val) => val?.length == 5 || val?.length == 10 // one extra character for '-'
      ),
  });
  const formValues = {
    subject: "",
    phone: "",
    address: "",
    apartment: "",
    city: "",
    state: "",
    zip: "",
  };

  const printModalMessage = (isSuccessful) => {
    if (isSuccessful) {
      return (
        <div>
          <RichText field={fields.successMessage} />
          <div style={{ textAlign: "right" }}>
            <button
              type="button"
              className="btn btn-primary"
              onClick={closeModal}
            >
              <Text field={fields.modalButton} />
            </button>
          </div>
        </div>
      );
    } else {
      return (
        <div>
          <RichText field={fields.failMessage} />
          <div style={{ textAlign: "right" }}>
            <button
              type="button"
              className="btn btn-primary"
              onClick={closeModal}
            >
              <Text field={fields.modalButton} />
            </button>
          </div>
        </div>
      );
    }
  };

  const MakeRequest = async (submitProps, { resetForm }) => {
    const request = {
      PhoneNumber: submitProps.phone,
      StreetAddress: submitProps.address,
      ApartmentNumber: submitProps.apartment,
      City: submitProps.city,
      State: submitProps.state,
      ZipCode: submitProps.zip,
    };

    let response;
    setSpinner(true);
    try {
      response = await RequestProviderDirectoryHardCopy(request);
    } finally {
      setSpinner(false);
      if (response?.ResultData) {
        openModal({
          header: <Text field={fields.header} />,
          content: printModalMessage(true),
          version: 1,
        });
        resetForm();
      } else {
        openModal({
          header: <Text field={fields.header} />,
          content: printModalMessage(false),
          version: 1,
        });
      }
    }
  };

  const MyMessageLink = () => {
    window.location.href = "./RequestProviderDirectory";
  };

  return (
    <div>
      <div style={{ textAlign: "right" }}>
        <button
          type="button"
          className="btn btn-primary m-sm-3 my-md-3 ml-md-3 w-md-35 w-sm-100"
          onClick={MyMessageLink}
        >
          <Text field={fields.btnMessage} />
        </button>
        <button
          type="button"
          disabled="true"
          className="btn btn-light m-sm-3 my-md-3 ml-md-3 w-md-35 w-sm-100"
        >
          <Text field={fields.btnArchive} />
        </button>
        <button
          type="button"
          disabled="true"
          className="btn btn-light m-sm-3 my-md-3 ml-md-3 w-md-35 w-sm-100"
        >
          <Text field={fields.btnPrint} />
        </button>
      </div>
      <div className="row">{heading}</div>
      <div>
        <Text field={fields.instructions} />
      </div>
      <div style={{ textAlign: "right" }}>
        {requiredMark}
        <Text field={fields.requiredField} />
      </div>
      <Formik
        initialValues={formValues}
        onSubmit={MakeRequest}
        validationSchema={RequestSchema}
        enableReinitialize
      >
        {(props) => {
          return (
            <Form>
              <div className="row m-2">
                <div className="col-sm-12 col-md-2 row-title align-self-center">
                  <label htmlFor="subject">
                    {requiredMark}
                    <Text field={fields.lblSubject} />
                  </label>
                </div>
                <div className="col-sm-12 col-md-4 align-self-center row-title">
                  <Field
                    name="subject"
                    id="subject"
                    as="select"
                    className="form-select"
                    style={{ display: "block" }}
                  >
                    <option
                      value=""
                      label="Request Provider Directory"
                      key="default"
                    />
                  </Field>
                </div>
              </div>
              <div className="row m-2">
                <div className="col-sm-12 col-md-2 row-title align-self-center">
                  <label htmlFor="phone">
                    {requiredMark}
                    <Text field={fields.lblPhone} />
                  </label>
                </div>
                <div className="col-sm-12 col-md-4 align-self-center row-title">
                  <Field
                    name="phone"
                    id="phone"
                    type="text"
                    maxLength="13"
                    onKeyDown={PhoneFormat(props)}
                    className={`form-control  ${
                      props.errors.phone && props.touched.phone && "is-invalid"
                    }`}
                  />
                  <ErrorMessage name="phone" component={InlineFormError} />
                </div>
              </div>
              <div className="row m-2">
                <div className="col-sm-12 col-md-2 row-title align-self-center">
                  <label htmlFor="address">
                    {requiredMark}
                    <Text field={fields.lblAddress} />
                  </label>
                </div>
                <div className="col-sm-12 col-md-4 align-self-center row-title">
                  <Field
                    name="address"
                    id="address"
                    type="text"
                    className={`form-control  ${
                      props.errors.address &&
                      props.touched.address &&
                      "is-invalid"
                    }`}
                  />
                  <ErrorMessage name="address" component={InlineFormError} />
                </div>
              </div>
              <div className="row m-2">
                <div className="col-sm-12 col-md-2 row-title align-self-center">
                  <label htmlFor="apartment">
                    <Text field={fields.lblApt} />
                  </label>
                </div>
                <div className="col-sm-12 col-md-4 align-self-center row-title">
                  <Field
                    name="apartment"
                    id="apartment"
                    type="text"
                    className={`form-control`}
                  />
                </div>
              </div>
              <div className="row m-2">
                <div className="col-sm-12 col-md-2 row-title align-self-center">
                  <label htmlFor="city">
                    {requiredMark}
                    <Text field={fields.lblCity} />
                  </label>
                </div>
                <div className="col-sm-12 col-md-4 align-self-center row-title">
                  <Field
                    name="city"
                    id="city"
                    type="text"
                    className={`form-control  ${
                      props.errors.city && props.touched.city && "is-invalid"
                    }`}
                  />
                  <ErrorMessage name="city" component={InlineFormError} />
                </div>
              </div>
              <div className="row m-2">
                <div className="col-sm-12 col-md-2 row-title align-self-center">
                  <label htmlFor="state">
                    {requiredMark}
                    <Text field={fields.lblState} />
                  </label>
                </div>
                <div className="col-sm-12 col-md-4 align-self-center row-title">
                  <Field
                    name="state"
                    id="state"
                    type="text"
                    disabled="true"
                    className={`form-control  ${
                      props.errors.state && props.touched.state && "is-invalid"
                    }`}
                  />
                  <ErrorMessage name="state" component={InlineFormError} />
                  {props.values.state === "" &&
                    MemberStateName &&
                    props.setFieldValue("state", MemberStateName)}
                </div>
              </div>
              <div className="row m-2">
                <div className="col-sm-12 col-md-2 row-title align-self-center">
                  <label htmlFor="zip">
                    {requiredMark}
                    <Text field={fields.lblZip} />
                  </label>
                </div>
                <div className="col-sm-12 col-md-4 align-self-center row-title">
                  <Field
                    name="zip"
                    id="zip"
                    type="text"
                    maxLength="10"
                    onKeyDown={ZipFormat(props)}
                    className={`form-control ${
                      props.errors.zip && props.touched.zip && "is-invalid"
                    }`}
                  />
                  <ErrorMessage name="zip" component={InlineFormError} />
                </div>
              </div>
              <div>
                <button
                  type="submit"
                  className="btn btn-primary m-sm-3 my-md-3 ml-md-3 w-md-35 w-sm-100"
                >
                  <Text field={fields.btnSubmit} />
                </button>
                <button
                  type="reset"
                  className="btn btn-primary m-sm-3 my-md-3 ml-md-3 w-md-35 w-sm-100"
                >
                  <Text field={fields.btnClear} />
                </button>
              </div>
            </Form>
          );
        }}
      </Formik>
      <div style={{ paddingTop: "3rem" }}>
        <RichText field={fields.notice} />
      </div>
    </div>
  );
};

export default RequestProviderDirectory;
