import React, { useContext, useState, useEffect } from "react";
import { Text, getFieldValue } from "@sitecore-jss/sitecore-jss-react";
import { ModalContext } from "../../contexts/modal.context";
import MFASelectionModal from "./MFASelection";
import EditEmail from "./EditEmail";
import UpdatePassword from "./UpdatePassword";
import RequestDeleteAccount from "./RequestDeleteAccount";
import {
  CHANNEL_ID_EMAIL,
  CHANNEL_ID_SMS,
  DELETE_SUCCESS_RESULT_CODE,
  ICON_PATH,
  SUCCESS_RESULT_CODE,
  SUPPORT_USER,
  TERMED_MEMBER,
  PINGUSERID_NOT_FOUND_RESULT_CODE,
  CHANNEL_ID_TOTP,
  CHANNEL_ID_FIDO,
} from "../../define.constants";
import { AppContext } from "../../contexts/app.context";
import {
  getAllUserDevices,
  deleteUseMFADevice,
  setDefaultMFADevice,
} from "../../services/AccountSettingsMfaAPI";
import { WhoIsAuthorized } from "../../common/WhoIsAuthorized";
import { ErrorContext } from "../../contexts/error.context";
import AddMFAWidget from "./AddMFAWidget";
import MFAAuthWidget from "./MFAAuthWidget";

const AccountSettingsMFA = (props) => {
  const { fields } = props;
  const { openModal, closeModal } = useContext(ModalContext);
  const { setError } = useContext(ErrorContext);
  const { userId, MemberStateName, isCABrightcareMember } =
    useContext(AppContext);
  const lang = getFieldValue(fields, "lang");
  const [currentOptions, setCurrentOptions] = useState([]);
  const [showAdd, setShowAdd] = useState(false);
  const allowedOptions = [
    getFieldValue(fields, "emailTitle"),
    getFieldValue(fields, "textTitle"),
    getFieldValue(fields, "appTitle"),
    getFieldValue(fields, "bioTitle"),
  ];

  useEffect(() => {
    getDevices();
  }, []);

  // Here we check if there are any other MFA options that have not been provided for
  // if there are additional MFA options that added to the account, we enable the
  // Add Method button
  useEffect(() => {
    const maxOptions = allowedOptions.length;
    let count = 0;
    allowedOptions.forEach((option) => {
      for (let i = 0; i < currentOptions.length; i++) {
        if (currentOptions[i].title === option) {
          count++;
          break;
        }
      }
    });
    setShowAdd(count < maxOptions);
  }, [currentOptions]);

  const getDevices = () => {
    getAllUserDevices({})
      .then((response) => {
        if (response.ResultCode === SUCCESS_RESULT_CODE) {
          const resultData =
            response.ResultData.ActiveMFADevices.ActiveDeviceList;
          const options = getOptions(resultData);
          setCurrentOptions(options);
        } else if (response.ResultCode === PINGUSERID_NOT_FOUND_RESULT_CODE) {
          setCurrentOptions([]);
        } else {
          setError({ err: response.Message });
        }
      })
      .catch((err) => {
        setError({ err });
      });
  };

  const getOptions = (data) => {
    let options = [];

    data.forEach((option, index) => {
      let title;
      let description;
      let subTitle;
      let insertFunc;
      let helperBox;
      switch (option.deviceAuthType) {
        case CHANNEL_ID_EMAIL.toUpperCase():
          title = getFieldValue(fields, "emailTitle");
          description = getFieldValue(fields, "emailDescription");
          subTitle = option.email;
          insertFunc = (obj) => options.unshift(obj);
          helperBox = getFieldValue(fields, "emailHelper");
          break;
        case CHANNEL_ID_SMS.toUpperCase():
          title = getFieldValue(fields, "textTitle");
          description = getFieldValue(fields, "textDescription");
          subTitle = option.phone;
          insertFunc = (obj) => options.push(obj);
          helperBox = getFieldValue(fields, "mobileHelper");
          break;
        case CHANNEL_ID_TOTP.toUpperCase():
          title = getFieldValue(fields, "appTitle");
          description = getFieldValue(fields, "appDescription");
          insertFunc = (obj) => options.push(obj);
          break;
        case CHANNEL_ID_FIDO.toUpperCase():
          title = getFieldValue(fields, "bioTitle");
          description = getFieldValue(fields, "bioDescription");
          insertFunc = (obj) => options.push(obj);
          break;
      }
      insertFunc({
        title: title,
        subTitle: subTitle,
        description: description,
        helperBox: helperBox,
        deviceID: option.deviceID,
        deviceAuthType: option.deviceAuthType,
        isDefault: index === 0 ? true : false,
      });
    });

    return options;
  };

  const onAdd = () => {
    openModal({
      content: (
        <MFASelectionModal
          fields={props?.fields}
          MFAOptions={currentOptions}
          setMFAOptions={setCurrentOptions}
        />
      ),
      version: 1,
    });
  };

  const onAddWidget = () => {
    openModal({
      content: (
        <AddMFAWidget userId={userId} lang={lang} onAddSuccess={getDevices} />
      ),
      version: 1,
    });
  };

  const handleUpdatePasswordClick = () => {
    openModal({
      content: (
        <MFAAuthWidget
          userId={userId}
          lang={lang}
          onAuthSuccess={updatePasswordModel}
        />
      ),
      version: 1,
    });
  };

  const deleteMemberRequest = () => {
    openModal({
      content: (
        <RequestDeleteAccount
          fields={fields}
          MemberStateName={MemberStateName}
          closeModal={closeModal}
        />
      ),
      version: 1,
    });
  };

  // What is MFA Modal
  const MFAInfo = () => {
    openModal({
      content: (
        <>
          <div className="text-center what-is-mfa MFAHeader">
            <Text field={fields.whatIsMFA} />
          </div>
          <div className="MFA-info">
            <Text field={fields.MFAInfo} />
          </div>
        </>
      ),
      verion: 1,
    });
  };

  const setDefault = (optionIndex, currentOptions) => {
    let deviceList = [];
    deviceList.push(currentOptions[optionIndex].deviceID);
    currentOptions.map((value, index) => {
      if (index !== optionIndex) {
        deviceList.push(value.deviceID);
      }
    });
    setDefaultMFADevice({
      DeviceIDList: deviceList,
    })
      .then((response) => {
        if (response.ResultCode === SUCCESS_RESULT_CODE) {
          //set default in UI
          const updatedOptions = [];

          currentOptions.forEach((option, index) => {
            const isDefault = index === optionIndex ? true : false;

            updatedOptions.push({
              title: option.title,
              subTitle: option.subTitle,
              description: option.description,
              helperBox: option.helperBox,
              deviceID: option.deviceID,
              deviceAuthType: option.deviceAuthType,
              isDefault: isDefault,
            });
          });

          setCurrentOptions(updatedOptions);
        } else {
          setError({ err: response.Message });
        }
      })
      .catch((err) => {
        setError({ err });
      });
  };

  const deleteOption = (optionIndex) => {
    let resetDefault = false;
    //remove MFA item
    deleteUseMFADevice({
      DeviceID: currentOptions[optionIndex].deviceID,
    })
      .then((response) => {
        if (response.ResultCode === DELETE_SUCCESS_RESULT_CODE) {
          //update MFA options in UI
          const updatedOptions = [];
          currentOptions.forEach((option, index) => {
            if (index != optionIndex) {
              updatedOptions.push({
                title: option.title,
                subTitle: option.subTitle,
                description: option.description,
                helperBox: option.helperBox,
                deviceID: option.deviceID,
                deviceAuthType: option.deviceAuthType,
                isDefault: option.isDefault,
              });
            } else if (option.isDefault) {
              updatedOptions[0].isDefault = true;
              resetDefault = true;
            }
          });

          if (resetDefault) {
            setDefault(0, updatedOptions);
          } else {
            setCurrentOptions(updatedOptions);
          }
        } else {
          console.log(response.Message);
          setError({ err: response.Message });
        }
      })
      .catch((err) => {
        setError({ err });
      });
  };

  const editEmail = () => {
    openModal({
      content: (
        <EditEmail
          fields={fields}
          userId={userId}
          email={currentOptions[0].subTitle}
          MFAOptions={currentOptions}
          getOptions={getOptions}
          setMFAOptions={setCurrentOptions}
          setDefault={setDefault}
          close={closeModal}
        />
      ),
      version: 1,
    });
  };

  // Display each active MFA Option
  const MFAOption = (
    title,
    subTitle,
    description,
    helper,
    isDefault,
    index
  ) => {
    return (
      <div className="MFAMethod" key={index}>
        <div className="MFASegment">
          <span className="MFALabel">{title}</span>
          <br />
          <span className="MFAItem">{subTitle}</span>
        </div>
        <div className="MFASegment MFALabel" id="lblText">
          {description}
          {helper && (
            <div className="font-weight-500 custom-tooltip-spending pl-3">
              <p className="custom-tooltip-spending-text tooltipBox">
                {helper}
              </p>
              <img
                style={{ marginBottom: "2px" }}
                src={`${ICON_PATH}/info-24px.svg`}
                alt={getFieldValue(fields, "infoIcon")}
              />
            </div>
          )}
        </div>
        <div className="MFASegment MFAItem" id="lblTextValue">
          {isDefault && <Text field={fields?.default} />}
        </div>
        <div className="MFASegment" id="lblTextValue">
          {currentOptions[index].deviceAuthType ===
          CHANNEL_ID_EMAIL.toUpperCase() ? (
            <WhoIsAuthorized
              authorize={{
                [SUPPORT_USER]: false,
                [TERMED_MEMBER]: true,
              }}
              type="link"
              handleClick={editEmail}
            >
              {({ handleClick, disabledClass }) => {
                return (
                  <a className={`mfa-link disabledClass`} onClick={handleClick}>
                    <Text field={fields.edit} />
                  </a>
                );
              }}
            </WhoIsAuthorized>
          ) : (
            <WhoIsAuthorized
              authorize={{
                [SUPPORT_USER]: false,
                [TERMED_MEMBER]: true,
              }}
              type="link"
              handleClick={() => deleteOption(index)}
            >
              {({ handleClick, disabledClass }) => {
                return (
                  <a className={`mfa-link disabledClass`} onClick={handleClick}>
                    <Text field={fields.delete} />
                  </a>
                );
              }}
            </WhoIsAuthorized>
          )}
          <br />
          <br />
          <WhoIsAuthorized
            authorize={{
              [SUPPORT_USER]: false,
              [TERMED_MEMBER]: true,
            }}
            type="link"
            handleClick={() => setDefault(index, currentOptions)}
          >
            {({ handleClick, disabledClass }) => {
              return (
                <a
                  hidden={isDefault}
                  className={`mfa-link disabledClass`}
                  onClick={handleClick}
                >
                  <Text field={fields.setDefault} />
                </a>
              );
            }}
          </WhoIsAuthorized>
        </div>
      </div>
    );
  };

  // Update Password Model
  const updatePasswordModel = () => {
    closeModal();
    openModal({
      content: (
        <UpdatePassword fields={fields} email={currentOptions[0].subTitle} />
      ),
      version: 1,
    });
  };

  return (
    <div className="mfa-frame-top-padding">
      {/* MFA Card Start */}
      <div className="card mfa-card-padding">
        <div>
          <h5 className="tab-title title2 col-12 text-center">
            {isCABrightcareMember ? (
              <Text field={fields.headerCHP} />
            ) : (
              <Text field={fields.header} />
            )}
          </h5>
          <hr className="mfa-style-line" />
        </div>
        <div className="optionsContainer">
          <div className="optionsContainer2">
            <button
              type="button"
              className="btn btn-primary float-right"
              onClick={MFAInfo}
            >
              <Text field={fields.whatIsMFA} />
            </button>
          </div>
          <div className="mfa-description MFA-info">
            {isCABrightcareMember ? (
              <Text field={fields.MFAOptionsDescriptionCHP} />
            ) : (
              <Text field={fields.MFAOptionsDescription} />
            )}
          </div>
          <div className="mfa-description MFA-info">
            <Text field={fields.MFAOptionsDescription2} />
          </div>
          {currentOptions.map((option, index) => {
            if (
              option.deviceAuthType === CHANNEL_ID_EMAIL.toUpperCase() ||
              option.deviceAuthType === CHANNEL_ID_SMS.toUpperCase() ||
              option.deviceAuthType === CHANNEL_ID_TOTP.toUpperCase() ||
              option.deviceAuthType === CHANNEL_ID_FIDO.toUpperCase()
            ) {
              return MFAOption(
                option.title,
                option.subTitle,
                option.description,
                option.helperBox,
                option.isDefault,
                index
              );
            }
          })}
        </div>
        {/* Widget Start */}
        {showAdd && (
          <div className="text-center one-rem-top-margin">
            <WhoIsAuthorized
              authorize={{
                [SUPPORT_USER]: false,
                [TERMED_MEMBER]: true,
              }}
              handleClick={onAddWidget}
            >
              {({ handleClick, disabledClass }) => {
                return (
                  <button
                    type="button"
                    className={`btn btn-primary ${disabledClass}`}
                    onClick={handleClick}
                  >
                    <Text field={fields?.addMethodBtn} />
                  </button>
                );
              }}
            </WhoIsAuthorized>
          </div>
        )}
        {/* Widget End */}
      </div>
      {/* Password card start */}
      <div id="updatePasswordContainer" className="card mfa-card-padding">
        <div className="MFALabel">
          <Text field={fields.updatePassword} />
        </div>
        <div className="MFAItem center-padding">
          <Text field={fields.updatePasswordInst} />
        </div>
        <div>
          <WhoIsAuthorized
            authorize={{
              [SUPPORT_USER]: false,
              [TERMED_MEMBER]: true,
            }}
            handleClick={handleUpdatePasswordClick}
          >
            {({ handleClick, disabledClass }) => {
              return (
                <button
                  type="button"
                  className="btn btn-primary float-right"
                  onClick={handleClick}
                >
                  <Text field={fields.updateBtn} />
                </button>
              );
            }}
          </WhoIsAuthorized>
        </div>
      </div>

      <div id="updatePasswordContainer" className="card mfa-card-padding">
        <div className="MFALabel">
          <Text field={fields.requestDeleteMember} />
        </div>
        <div className="MFAItem center-padding">
          {isCABrightcareMember ? (
            <Text field={fields.requestDeleteMemberDescriptionCHP} />
          ) : (
            <Text field={fields.requestDeleteMemberDescription} />
          )}
        </div>
        <div>
          <WhoIsAuthorized
            authorize={{
              [SUPPORT_USER]: false,
              [TERMED_MEMBER]: true,
            }}
            handleClick={deleteMemberRequest}
          >
            {({ handleClick, disabledClass }) => {
              return (
                <button
                  type="button"
                  className="btn btn-primary float-right"
                  onClick={handleClick}
                >
                  <Text field={fields.deleteAccountBtn} />
                </button>
              );
            }}
          </WhoIsAuthorized>
        </div>
      </div>
    </div>
  );
};

export default AccountSettingsMFA;
