import {
  getFieldValue,
  Text,
  RichText,
} from "@sitecore-jss/sitecore-jss-react";
import { Formik, Field, Form, ErrorMessage } from "formik";
const CancelConfirmationDialog = ({
  message,
  onDialog,
  fields,
  caBrightcareMember,
}) => {
  return (
    <div
      style={{
        position: "fixed",
        top: "0",
        left: "0",
        right: "0",
        bottom: "0",
        backgroundColor: "rgba(0,0,0,0.5)",
      }}
      onClick={() => onDialog(false)}
    >
      <div
        className="confirmation-popup"
        onClick={(e) => e.stopPropagation()}
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          position: "absolute",
          margin: "auto",
          border: "2px solid black",
          width: "600px",
          height: "200px",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          background: "white",
          padding: "20px",
          borderRadius: "10px",
        }}
      >
        <div className="confirmationpopupheader" style={{ marginTop: "-35px" }}>
          <h5 style={{ color: "#111", fontSize: "25px" }}>{message}</h5>
        </div>

        <div
          style={{
            display: "flex",
            alignItems: "enter",
            padding: "15px 50px 30px ",
            marginBottom: "-35px",
          }}
        >
          <button
            type="reset"
            onClick={() => onDialog(false)}
            style={{
              background: "gray",
              color: "white",
              padding: "10px",
              fontSize: "30px",
              marginRight: "100px",
              border: "none",
              borderRadius: "4px",
              cursor: "pointer",
            }}
          >
            <Text field={fields.lblNo} />
          </button>
          <button
            type="reset"
            onClick={() => onDialog(true)}
            style={{
              background: caBrightcareMember ? "#005d63" : " #ff4500",
              color: "white",
              fontSize: "30px",
              padding: "10px",
              borderRadius: "4px",
              marginRight: "50px",
              border: "none",
              cursor: "pointer",
            }}
          >
            <Text field={fields.lblYes} />
          </button>
        </div>
      </div>
    </div>
  );
};
export default CancelConfirmationDialog;
