import React, { useContext, useState, useEffect } from "react";
import {
  Text,
  RichText,
  Link,
  getFieldValue,
} from "@sitecore-jss/sitecore-jss-react";
import { AppContext } from "../../contexts/app.context";
import { MyResourcesContext } from "../../contexts/myResources.context";
import {
  ICON_PATH,
  SAPPHIRE_SSO,
  CAREMARK_SSO,
  SPANISH_LANG_CODE,
  ENGLISH_LANG_CODE,
  SPANISH_LANG_TEXT,
  SUPPORT_USER,
  TERMED_MEMBER,
  SWITCHED_TO_DEPENDENT,
  HELP_FULLINK_ID,
  MEDICAID,
  NM,
  NM_MCAID_PROGID,
  GLOBAL_VARIABLE,
} from "../../define.constants";
import {
  getStateConfiguration,
  getHelpfulLink,
  isAccessibleToSupportUser,
  isAccessibleToTermedMember,
  isAccessibleToSwitchedDependent,
  getStateLobConfigurationItems,
} from "./../../utils/helpers";
import { SSORedirectContext } from "../../contexts/SSORedirect.context";
import { createLinkIcon } from "../MyResources-FormDocs/Helper";
import { useGetDDLYearsAPI } from "../../hooks/useGetDDLYearsAPI";
import * as CommonAPI from "../../services/Common";
import { ErrorContext } from "../../contexts/error.context";
import { WhoIsAuthorized } from "../../common/WhoIsAuthorized";
import { createLinkIconNMPHelpfulLink } from "../MyResources-FormDocs/NonMarketplaceHelper";
const MyResourcesHelpfulLinks = (props) => {
  let {
    MemberStateName,
    memberLOB,
    programId,
    planId,
    isNYAffinityMember,
    isTermedMember,
    isCABrightcareMember,
    enrollmentEffDate,
  } = useContext(AppContext);
  const helpfulLinkIds = HELP_FULLINK_ID;
  const { distItem, listRecords } = useContext(MyResourcesContext);
  const { setError } = useContext(ErrorContext);
  const { redirectToSSO } = useContext(SSORedirectContext);

  const [recordList, setRecordList] = useState(null);
  // const { listRecords } = useGetDDLYearsAPI();

  GLOBAL_VARIABLE.genesysIntent = getFieldValue(props?.fields, "intent");

  useEffect(() => {
    setRecordList(listRecords);
  }, [listRecords]);

  const getApigeeSSOurl = (SSOurl) => {
    switch (SSOurl) {
      case SAPPHIRE_SSO:
        return "SapphireChangePCPSSO";
      case CAREMARK_SSO:
        return "GetCVSCaremarkSSORedirect";
      default:
        return null;
    }
  };

  const getSSOLink = (e, item) => {
    redirectToSSO({
      e,
      url: getApigeeSSOurl(item.SSOurl?.value),
    });
  };

  const list = getStateLobConfigurationItems(
    props.fields,
    MemberStateName,
    memberLOB,
    programId
  );

  // Flag to show message for termed members instead of HelpfulLinks
  let isSpcialTermedMember = false;

  // MEDICAID Check- NM
  const isMedicaidMember = memberLOB?.toLowerCase() === MEDICAID;
  isSpcialTermedMember =
    isMedicaidMember &&
    MemberStateName?.toUpperCase() === NM &&
    programId === NM_MCAID_PROGID &&
    isTermedMember;

  const displayCards = (classname, item, index) => {
    // Helpfullink Section Label Sort by Alphabetically
    //{
    //   list.sort((a, b) =>
    //     a["fields"]["helpfullinkTitle"]["value"].localeCompare(
    //       b["fields"]["helpfullinkTitle"]["value"]
    //     )
    //   );
    // }

    return (
      <li className={`list-group-item cards-md-tab2 ${classname}`} key={index}>
        <div className="row">
          <div className="col-xxl-2 col-md-3 list-title card-titles">
            <Text field={item.fields.helpfullinkTitle} />
          </div>
          <div className="col-xxl-9 col-md-8 list-desc2">
            <div className="card-desc content-md-tab2">
              <RichText field={item.fields.helpfullinkDescription} />
            </div>
          </div>
          {item.fields.SSOurl?.value ? (
            <WhoIsAuthorized
              type="link"
              href="#"
              authorize={{
                [SUPPORT_USER]: isAccessibleToSupportUser(
                  getApigeeSSOurl(item.fields.SSOurl?.value)
                ),
                [TERMED_MEMBER]: isAccessibleToTermedMember(
                  getApigeeSSOurl(item.fields.SSOurl?.value)
                ),
                [SWITCHED_TO_DEPENDENT]: isAccessibleToSwitchedDependent(
                  getApigeeSSOurl(item.fields.SSOurl?.value)
                ),
              }}
              handleClick={(e, item) =>
                redirectToSSO({
                  e,
                  url: constants.apigeeURLs[
                    getApigeeSSOurl(item.SSOurl?.value)
                  ],
                })
              }
            >
              {({ handleClick, disabledClass, href }) => {
                return (
                  <div
                    className={`list-multi-link-drp ${disabledClass}`.trim()}
                  >
                    <a
                      className="list-icon-newtab"
                      onClick={(e) => handleClick(e, item.fields)}
                      cls_vname={
                        MemberStateName +
                        "_" +
                        memberLOB +
                        "_" +
                        item?.displayName.replace(/\s+/g, "_")
                      }
                    >
                      <img
                        src={
                          isCABrightcareMember
                            ? `${ICON_PATH}/CHP_open_in_new-24px.svg`
                            : `${ICON_PATH}/open_in_new-24px.svg`
                        }
                        className="redirect-icon"
                        alt={getFieldValue(item.fields, "helpfullinkLink")}
                        id={index}
                      />
                    </a>
                  </div>
                );
              }}
            </WhoIsAuthorized>
          ) : (
            <div className="list-multi-link-drp">
              {
                item.fields.helpfullinkYearList &&
                item.fields.helpfullinkYearList != null &&
                item.fields.helpfullinkYearList != undefined ? (
                  <>
                    {recordList !== null &&
                    recordList?.length > 0 &&
                    item.fields.helpfullinkYearList?.length > 1 ? (
                      <>
                        {recordList?.length > 1 ? (
                          <>
                            <a
                              className="list-icon-newtab"
                              href="#"
                              id={index}
                              data-mdb-toggle="dropdown"
                              cls_vname={
                                MemberStateName +
                                "_" +
                                memberLOB +
                                "_" +
                                item?.displayName.replace(/\s+/g, "_")
                              }
                            >
                              <img
                                src={
                                  isCABrightcareMember
                                    ? `${ICON_PATH}/CHP_open_in_new-24px.svg`
                                    : `${ICON_PATH}/open_in_new-24px.svg`
                                }
                                className="redirect-icon"
                                alt={getFieldValue(
                                  item.fields,
                                  "helpfullinkLink"
                                )}
                                id={`downloadIcon${index}`}
                              />
                            </a>

                            <div className="dropdown-menu dropdown-menu-right dropdown-default">
                              {recordList?.map((listItem, index) => {
                                return createLinkIcon({
                                  link: getHelpfulLink({
                                    year: listItem.Year.toString(),
                                    yearsList: item.fields.helpfullinkYearList,
                                    comingSoonYear:
                                      distItem?.phrases.comingSoonYear?.toString(),
                                    comingSoonYearUrl:
                                      distItem?.phrases.comingSoonYearUrl,
                                  }),
                                  index,
                                  language: listItem.Year.toString(),
                                  className: "dropdown-item",
                                  multiLanguage: true,
                                  imageName: isCABrightcareMember
                                    ? "CHP_open_in_new-24px.svg"
                                    : "open_in_new-24px.svg",
                                  cls_vname:
                                    MemberStateName +
                                    "_" +
                                    memberLOB +
                                    "_" +
                                    item?.displayName.replace(/\s+/g, "_"),
                                });
                              })}
                            </div>
                          </>
                        ) : (
                          createLinkIcon({
                            link: getHelpfulLink({
                              year: recordList[0].Year.toString(),
                              yearsList: item.fields.helpfullinkYearList,
                              comingSoonYear:
                                distItem?.phrases.comingSoonYear?.toString(),
                              comingSoonYearUrl:
                                distItem?.phrases.comingSoonYearUrl,
                            }),
                            index,
                            language: recordList[0].Year.toString(),
                            className: "list-icon-newtab",
                            multiLanguage: false,
                            imageName: isCABrightcareMember
                              ? "CHP_open_in_new-24px.svg"
                              : "open_in_new-24px.svg",
                            cls_vname:
                              MemberStateName +
                              "_" +
                              memberLOB +
                              "_" +
                              item?.displayName.replace(/\s+/g, "_"),
                          })
                        )}
                      </>
                    ) : item.fields.docName?.value && isCABrightcareMember ? (
                      createLinkIconNMPHelpfulLink({
                        link: item.fields.href,
                        index,
                        language: "",
                        className: "list-icon-newtab",
                        multiLanguage: false,
                        imageName: isCABrightcareMember
                          ? "CHP_open_in_new-24px.svg"
                          : "open_in_new-24px.svg",
                        tabName: "",
                        distItem,
                        MemberStateName,
                        memberLOB,
                        name: item.fields.docName?.value,
                        planId,
                        programId,
                        selectedYear: new Date(enrollmentEffDate).getFullYear(),
                        isYearSpecific: "",
                        byPassAccessControl: true,
                        cls_vname:
                          MemberStateName +
                          "_" +
                          memberLOB +
                          "_" +
                          item?.displayName.replace(/\s+/g, "_"),
                        isNYAffinityMember,
                        isCABrightcareMember: isCABrightcareMember,
                      })
                    ) : item.fields.docName?.value ? (
                      createLinkIconNMPHelpfulLink({
                        link: item.fields.href,
                        index,
                        language: "",
                        className: "list-icon-newtab",
                        multiLanguage: false,
                        imageName: isCABrightcareMember
                          ? "CHP_open_in_new-24px.svg"
                          : "open_in_new-24px.svg",
                        tabName: "",
                        distItem,
                        MemberStateName,
                        memberLOB,
                        name: item.fields.docName?.value,
                        planId,
                        programId,
                        selectedYear: new Date().getFullYear(),
                        isYearSpecific: "",
                        byPassAccessControl: true,
                        cls_vname:
                          MemberStateName +
                          "_" +
                          memberLOB +
                          "_" +
                          item?.displayName.replace(/\s+/g, "_"),
                        isNYAffinityMember,
                        isCABrightcareMember: false,
                      })
                    ) : (
                      createLinkIcon({
                        link: getHelpfulLink({
                          yearsList: item.fields.helpfullinkYearList,
                        }),
                        index,
                        language: "Common",
                        className: "list-icon-newtab",
                        multiLanguage: false,
                        imageName: isCABrightcareMember
                          ? "CHP_open_in_new-24px.svg"
                          : "open_in_new-24px.svg",
                        cls_vname:
                          MemberStateName +
                          "_" +
                          memberLOB +
                          "_" +
                          item?.displayName.replace(/\s+/g, "_"),
                      })
                    )}
                  </>
                ) : null
                //  (
                //   <Link
                //     field={item.fields.helpfullinkLink}
                //     className="list-icon-newtab"
                //   >
                //     <img
                //       src={`${ICON_PATH}/open_in_new-24px.svg`}
                //       className="redirect-icon"
                //       alt={getFieldValue(item.fields, "helpfullinkLink")}
                //       id={index}
                //     />
                //   </Link>
                // )
              }
            </div>
          )}
        </div>
      </li>
    );
  };

  return (
    <div
      className="tab-pane fade show active my-resources-page"
      id="helpfulLinks"
      role="tabpanel"
      aria-labelledby="tab-2"
    >
      <div className="row no-tab-title cards-section-tab2">
        <div className="col-md-12">
          {isSpcialTermedMember ? (
            <Text field={props?.fields?.TermedMemberMessage} />
          ) : (
            <ul className="list-group">
              {Array.isArray(list) && list.length && (
                <>
                  {list?.map((item, index) => {
                    let linkIds = [];
                    helpfulLinkIds.forEach((link) => {
                      if (link.id === getFieldValue(item.fields, "id")) {
                        linkIds = link;
                      }
                    });
                    if (
                      linkIds &&
                      linkIds.programIds &&
                      linkIds.programIds.length > 0
                    ) {
                      if (linkIds.programIds.includes(programId)) {
                        if (
                          linkIds.planIds &&
                          linkIds.planIds.length > 0 &&
                          !linkIds.planIds.includes(planId)
                        ) {
                          return;
                        }
                      } else {
                        return;
                      }
                    }
                    return displayCards("cards-tab2", item, index);
                  })}
                </>
              )}
            </ul>
          )}
        </div>
      </div>
    </div>
  );
};

export default MyResourcesHelpfulLinks;
