import React, { useContext } from "react";

import { Text, Placeholder } from "@sitecore-jss/sitecore-jss-react";
import { AppContext } from "../../contexts/app.context";
import {
  DEPENDANT_UNDER18,
  DEPENDANT_18AndOVER,
  SUBSCRIBER,
  MEDICARE,
  MARKETPLACE,
  DUALS,
  MEDICAID,
  NE,
} from "../../define.constants";
import { isNYMDCHP } from "./../../utils/helpers";
import { SSORedirectProvider } from "../../contexts/SSORedirect.context";
const MyWellnessLayout = (props) => {
  let {
    isDependentLoggedIn,
    isSwitchedView,
    DependentCategory,
    loggedInUserDependentCat,
    switchedUserType,
    memberLOB,
    MemberStateName,
    programId,
  } = useContext(AppContext);
  const { rendering, fields } = props;
  const hiddenTabs = [];
  if (
    isDependentLoggedIn && //Conditions for 1490
    isSwitchedView &&
    switchedUserType === SUBSCRIBER &&
    loggedInUserDependentCat === DEPENDANT_UNDER18 //1124 mmpi
  ) {
    hiddenTabs.push("MyWellness-Assessments");
  }

  if (
    memberLOB?.toLowerCase() === MEDICARE ||
    memberLOB?.toLowerCase() === DUALS
  )
    hiddenTabs.push("MyWellness-MyHealthPerks");
  else hiddenTabs.push("MyWellness-MyHealthyRewards");
  if (isNYMDCHP(memberLOB, MemberStateName, programId)) {
    hiddenTabs.push("MyWellness-MyHealthPerks");
  }
  if (MemberStateName != NE) {
    hiddenTabs.push("MyWellness-ValueAddedBenefits");
  }
  if (MemberStateName == NE && memberLOB?.toLowerCase() === MEDICARE) {
    hiddenTabs.push("MyWellness-ValueAddedBenefits");
  }
  if (
    memberLOB?.toLowerCase() === MARKETPLACE ||
    //memberLOB?.toLowerCase() === DUALS ||
    memberLOB?.toLowerCase() === MEDICAID
  ) {
    hiddenTabs.push("MyWellness-MentalHealth");
  }

  return (
    <SSORedirectProvider>
      <div className="wellness-page">
        <div className="row">
          <div className="col-12">
            <div className="page-heading">
              <Text field={fields?.heading ?? ""} />
            </div>
          </div>
        </div>
        <Placeholder
          name="jss-mywellness-layout"
          rendering={rendering}
          hiddenTabs={hiddenTabs}
        />
      </div>
    </SSORedirectProvider>
  );
};
export default MyWellnessLayout;
