import Axios from "./axios/provider";
export const ONeilAuditLogIDcard = async (
  USER_ID,
  RKSMEMBER_ID,
  FUNCTIONALITY,
  STATECODE,
  ProgramID,
  LOB,
  CREATE_DATE,
  Request,
  Response,
  API,
  IPAddress,
  CorrelationID,
  LoggedInMemId,
  LoggedInMemStateCode,
  MemberNo
) => {
  try {
    const response = await Axios.post(
      constants.apigeeURLs.ONeilAuditLogIDcard,
      {
        USER_ID,
        RKSMEMBER_ID,
        FUNCTIONALITY,
        STATECODE,
        ProgramID,
        LOB,
        CREATE_DATE,
        Request,
        Response,
        API,
        IPAddress,
        CorrelationID,
        LoggedInMemId,
        LoggedInMemStateCode,
        MemberNo,
      }
    );
    return response;
  } catch (error) {
    throw error;
  }
};
