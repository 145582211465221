import React, { useContext, useEffect, useState } from "react";
import AccountSettingsPersonalConfirmPopUp from "../AccountSettings-Personal-ConfirmPopUp";
import { Text, getFieldValue } from "@sitecore-jss/sitecore-jss-react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { SSORedirectProvider } from "../../contexts/SSORedirect.context";
import { AppContext } from "../../contexts/app.context";
import { SpinnerContext } from "../../contexts/spinner.context";
import { ModalContext } from "../../contexts/modal.context";
import { ACCOUNT_SETTINGS_PAYER } from "../../define.widgets";
import * as PayerRequestToSFAPI from "../../services/PayerRequestAPI";
import { PHONE_DIGIT_ONLY_REGEX, MARKETPLACE } from "../../define.constants";
import { formaData, apiResponseTemplate } from "./formatAPISubmitData";
import { InlineFormError } from "../../common/InlineFormError";
import {
  PRIMARY_SUBSCRIBER_ROLEID,
  GLOBAL_VARIABLE,
} from "../../define.constants";
import { getConfigByKey } from "../../utils/helpers";
import { UNAUTHORIZED } from "../../define.constants";
import { SitecoreContextReactContext } from "@sitecore-jss/sitecore-jss-react";

function formatPhoneNumber(value) {
  if (!value) return value;
  const phoneNumber = value?.replace(PHONE_DIGIT_ONLY_REGEX, "");
  const phoneNumberLength = phoneNumber?.length;
  if (phoneNumberLength < 4) return phoneNumber;
  else if (phoneNumberLength < 7) {
    return `(${phoneNumber?.slice(0, 3)}) ${phoneNumber?.slice(3)}`;
  }
  return `(${phoneNumber?.slice(0, 3)}) ${phoneNumber?.slice(
    3,
    6
  )}-${phoneNumber?.slice(6, 10)}`;
}

function onFocusFormatPhoneNumber(value) {
  const phoneNumber = value?.replace(PHONE_DIGIT_ONLY_REGEX, "") + "";
  return phoneNumber;
}

const dependentInitialValues = {
  selectDependent: "",
  relationshipWithDependent: "",
  dependentFirstName: "",
  dependentLastName: "",
  dependentMemberId: "",
  dependentGender: "",
  dependentDateOfBirth: "",
  dependentAddress: "",
  dependentCity: "",
  dependentZip: "",
  dependentState: "",
  dependentEmail: "",
};

const healthPlanInitialValues = {
  healthPlanName: "",
  memberHealthPlan: "",
  healthPlanContactFirstName: "",
  healthPlanContactLastName: "",
  healthPlanPhoneNum: "",
  healthPlanEmailAddress: "",
  healthPlanExtn: "",
};

const decalrationInitialValues = {
  declaration1: false,
  declaration2: false,
  declaration3: false,
  declaration4: false,
};

const formatToday = () => {
  let date = new Date();
  return `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`;
};

function PersonalInformation(props) {
  const contextValue = useContext(AppContext);
  let {
    profileList,
    isDependentLoggedIn,
    Dependents,
    DependentDetails,
    memberRKSID,
    userId,
    memberLOB,
    roleId,
    programId,
    isSupportUser,
    supportUserId,
    isTermedMember,
    isCABrightcareMember,
  } = contextValue;
  const isMarketplaceMember =
    memberLOB.toLowerCase() === MARKETPLACE.toLowerCase();

  GLOBAL_VARIABLE.genesysIntent = getFieldValue(props?.fields, "intent");

  const { innerSpinnerHTML, setSpinner, isLoading } =
    useContext(SpinnerContext);
  const { openModal, closeModal } = useContext(ModalContext);
  const [dependentSelected, setDependentSelected] = useState(false);
  const [apiStatus, setApiStatus] = useState(false);
  const {
    context: {
      route: { placeholders },
    },
  } = useContext(SitecoreContextReactContext);
  //console.log("Rendering PayerForm for ", memberRKSID, isSupportUser);

  const accountDetailMyProfile = props?.fields?.MyProfileList[0] ?? [];

  const DependentDetailsU = Array.isArray(DependentDetails)
    ? DependentDetails
    : [];

  //console.log("dependentU detailsU = ", JSON.stringify(DependentDetailsU));

  let validationSchema = Yup.object().shape({
    healthPlanName: Yup.string()
      .required(accountDetailMyProfile?.fields?.healthPlanNameValMsg?.value)
      .trim(),
    healthPlanPhoneNum: Yup.string()
      .required(accountDetailMyProfile?.fields?.healthPlanPhoneNumValMsg?.value)
      .test(
        "len",
        accountDetailMyProfile?.fields?.minPhoneNumError?.value,
        (val) => val?.length === 14 || val?.length === 10
      ),
    healthPlanEmailAddress: Yup.string().email(
      accountDetailMyProfile?.fields?.healthPlanEmailValMsg?.value
    ),
    phoneNumber: Yup.string()
      .required(accountDetailMyProfile?.fields?.phoneNumValMsg?.value)
      .test(
        "len",
        accountDetailMyProfile?.fields?.minPhoneNumError?.value,
        (val) => val?.length === 14 || val?.length === 10
      ),
    altPhoneNum: Yup.string().when("sameNum", {
      is: false,
      then: Yup.string()
        .required(accountDetailMyProfile?.fields?.altPhoneNumValMsg?.value)
        .test(
          "len",
          accountDetailMyProfile?.fields?.minPhoneNumError?.value,
          (val) => val?.length === 14 || val?.length === 10
        ),
    }),
    selectDependent: Yup.string().when(["request"], {
      is: isMarketplaceMember ? "dependant" : "",
      then: Yup.string().required(
        accountDetailMyProfile?.fields?.dependentSelectValMsg?.value
      ),
      otherwise: Yup.string(),
    }),

    // dependentAddress: Yup.string().when("request", {
    // is: "dependant",
    //   then: Yup.string().required(
    //     accountDetailMyProfile?.fields?.dependentAddressValMsg?.value
    //   ),
    // }),
    dependentCity: Yup.string().when("request", {
      is: "dependant",
      then: Yup.string()
        .required(accountDetailMyProfile?.fields?.dependentCityValMsg?.value)
        .trim(),
    }),
    dependentZip: Yup.string().when("request", {
      is: "dependant",
      then: Yup.string().required(
        accountDetailMyProfile?.fields?.dependentZipValMsg?.value
      ),
    }),
    dependentPhoneNumber: Yup.string().when("request", {
      is: "dependant",
      then: Yup.string()
        .required(
          accountDetailMyProfile?.fields?.dependentPhoneNumValMsg?.value
        )
        .test(
          "len",
          accountDetailMyProfile?.fields?.minPhoneNumError?.value,
          (val) => val?.length === 14 || val?.length === 10
        ),
    }),
    dependentAltPhoneNum: Yup.string().when("request", {
      is: "dependant",
      then: Yup.string()
        .required(
          accountDetailMyProfile?.fields?.dependentAltPhoneNumValMsg?.value
        )
        .test(
          "len",
          accountDetailMyProfile?.fields?.minPhoneNumError?.value,
          (val) => val?.length === 14 || val?.length === 10
        ),
    }),
    dependentEmail: Yup.string().when("request", {
      is: "dependant",
      then: Yup.string()
        // .required(
        //   accountDetailMyProfile?.fields?.dependentEmailReqValMsg?.value
        // )
        .email(accountDetailMyProfile?.fields?.dependentEmailValMsg?.value),
    }),
    dependentFirstName: Yup.string().when("request", {
      is: "dependant",
      then: Yup.string()
        .required(
          accountDetailMyProfile?.fields?.dependentFirstNameValMsg?.value
        )
        .trim(),
    }),
    dependentLastName: Yup.string().when("request", {
      is: "dependant",
      then: Yup.string()
        .required(
          accountDetailMyProfile?.fields?.dependentLastNameValMsg?.value
        )
        .trim(),
    }),
    dependentMemberId: Yup.string().when("request", {
      is: "dependant",
      then: Yup.string()
        .required(
          accountDetailMyProfile?.fields?.dependentMemberIdValMsg?.value
        )
        .trim(),
    }),
    dependentDateOfBirth: Yup.string().when("request", {
      is: "dependant",
      then: Yup.string()
        .required(accountDetailMyProfile?.fields?.dependentDOBValMsg?.value)
        .trim(),
    }),
    dependentState: Yup.string().when("request", {
      is: "dependant",
      then: Yup.string()
        .required(accountDetailMyProfile?.fields?.dependentStateValMsg?.value)
        .trim(),
    }),
  });

  const initialValues = {
    request: "member",
    phoneNumber: formatPhoneNumber(profileList.HomePhone),
    altPhoneNum: formatPhoneNumber(profileList.HomePhone),
    QNXTMemberID: memberRKSID,
    DependentQNXTMemberID: null,
    sameNum: true,
    dependentPhoneNumber: "",
    dependentAltPhoneNum: "",
    isDependentNumSame: false,
    ...dependentInitialValues,
    ...healthPlanInitialValues,
    ...decalrationInitialValues,
  };
  const validationMessage =
    accountDetailMyProfile?.fields?.validationMessage?.value;

  const onSubmit = async (submitProps, actions) => {
    console.log("actions =", actions, submitProps);
    if (isSupportUser) {
      openModal({
        header: <></>,
        content: (
          <>{getConfigByKey(UNAUTHORIZED, placeholders?.["jss-config"])}</>
        ),
        footer: <></>,
        version: 1,
      });
    } else {
      postApiData(submitProps, profileList, actions);
      actions.setSubmitting(false);
    }
  };
  const [hiddenDisclaimer, setHiddenDisclaimer] = useState(false);

  const onNumberInputChangeHandler = (
    event,
    inputName,
    formikProps,
    depInput
  ) => {
    const uptoTendDigitNum = /^[0-9]{1,10}$/;
    const formatedNumber = onFocusFormatPhoneNumber(event.target.value);
    if (uptoTendDigitNum.test(formatedNumber) || formatedNumber == "") {
      formikProps.setFieldValue(inputName, formatedNumber);
      depInput && formikProps.setFieldValue(depInput, formatedNumber);
    }
  };

  const NumberOnly = (event, inputName, formikProps) => {
    const numberOnly = /^[0-9]{1,10}$/;
    const value = event.target.value;
    if (numberOnly.test(value) || value == "") {
      formikProps.setFieldValue(inputName, value);
    }
  };

  const CharOnly = (event, inputName, formikProps, length = 2) => {
    const charOnly = /^[a-zA-Z]+$/;
    const value = event.target.value;
    if ((charOnly.test(value) && value.length <= 2) || value == "") {
      formikProps.setFieldValue(inputName, value.toUpperCase());
    }
  };

  const postApiData = (formikValues, profileList, actions) => {
    const userDetails = {
      userId,
      memberLOB,
      roleId,
      programId,
      supportUserId,
    };
    const profileListU = { ...userDetails, ...profileList };
    setSpinner(true);
    PayerRequestToSFAPI.PayerRequestToSF(
      formaData(formikValues, profileListU, formatToday, memberRKSID)
    )
      .then((response) => {
        setSpinner(false);
        setApiStatus(true);
        console.log(response);
        const message = response?.Message;
        console.log({ message });
        const successMsgTitle =
          accountDetailMyProfile?.fields?.successApiTitle?.value;
        const ErrorMsgTitle =
          accountDetailMyProfile?.fields?.errorApiTitle?.value;
        const successMsg = successMsgTitle ? successMsgTitle : "Success";
        const errorMsg = ErrorMsgTitle ? ErrorMsgTitle : "Error";
        const ApiStatus = response?.ResultCode === 400 ? errorMsg : successMsg;
        const content = apiResponseTemplate(ApiStatus, message);
        if (response?.ResultCode != 200) {
          actions.resetForm({
            values: {
              healthPlanName: "",
              memberHealthPlan: "",
              healthPlanContactFirstName: "",
              healthPlanContactLastName: "",
              healthPlanPhoneNum: "",
              healthPlanEmailAddress: "",
              healthPlanExtn: "",
            },
          });
        }
        openModal({
          content,
          version: 1,
        });
      })
      .catch((err) => {
        console.log(err);
        setSpinner(false);
        setApiStatus(err);
        actions.resetForm({
          values: {
            healthPlanName: "",
            memberHealthPlan: "",
            healthPlanContactFirstName: "",
            healthPlanContactLastName: "",
            healthPlanPhoneNum: "",
            healthPlanEmailAddress: "",
            healthPlanExtn: "",
          },
        });
        //const content = apiResponseTemplate("Error", err?.data?.Message);
        const content = apiResponseTemplate(
          accountDetailMyProfile?.fields?.errorApiTitle?.value,
          accountDetailMyProfile?.fields?.errorApiMsg?.value
        );
        openModal({
          content,
          version: 1,
        });
      });
  };

  const filteredDependentList = DependentDetailsU?.filter((dependent) => {
    // const HeadOfHouse = dependent.HEADOFHOUSE?.trim();
    // return HeadOfHouse !== profileList.MemberId;
    const RoleID = dependent.RoleID?.trim();
    return RoleID !== PRIMARY_SUBSCRIBER_ROLEID;
  });

  const dependentsAvailable =
    !isDependentLoggedIn && filteredDependentList.length >= 1;

  const dependentDropDownSelect = filteredDependentList.map(
    (dependent, index) => {
      const [lastName, firstName] = dependent.FULLNAME?.trim().split(", ");
      return (
        <option
          value={firstName}
          label={`${firstName} ${lastName}`}
          key={index}
        />
      );
    }
  );

  const dependentDropDownSelectU = [
    <option value="" label="" key="default" />,
    ...dependentDropDownSelect,
  ];

  const relationDropDownSelect = [
    accountDetailMyProfile?.fields?.relationAsParent,
    accountDetailMyProfile?.fields?.relationAsSpouse,
    accountDetailMyProfile?.fields?.relationAsGuardian,
    accountDetailMyProfile?.fields?.relationAsOther,
  ].map((relation) => {
    // console.log("relation =", relation.value);
    return (
      <option
        label={relation.value}
        key={relation.value}
        value={relation.value}
      />
    );
  });

  const relationDropDownSelectU = [
    <option value="" label="" key="default" />,
    ...relationDropDownSelect,
  ];

  const onRelationDropdownHandleChange = () => {};

  const onDropdownHandleChange = (event, formikProps) => {
    const selectedDependent = DependentDetailsU.find((dependent) => {
      const [, firstName] = dependent.FULLNAME?.trim().split(", ");
      return event.target.value === firstName;
    });

    let dependentSelectedValues = {};
    if (selectedDependent) {
      setDependentSelected(true);
      const [lastName, firstName] =
        selectedDependent.FULLNAME?.trim().split(", ");
      const [date] = selectedDependent.DOB?.split("T");
      const gender = selectedDependent.SEX === "M" ? "Male" : "Female";
      formikProps.setFieldTouched("selectDependent", false);
      formikProps.setFieldValue("selectDependent", firstName);
      formikProps.setFieldValue("dependentFirstName", firstName);
      formikProps.setFieldValue("dependentLastName", lastName);
      formikProps.setFieldValue(
        "dependentMemberId",
        selectedDependent.HEADOFHOUSE
      );
      formikProps.setFieldValue("dependentDateOfBirth", date);
      formikProps.setFieldValue("dependentGender", gender);
      formikProps.setFieldValue("dependentState", selectedDependent.StateCode);
      formikProps.setFieldValue(
        "DependentQNXTMemberID",
        selectedDependent.MemID
      );
      clearDependentEditable(formikProps);
    } else {
      setDependentSelected(false);
      clearDependentSection(formikProps);
    }
  };

  const clearDependentSelected = (formikProps) => {
    formikProps.setFieldValue("selectDependent", "");
    formikProps.setFieldValue("relationshipWithDependent", "");
    formikProps.setFieldValue("dependentFirstName", "");
    formikProps.setFieldValue("dependentLastName", "");
    formikProps.setFieldValue("dependentMemberId", "");
    formikProps.setFieldValue("dependentDateOfBirth", "");
    formikProps.setFieldValue("dependentGender", "");
    formikProps.setFieldValue("dependentState", "");
    formikProps.setFieldValue("DependentQNXTMemberID", "");
  };

  const clearDependentSection = (formikProps) => {
    setDependentSelected(false);
    clearDependentSelected(formikProps);
    clearDependentEditable(formikProps);
  };

  const clearDependentEditable = (formikProps) => {
    formikProps.setFieldValue("isDependentNumSame", false);
    formikProps.setFieldValue("dependentPhoneNumber", "");
    formikProps.setFieldValue("dependentAltPhoneNum", "");
    formikProps.setFieldValue("dependentAddress", "");
    formikProps.setFieldValue("dependentCity", "");
    formikProps.setFieldValue("dependentZip", "");
    formikProps.setFieldValue("dependentEmail", "");
  };

  return (
    <>
      <Formik
        initialValues={initialValues}
        onSubmit={onSubmit}
        validationSchema={validationSchema}
        enableReinitialize={true}
      >
        {(props) => {
          return (
            <Form>
              <li className="list-group-item">
                {innerSpinnerHTML?.[ACCOUNT_SETTINGS_PAYER]}
                <div className="row">
                  <div style={{ color: "red" }}>
                    <Text
                      field={
                        accountDetailMyProfile?.fields
                          ?.sendYourHealthInformationAlert
                      }
                    />
                  </div>
                  {isCABrightcareMember ? (
                    <div className="btn-disclaimer-text mt-3">
                      <Text
                        field={accountDetailMyProfile?.fields?.lblSubsidiaryCHP}
                      />
                    </div>
                  ) : null}
                </div>

                <div className="row">
                  <div className="col-xl-3 col-md-4"></div>
                </div>

                <div className="row mb-2">
                  <h5 className="tab-title title2 col-12 text-center">
                    <Text
                      field={
                        accountDetailMyProfile?.fields?.personalInformationTitle
                      }
                    />
                  </h5>
                </div>
                <hr
                  style={{
                    borderTop: "2px solid #009ea0",
                    marginBottom: "2rem",
                  }}
                />

                {/*request member details */}

                <div className="row">
                  <h5
                    className="tab-sub-title sub-color"
                    style={{ paddingBottom: "0.76rem" }}
                  >
                    <Text
                      field={accountDetailMyProfile?.fields?.requestSubmitTitle}
                    />
                  </h5>
                </div>
                <div className="row mb-2">
                  <div className="col-1 row-title">
                    <Field
                      type="radio"
                      name="request"
                      value="member"
                      className="pl-2 form-check-input"
                      onClick={() => clearDependentSection(props)}
                    />
                  </div>

                  <div className="col-11 ">
                    <p className="row-title ">
                      <label htmlFor="request">
                        <Text
                          field={
                            accountDetailMyProfile?.fields?.payerRequestMyself
                          }
                        />
                      </label>
                    </p>
                  </div>
                </div>
                <div className="row">
                  {isMarketplaceMember ? (
                    <div className="col-1 row-title">
                      <Field
                        type="radio"
                        name="request"
                        value="dependant"
                        className="pl-2 form-check-input"
                        disabled={!dependentsAvailable}
                      />
                    </div>
                  ) : (
                    <div className="col-1 row-title">
                      <Field
                        type="radio"
                        name="request"
                        value="dependant"
                        className="pl-2 form-check-input"
                        // disabled={!dependentsAvailable}
                      />
                    </div>
                  )}

                  <div className="col-11">
                    <p className="row-title">
                      <label htmlFor="request">
                        <Text
                          field={
                            accountDetailMyProfile?.fields
                              ?.payerRequestDependent
                          }
                        />
                      </label>
                    </p>
                  </div>
                </div>
                <div className="row mt-4">
                  <h5 className="tab-sub-title  sub-color">
                    <Text
                      field={accountDetailMyProfile?.fields?.memberDetailsTitle}
                    />
                  </h5>
                </div>

                {/* Member Details */}
                <div className="row m-2">
                  <div className="col-sm-12 col-md-2 row-title ">
                    <label htmlFor="memberFirstName">
                      <Text field={accountDetailMyProfile?.fields?.firstName} />
                    </label>
                  </div>
                  <div className="col-sm-12 col-md-4 row-details align-self-center row-title">
                    {profileList.FirstName}
                  </div>
                  <div className="col-sm-12 col-md-2 row-title">
                    <label htmlFor="memberLastName">
                      <Text field={accountDetailMyProfile?.fields?.lastName} />
                    </label>
                  </div>
                  <div className="col-sm-12 col-md-4 row-details align-self-center row-title">
                    {profileList.LastName}
                  </div>
                </div>
                <div className="row m-2">
                  <div className="col-sm-12 col-md-2 row-title">
                    <label htmlFor="memberId">
                      <Text
                        field={accountDetailMyProfile?.fields?.memberIDNumber}
                      />
                    </label>
                  </div>
                  <div className="col-sm-12 col-md-4 row-details align-self-center row-title">
                    {profileList.MemberId}
                  </div>
                  <div className="col-sm-12 col-md-2 align-self-center row-title">
                    <label htmlFor="gender">
                      <Text field={accountDetailMyProfile?.fields?.gender} />
                    </label>
                  </div>
                  <div className="col-sm-12 col-md-4 row-details align-self-center row-title">
                    {profileList.Gender}
                  </div>
                </div>
                <div className="row m-2">
                  <div className="col-sm-12 col-md-2 row-title align-self-center">
                    <label htmlFor="dateOfBirth">
                      <Text
                        field={accountDetailMyProfile?.fields?.dateOfBirth}
                      />
                    </label>
                  </div>
                  <div className="col-sm-12 col-md-4 row-details align-self-center row-title">
                    {profileList.DateOfBirth}
                  </div>
                  <div className="col-sm-12 col-md-2 row-title align-self-center">
                    <label htmlFor="mailingAddress">
                      <Text
                        field={accountDetailMyProfile?.fields?.mailingAddress}
                      />
                    </label>
                  </div>
                  <div className="col-sm-12 col-md-4 row-details align-self-center row-title">
                    {profileList.MailingAddress}
                  </div>
                </div>

                <div className="row m-2">
                  <div className="col-sm-12 col-md-2 row-title align-self-center">
                    <label htmlFor="phoneNumber">
                      <Text
                        field={accountDetailMyProfile?.fields?.phoneNumber}
                      />
                    </label>
                  </div>
                  <div className="col-sm-12 col-md-4 align-self-center row-title">
                    <Field
                      name="phoneNumber"
                      id="phoneNumber"
                      type="text"
                      onFocus={(event) =>
                        props.values.sameNum
                          ? onNumberInputChangeHandler(
                              event,
                              "phoneNumber",
                              props,
                              "altPhoneNum"
                            )
                          : onNumberInputChangeHandler(
                              event,
                              "phoneNumber",
                              props
                            )
                      }
                      onChange={(event) =>
                        props.values.sameNum
                          ? onNumberInputChangeHandler(
                              event,
                              "phoneNumber",
                              props,
                              "altPhoneNum"
                            )
                          : onNumberInputChangeHandler(
                              event,
                              "phoneNumber",
                              props
                            )
                      }
                      onBlur={(event) => {
                        props.handleBlur(event);
                        props.setFieldValue(
                          `phoneNumber`,
                          formatPhoneNumber(event?.target?.value)
                        );
                        props.values.sameNum &&
                          props.setFieldValue(
                            `altPhoneNum`,
                            formatPhoneNumber(event?.target?.value)
                          );
                      }}
                      className={`form-control ${
                        props.errors.phoneNumber && props.touched.phoneNumber
                          ? "is-invalid"
                          : null
                      }`}
                      disabled={props.values.sameNum}
                    />
                    <ErrorMessage
                      name="phoneNumber"
                      component={InlineFormError}
                    />
                  </div>
                  <div className="col-sm-12 col-md-2 row-title align-self-center">
                    <label htmlFor="city">
                      <Text field={accountDetailMyProfile?.fields?.city} />
                    </label>
                  </div>
                  <div className="col-sm-12 col-md-4 row-details align-self-center row-title">
                    {profileList.City}
                  </div>
                </div>

                <div className="row m-2">
                  <div className="col-sm-12 col-md-2 row-title">
                    <label htmlFor="altPhoneNum">
                      <Text
                        field={accountDetailMyProfile?.fields?.altPhoneNum}
                      />
                    </label>
                  </div>
                  <div className="col-sm-12 col-md-4 row-title">
                    <Field
                      name="altPhoneNum"
                      id="altPhoneNum"
                      type="text"
                      className={`form-control ${
                        props.errors.altPhoneNum && props.touched.altPhoneNum
                          ? "is-invalid"
                          : null
                      }`}
                      disabled={props.values.sameNum}
                      onFocus={(event) =>
                        onNumberInputChangeHandler(event, "altPhoneNum", props)
                      }
                      onChange={(event) =>
                        onNumberInputChangeHandler(event, "altPhoneNum", props)
                      }
                      onBlur={(event) => {
                        props.handleBlur(event);
                        props.setFieldValue(
                          `altPhoneNum`,
                          formatPhoneNumber(event?.target?.value)
                        );
                      }}
                    />

                    <p className="font-weight-light font-italic mt-1">
                      <u>
                        <Field
                          type="checkbox"
                          name="sameNum"
                          className="form-check-input"
                          onClick={(event) => {
                            !props.values.sameNum
                              ? props.setFieldValue(
                                  "altPhoneNum",
                                  props.values.phoneNumber
                                )
                              : props.setFieldValue("altPhoneNum", "");
                            props.setFieldValue(
                              "sameNum",
                              !props.values.sameNum
                            );
                          }}
                        />
                        <Text
                          field={
                            accountDetailMyProfile?.fields?.checkSameNumber
                          }
                        />
                      </u>
                    </p>

                    <ErrorMessage
                      name="altPhoneNum"
                      component={InlineFormError}
                    />
                  </div>
                  <div className="col-sm-12 col-md-2 row-title ">
                    <label htmlFor="state">
                      <Text field={accountDetailMyProfile?.fields?.state} />
                    </label>
                    <div className="d-none d-sm-none d-xs-none d-md-block mt-4">
                      <label htmlFor="email">
                        <Text field={accountDetailMyProfile?.fields?.email} />
                      </label>
                    </div>
                  </div>
                  <div className="col-sm-12 col-md-4 ">
                    <div className="row">
                      <div className="col-sm-12 col-md-3 row-details align-self-center row-title">
                        {profileList.State}
                      </div>
                      <div className="col-sm-12 col-md-3 row-title">
                        <label htmlFor="email">
                          <Text field={accountDetailMyProfile?.fields?.zip} />
                        </label>
                      </div>
                      <div className="col-sm-12 col-md-6 row-details align-self-center row-title">
                        {profileList.Zip}
                      </div>
                      <div className="d-none d-sm-none d-md-block mt-1 col-12 row-details mt-4 align-self-center">
                        {profileList.Email}{" "}
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row d-sm-block d-md-none m-2">
                  <div className="col-sm-12 row-title">
                    <label htmlFor="email">
                      <Text field={accountDetailMyProfile?.fields?.email} />
                    </label>
                  </div>
                  <div className="col-sm-12 row-details align-self-center">
                    {profileList.Email}
                  </div>
                </div>

                {/* dependant details */}

                <div
                  className={`${
                    props.values.request === "dependant" ? null : "d-none"
                  }`}
                >
                  <div className="row">
                    <h5 className="tab-sub-title sub-color">
                      <Text
                        field={accountDetailMyProfile?.fields?.dependentTitle}
                      />
                    </h5>
                  </div>

                  <div className="row m-2">
                    {isMarketplaceMember && (
                      <>
                        <div className="col-sm-12 col-md-2 row-title">
                          <label htmlFor="selectDependent">
                            <Text
                              field={
                                accountDetailMyProfile?.fields?.selectDependent
                              }
                            />
                          </label>
                        </div>
                        <div className="col-sm-12 col-md-4 row-details">
                          <Field
                            name="selectDependent"
                            id="selectDependent"
                            as="select"
                            className="form-select"
                            // className={`form-select ${
                            //   props.errors.selectDependent &&
                            //   props.touched.selectDependent
                            //     ? "is-invalid"
                            //     : null
                            // }`}
                            style={{ display: "block" }}
                            onChange={(event) => {
                              // props.handleChange(event);
                              onDropdownHandleChange(event, props);
                            }}
                          >
                            {dependentDropDownSelectU}
                          </Field>
                          <div
                            className={
                              props.values.selectDependent === ""
                                ? "d-block"
                                : "d-none"
                            }
                          >
                            <ErrorMessage
                              name="selectDependent"
                              component={InlineFormError}
                            />
                          </div>
                        </div>
                      </>
                      // <>
                      //   <div className="col-sm-12 col-md-2 row-title"></div>
                      //   <div className="col-sm-12 col-md-4 row-details"></div>
                      //  </>
                    )}

                    <div className="col-sm-12 col-md-2 row-title">
                      <label htmlFor="relationshipWithDependent">
                        <Text
                          field={
                            accountDetailMyProfile?.fields
                              ?.relationshipWithDependent
                          }
                        />
                      </label>
                    </div>
                    <div className="col-sm-12 col-md-4 row-title align-self-center">
                      <Field
                        name="relationshipWithDependent"
                        id="relationshipWithDependent"
                        as="select"
                        className="form-select"
                        style={{ display: "block" }}
                        onChange={(event) =>
                          props.setFieldValue(
                            "relationshipWithDependent",
                            event.target.value
                          )
                        }
                      >
                        {relationDropDownSelectU}
                      </Field>
                    </div>
                  </div>

                  <div className="row m-2">
                    {isMarketplaceMember ? (
                      <div className="col-sm-12 col-md-2 row-title align-self-center">
                        <label htmlFor="dependentFirstName">
                          <Text
                            field={
                              accountDetailMyProfile?.fields?.dependentFirstName
                            }
                          />
                        </label>
                      </div>
                    ) : (
                      <div className="col-sm-12 col-md-2 row-title align-self-center">
                        <label htmlFor="dependentFirstName">
                          <Text
                            field={
                              accountDetailMyProfile?.fields
                                ?.dependentFirstNameOtherLOB
                            }
                          />
                        </label>
                      </div>
                    )}
                    {isMarketplaceMember ? (
                      <div className="col-sm-12 col-md-4 row-details">
                        <Field
                          name="dependentFirstName"
                          id="dependentFirstName"
                          type="text"
                          className={`form-control`}
                          disabled={dependentSelected}
                        />
                      </div>
                    ) : (
                      <div className="col-sm-12 col-md-4 row-details row-title">
                        <Field
                          name="dependentFirstName"
                          id="dependentFirstName"
                          type="text"
                          className={`form-control ${
                            props.errors.dependentFirstName &&
                            props.touched.dependentFirstName
                              ? "is-invalid"
                              : null
                          }`}
                          // disabled={dependentSelected}
                        />
                        <ErrorMessage
                          name="dependentFirstName"
                          component={InlineFormError}
                        />
                      </div>
                    )}
                    {isMarketplaceMember ? (
                      <div className="col-sm-12 col-md-2 row-title  align-self-center">
                        <label htmlFor="dependentLastName">
                          <Text
                            field={
                              accountDetailMyProfile?.fields?.dependentLastName
                            }
                          />
                        </label>
                      </div>
                    ) : (
                      <div className="col-sm-12 col-md-2 row-title  align-self-center">
                        <label htmlFor="dependentLastName">
                          <Text
                            field={
                              accountDetailMyProfile?.fields
                                ?.dependentLastNameOtherLOB
                            }
                          />
                        </label>
                      </div>
                    )}
                    {isMarketplaceMember ? (
                      <div className="col-sm-12 col-md-4 row-title">
                        <Field
                          name="dependentLastName"
                          id="dependentLastName"
                          type="text"
                          className="form-control"
                          disabled={dependentSelected}
                        />
                      </div>
                    ) : (
                      <div className="col-sm-12 col-md-4 row-title">
                        <Field
                          name="dependentLastName"
                          id="dependentLastName"
                          type="text"
                          className={`form-control ${
                            props.errors.dependentLastName &&
                            props.touched.dependentLastName
                              ? "is-invalid"
                              : null
                          }`}
                        />
                        <ErrorMessage
                          name="dependentLastName"
                          component={InlineFormError}
                        />
                      </div>
                    )}
                  </div>
                  <div className="row m-2">
                    {isMarketplaceMember ? (
                      <div className="col-sm-12 col-md-2 row-title  align-self-center">
                        <label htmlFor="dependentMemberId">
                          <Text
                            field={
                              accountDetailMyProfile?.fields?.dependentMemberId
                            }
                          />
                        </label>
                      </div>
                    ) : (
                      <div className="col-sm-12 col-md-2 row-title  align-self-center">
                        <label htmlFor="dependentMemberId">
                          <Text
                            field={
                              accountDetailMyProfile?.fields
                                ?.dependentMemberIdOtherLOB
                            }
                          />
                        </label>
                      </div>
                    )}
                    {isMarketplaceMember ? (
                      <div className="col-sm-12 col-md-4 row-title">
                        <Field
                          name="dependentMemberId"
                          id="dependentMemberId"
                          type="text"
                          className="form-control"
                          disabled={dependentSelected}
                        />
                      </div>
                    ) : (
                      <div className="col-sm-12 col-md-4 row-title">
                        <Field
                          name="dependentMemberId"
                          id="dependentMemberId"
                          type="text"
                          className={`form-control ${
                            props.errors.dependentMemberId &&
                            props.touched.dependentMemberId
                              ? "is-invalid"
                              : null
                          }`}
                          // disabled={dependentSelected}
                        />
                        <ErrorMessage
                          name="dependentMemberId"
                          component={InlineFormError}
                        />
                      </div>
                    )}
                    <div className="col-sm-12 col-md-2 row-title  align-self-center">
                      <label htmlFor="dependentGender">
                        <Text
                          field={
                            accountDetailMyProfile?.fields?.dependentGender
                          }
                        />
                      </label>
                    </div>
                    <div className="col-sm-12 col-md-4  align-self-center">
                      <div className="d-flex justify-content-around row-title">
                        <Field
                          type="radio"
                          name="dependentGender"
                          value="Male"
                          className="align-self-center"
                          disabled={dependentSelected}
                        />
                        <label>
                          <Text
                            field={
                              accountDetailMyProfile?.fields?.dependentGMale
                            }
                          />
                        </label>

                        <Field
                          type="radio"
                          name="dependentGender"
                          value="Female"
                          className="align-self-center"
                          disabled={dependentSelected}
                        />
                        <label>
                          <Text
                            field={
                              accountDetailMyProfile?.fields?.dependentGFemale
                            }
                          />
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="row mx-2 mt-2">
                    {isMarketplaceMember ? (
                      <div className="col-sm-12 col-md-2 row-title  align-self-center">
                        <label htmlFor="dependentDateOfBirth">
                          <Text
                            field={
                              accountDetailMyProfile?.fields
                                ?.dependentDateOfBirth
                            }
                          />
                        </label>
                      </div>
                    ) : (
                      <div className="col-sm-12 col-md-2 row-title  align-self-center">
                        <label htmlFor="dependentDateOfBirth">
                          <Text
                            field={
                              accountDetailMyProfile?.fields
                                ?.dependentDateOfBirthOtherLOB
                            }
                          />
                        </label>
                      </div>
                    )}
                    {isMarketplaceMember ? (
                      <div className="col-sm-12 col-md-4 row-title">
                        <Field
                          name="dependentDateOfBirth"
                          id="dependentDateOfBirth"
                          type="date"
                          className="form-control"
                          disabled={dependentSelected}
                        />
                      </div>
                    ) : (
                      <div className="col-sm-12 col-md-4 row-title">
                        <Field
                          name="dependentDateOfBirth"
                          id="dependentDateOfBirth"
                          type="date"
                          className={`form-control ${
                            props.errors.dependentDateOfBirth &&
                            props.touched.dependentDateOfBirth
                              ? "is-invalid"
                              : null
                          }`}
                        />
                        <ErrorMessage
                          name="dependentDateOfBirth"
                          component={InlineFormError}
                        />
                      </div>
                    )}
                    <div className="col-sm-12 col-md-2 row-title  align-self-center">
                      <label htmlFor="dependentAddress">
                        <Text
                          field={
                            accountDetailMyProfile?.fields?.dependentAddress
                          }
                        />
                      </label>
                    </div>
                    <div className="col-sm-12 col-md-4 row-title">
                      <Field
                        name="dependentAddress"
                        id="dependentAddress"
                        type="text"
                        className={`form-control ${
                          props.errors.dependentAddress &&
                          props.touched.dependentAddress
                            ? "is-invalid"
                            : null
                        }`}
                      />
                      <div className="d-sm-block d-md-none">
                        <ErrorMessage
                          name="dependentAddress"
                          component={InlineFormError}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="row mx-2">
                    <div className="d-none d-md-block offset-md-8 col-md-4">
                      <ErrorMessage
                        name="dependentAddress"
                        component={InlineFormError}
                      />
                    </div>
                  </div>

                  <div className="row mx-2 mt-2">
                    <div className="col-sm-12 col-md-2 row-title">
                      <label htmlFor="dependentPhoneNumber">
                        <Text
                          field={
                            accountDetailMyProfile?.fields?.dependentPhoneNumber
                          }
                        />
                      </label>
                    </div>
                    <div className="col-sm-12 col-md-4 row-title">
                      <Field
                        name="dependentPhoneNumber"
                        id="dependentPhoneNumber"
                        type="text"
                        className={`form-control ${
                          props.values.request === "dependant" &&
                          props.errors.dependentPhoneNumber &&
                          props.touched.dependentPhoneNumber
                            ? "is-invalid"
                            : null
                        }`}
                        onFocus={(event) =>
                          props.values.isDependentNumSame
                            ? onNumberInputChangeHandler(
                                event,
                                "dependentPhoneNumber",
                                props,
                                "dependentAltPhoneNum"
                              )
                            : onNumberInputChangeHandler(
                                event,
                                "dependentPhoneNumber",
                                props
                              )
                        }
                        onChange={(event) =>
                          props.values.isDependentNumSame
                            ? onNumberInputChangeHandler(
                                event,
                                "dependentPhoneNumber",
                                props,
                                "dependentAltPhoneNum"
                              )
                            : onNumberInputChangeHandler(
                                event,
                                "dependentPhoneNumber",
                                props
                              )
                        }
                        onBlur={(event) => {
                          props.handleBlur(event);
                          props.setFieldValue(
                            `dependentPhoneNumber`,
                            formatPhoneNumber(event?.target?.value)
                          );
                          props.values.isDependentNumSame &&
                            props.setFieldValue(
                              `dependentAltPhoneNum`,
                              formatPhoneNumber(event?.target?.value)
                            );
                        }}
                      />
                      <div className="d-sm-block d-md-none">
                        <ErrorMessage
                          name="dependentPhoneNumber"
                          component={InlineFormError}
                        />
                      </div>
                    </div>
                    <div className="col-sm-12 col-md-2 row-title  align-self-center">
                      <label htmlFor="dependentCity">
                        <Text
                          field={accountDetailMyProfile?.fields?.dependentCity}
                        />
                      </label>
                    </div>
                    <div className="col-sm-12 col-md-4 row-title">
                      <Field
                        name="dependentCity"
                        id="dependentCity"
                        type="text"
                        className={`form-control ${
                          props.errors.dependentCity &&
                          props.touched.dependentCity
                            ? "is-invalid"
                            : null
                        }`}
                      />
                      <div className="d-sm-block d-md-none">
                        <ErrorMessage
                          name="dependentCity"
                          component={InlineFormError}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="row mx-2">
                    <div className="d-none d-md-block offset-md-2 col-md-4">
                      <ErrorMessage
                        name="dependentPhoneNumber"
                        component={InlineFormError}
                      />
                    </div>
                    <div className="d-none d-md-block offset-md-2 col-md-4">
                      <ErrorMessage
                        name="dependentCity"
                        component={InlineFormError}
                      />
                    </div>
                  </div>

                  <div className="row mx-2 mt-2 mb-3">
                    <div className="col-sm-12 col-md-2 row-title align-self-center">
                      <label htmlFor="dependentAltPhoneNum">
                        <Text
                          field={
                            accountDetailMyProfile?.fields?.dependentAltPhoneNum
                          }
                        />
                      </label>
                    </div>
                    <div className="col-sm-12 col-md-4 row-title align-self-center">
                      <Field
                        name="dependentAltPhoneNum"
                        id="dependentAltPhoneNum"
                        type="text"
                        className={`form-control ${
                          props.values.request === "dependant" &&
                          props.errors.dependentAltPhoneNum &&
                          props.touched.dependentAltPhoneNum
                            ? "is-invalid"
                            : null
                        }`}
                        onFocus={(event) =>
                          onNumberInputChangeHandler(
                            event,
                            "dependentAltPhoneNum",
                            props
                          )
                        }
                        onChange={(event) =>
                          onNumberInputChangeHandler(
                            event,
                            "dependentAltPhoneNum",
                            props
                          )
                        }
                        onBlur={(event) => {
                          props.handleBlur(event);
                          props.setFieldValue(
                            `dependentAltPhoneNum`,
                            formatPhoneNumber(event?.target?.value)
                          );
                        }}
                        disabled={props.values.isDependentNumSame}
                      />
                      <div className="d-sm-block d-md-none">
                        <p className="font-weight-light font-italic">
                          <u>
                            <Field
                              type="checkbox"
                              name="isDependentNumSame"
                              id="isDependentNumSame"
                              className="form-check-input"
                              onClick={(event) => {
                                !props.values.isDependentNumSame
                                  ? props.setFieldValue(
                                      "dependentAltPhoneNum",
                                      props.values.dependentPhoneNumber
                                    )
                                  : props.setFieldValue(
                                      "dependentAltPhoneNum",
                                      ""
                                    );
                              }}
                            />
                            <Text
                              field={
                                accountDetailMyProfile?.fields?.checkSameNumber
                              }
                            />
                          </u>
                        </p>
                        <ErrorMessage
                          name="dependentAltPhoneNum"
                          component={InlineFormError}
                        />
                      </div>
                    </div>
                    {/* <div className="row mx-2 mb-4"> */}
                    <div className="col-sm-12 col-md-2 row-title align-self-center">
                      <Text
                        field={accountDetailMyProfile?.fields?.dependentEmail}
                      />
                    </div>
                    <div className="col-sm-12 col-md-4 row-detail row-title align-self-center">
                      <Field
                        name="dependentEmail"
                        id="dependentEmail"
                        type="email"
                        className={`form-control ${
                          props.errors.dependentEmail &&
                          props.touched.dependentEmail
                            ? "is-invalid"
                            : null
                        }`}
                      />
                      <ErrorMessage
                        name="dependentEmail"
                        component={InlineFormError}
                      />
                    </div>
                    <div className="row mx-2">
                      <div className="d-none d-md-block col-md-4 offset-md-2">
                        <p className="font-weight-light font-italic">
                          <u>
                            <Field
                              type="checkbox"
                              name="isDependentNumSame"
                              id="isDependentNumSame"
                              className="form-check-input"
                              onClick={(event) => {
                                !props.values.isDependentNumSame
                                  ? props.setFieldValue(
                                      "dependentAltPhoneNum",
                                      props.values.dependentPhoneNumber
                                    )
                                  : props.setFieldValue(
                                      "dependentAltPhoneNum",
                                      ""
                                    );
                              }}
                            />
                            <Text
                              field={
                                accountDetailMyProfile?.fields?.checkSameNumber
                              }
                            />
                          </u>
                        </p>
                        <ErrorMessage
                          name="dependentAltPhoneNum"
                          component={InlineFormError}
                        />
                      </div>
                      {/* <div className="d-none d-md-block offset-md-4 col-md-2">
                      <ErrorMessage
                        name="dependentZip"
                        component={InlineFormError}
                      />
                    </div> */}
                    </div>
                    {/* </div> */}
                    {isMarketplaceMember ? (
                      <div className="col-sm-12 col-md-2 row-title align-self-center">
                        <label htmlFor="dependentState">
                          <Text
                            field={
                              accountDetailMyProfile?.fields?.dependentState
                            }
                          />
                        </label>
                      </div>
                    ) : (
                      <div className="col-sm-12 col-md-2 row-title align-self-center">
                        <label htmlFor="dependentState">
                          <Text
                            field={
                              accountDetailMyProfile?.fields
                                ?.dependentStateOtherLOB
                            }
                          />
                        </label>
                      </div>
                    )}

                    {/* <div className="col-sm-12 col-md-4 align-self-center"> */}
                    {/* <div className="col-sm-12 col-md-4 align-self-center"> */}
                    {isMarketplaceMember ? (
                      <div className="col-sm-12 col-md-4 row-title">
                        <Field
                          name="dependentState"
                          id="dependentState"
                          type="text"
                          className="form-control"
                          disabled={dependentSelected}
                          onChange={(event) =>
                            CharOnly(event, "dependentState", props)
                          }
                        />
                      </div>
                    ) : (
                      <div className="col-sm-12 col-md-4 row-title">
                        <Field
                          name="dependentState"
                          id="dependentState"
                          type="text"
                          className={`form-control ${
                            props.values.request === "dependant" &&
                            props.errors.dependentState &&
                            props.touched.dependentState
                              ? "is-invalid"
                              : null
                          }`}
                          onChange={(event) =>
                            CharOnly(event, "dependentState", props)
                          }
                        />
                        <ErrorMessage
                          name="dependentState"
                          component={InlineFormError}
                        />
                      </div>
                    )}

                    <div className="col-sm-12 col-md-2 row-title align-self-center">
                      <Text
                        field={accountDetailMyProfile?.fields?.dependentZip}
                      />
                    </div>
                    <div className="col-sm-12 col-md-4 row-title">
                      <Field
                        name="dependentZip"
                        id="dependentZip"
                        type="text"
                        className={`form-control ${
                          props.errors.dependentZip &&
                          props.touched.dependentZip
                            ? "is-invalid"
                            : null
                        }`}
                        onChange={(event) =>
                          NumberOnly(event, "dependentZip", props)
                        }
                      />

                      <ErrorMessage
                        name="dependentZip"
                        component={InlineFormError}
                      />
                    </div>
                    {/* <div className="mt-2">
                          <Field
                            name="email"
                            id="email"
                            type="text"
                            className="form-control"
                          />
                        </div> */}
                    {/* </div> */}
                    {/* </div> */}
                  </div>
                </div>

                {/* health plan */}

                <div className="row">
                  <h5 className="tab-sub-title sub-color">
                    <Text
                      field={
                        props.values.request === "dependant"
                          ? accountDetailMyProfile?.fields?.healthPlanTitle
                          : accountDetailMyProfile?.fields?.healthPlanTitleSelf
                      }
                    />
                  </h5>
                </div>

                <div className="row m-2">
                  <div className="col-sm-12 col-md-2 row-title">
                    <label htmlFor="healthPlanName">
                      <Text
                        field={accountDetailMyProfile?.fields?.healthPlanName}
                      />
                    </label>
                  </div>
                  <div className="col-sm-12 col-md-4 row-details row-title">
                    <Field
                      name="healthPlanName"
                      id="healthPlanName"
                      type="text"
                      className={`form-control ${
                        props.errors.healthPlanName &&
                        props.touched.healthPlanName
                          ? "is-invalid"
                          : null
                      }`}
                    />

                    <ErrorMessage
                      name="healthPlanName"
                      component={InlineFormError}
                    />
                  </div>
                  <div className="col-sm-12 col-md-2 row-title">
                    <label htmlFor="memberHealthPlan">
                      <Text
                        field={accountDetailMyProfile?.fields?.memberHealthPlan}
                      />
                    </label>
                  </div>
                  <div className="col-sm-12 col-md-4 row-title">
                    <Field
                      name="memberHealthPlan"
                      id="memberHealthPlan"
                      type="text"
                      className="form-control"
                    />
                  </div>
                </div>

                <div className="row m-2">
                  <div className="col-sm-12 col-md-2 row-title">
                    <label htmlFor="healthPlanContactFirstName">
                      <Text
                        field={
                          accountDetailMyProfile?.fields
                            ?.healthPlanContactFirstName
                        }
                      />
                    </label>
                  </div>
                  <div className="col-sm-12 col-md-4 row-details row-title">
                    <Field
                      name="healthPlanContactFirstName"
                      id="healthPlanContactFirstName"
                      type="text"
                      className="form-control"
                    />
                  </div>
                  <div className="col-sm-12 col-md-2 row-title">
                    <label htmlFor="healthPlanContactLastName">
                      <Text
                        field={
                          accountDetailMyProfile?.fields
                            ?.healthPlanContactLastName
                        }
                      />
                    </label>
                  </div>
                  <div className="col-sm-12 col-md-4 row-title">
                    <Field
                      name="healthPlanContactLastName"
                      id="healthPlanContactLastName"
                      type="text"
                      className="form-control"
                    />
                  </div>
                </div>

                <div className="row mx-2 mt-2">
                  <div className="col-sm-12 col-md-2 row-title ">
                    <label htmlFor="healthPlanPhoneNum">
                      <Text
                        field={
                          accountDetailMyProfile?.fields?.healthPlanPhoneNum
                        }
                      />
                    </label>
                  </div>
                  <div className="col-sm-12 col-md-4 row-title">
                    <div className="row">
                      <div className={`col-sm-12 col-md-5 `}>
                        <Field
                          name="healthPlanPhoneNum"
                          id="healthPlanPhoneNum"
                          type="text"
                          className={`form-control ${
                            props.errors.healthPlanPhoneNum &&
                            props.touched.healthPlanPhoneNum
                              ? "is-invalid"
                              : null
                          }`}
                          onFocus={(event) =>
                            onNumberInputChangeHandler(
                              event,
                              "healthPlanPhoneNum",
                              props
                            )
                          }
                          onChange={(event) =>
                            onNumberInputChangeHandler(
                              event,
                              "healthPlanPhoneNum",
                              props
                            )
                          }
                          onBlur={(event) => {
                            props.handleBlur(event);
                            props.setFieldValue(
                              `healthPlanPhoneNum`,
                              formatPhoneNumber(event?.target?.value)
                            );
                          }}
                        />
                        <div className="d-sm-block d-md-none">
                          <ErrorMessage
                            name="healthPlanPhoneNum"
                            component={InlineFormError}
                          />
                        </div>
                      </div>
                      <div className="col-sm-12 col-md-3 align-self-center">
                        <label htmlFor="healthPlanExtn">
                          <Text
                            field={
                              accountDetailMyProfile?.fields?.healthPlanExtn
                            }
                          />
                        </label>
                      </div>
                      <div className="col-sm-12 col-md-4">
                        <Field
                          name="healthPlanExtn"
                          id="healthPlanExtn"
                          type="text"
                          className="form-control"
                          onChange={(event) =>
                            NumberOnly(event, "healthPlanExtn", props)
                          }
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-12 col-md-2 row-title">
                    <label htmlFor="healthPlanEmailAddress">
                      <Text
                        field={
                          accountDetailMyProfile?.fields?.healthPlanEmailAddress
                        }
                      />
                    </label>
                  </div>
                  <div className="col-sm-12 col-md-4 row-title">
                    <Field
                      name="healthPlanEmailAddress"
                      id="healthPlanEmailAddress"
                      type="email"
                      className={`form-control ${
                        props.errors.healthPlanEmailAddress &&
                        props.touched.healthPlanEmailAddress
                          ? "is-invalid"
                          : null
                      }`}
                    />
                  </div>
                </div>

                <div className="row mx-2">
                  <div className="col-2 d-none d-md-block offset-md-2 align-self-center">
                    <ErrorMessage
                      name="healthPlanPhoneNum"
                      component={InlineFormError}
                    />
                  </div>
                  <div className="col-4 d-none d-md-block offset-md-4">
                    <ErrorMessage
                      name="healthPlanEmailAddress"
                      component={InlineFormError}
                    />
                  </div>
                </div>

                {/* Declaration section */}

                <div className="row mb-4">
                  <div
                    className="p-2 col-12  sub-color"
                    style={{
                      font: "normal normal 1.2rem/1.52rem Campton-medium",
                      backgroundColor: "#EFEFEF",
                    }}
                  >
                    <Text
                      field={accountDetailMyProfile?.fields?.declarationTitle}
                    />
                  </div>
                </div>
                <div className="row m-2">
                  <div className="col-1">
                    <Field
                      type="checkbox"
                      name="declaration1"
                      className={`form-check-input mr-2
                      ${
                        props.errors.declaration1 && props.touched.declaration1
                          ? "is-invalid"
                          : null
                      }
                      `}
                    />
                  </div>
                  <div className="col-11">
                    <p className="row-details">
                      <Text
                        field={accountDetailMyProfile?.fields?.declaration1}
                      />
                    </p>
                  </div>
                </div>

                <div className="row m-2">
                  <div className="col-1">
                    <Field
                      type="checkbox"
                      name="declaration2"
                      className={`form-check-input mr-2
                      ${
                        props.errors.declaration2 && props.touched.declaration2
                          ? "is-invalid"
                          : null
                      }
                      `}
                    />
                  </div>
                  <div className="col-11">
                    <p className="row-details">
                      <Text
                        field={accountDetailMyProfile?.fields?.declaration2}
                      />
                    </p>
                  </div>
                </div>

                <div className="row m-2">
                  <div className="col-1">
                    <Field
                      type="checkbox"
                      name="declaration3"
                      className={`form-check-input mr-2
                      ${
                        props.errors.declaration3 && props.touched.declaration3
                          ? "is-invalid"
                          : null
                      }
                      `}
                    />
                  </div>
                  <div className="col-11">
                    <p className="row-details">
                      <Text
                        field={
                          props.values.request === "dependant"
                            ? accountDetailMyProfile?.fields?.declaration3
                            : accountDetailMyProfile?.fields?.declaration3Self
                        }
                      />
                    </p>
                  </div>
                </div>

                <div className="row m-2">
                  <div className="col-1">
                    <Field
                      type="checkbox"
                      name="declaration4"
                      className={`form-check-input mr-2
                      ${
                        props.errors.declaration4 && props.touched.declaration4
                          ? "is-invalid"
                          : null
                      }
                      `}
                    />
                  </div>
                  <div className="col-11">
                    <p className="row-details">
                      <Text
                        field={
                          props.values.request === "dependant"
                            ? accountDetailMyProfile?.fields?.declaration4
                            : accountDetailMyProfile?.fields?.declaration4Self
                        }
                      />
                    </p>
                  </div>
                </div>

                {/* Button section */}

                <div className="row m-2">
                  <div className="col-sm-12 col-md-7 d-flex flex-sm-column flex-md-row justify-content-md-end align-items-md-center">
                    <button
                      type="submit"
                      className="btn btn-primary m-3 w-md-35 w-sm-100"
                      disabled={
                        !(
                          props.values.declaration1 &&
                          props.values.declaration2 &&
                          props.values.declaration3 &&
                          props.values.declaration4 &&
                          !isTermedMember
                        )
                      }
                    >
                      <Text field={accountDetailMyProfile?.fields?.btnSubmit} />
                    </button>
                    <button
                      className="btn btn-primary m-sm-3 my-md-3 ml-md-3 w-md-35 w-sm-100 "
                      onClick={props.handleReset}
                    >
                      <Text field={accountDetailMyProfile?.fields?.btnCancel} />
                    </button>
                  </div>
                  <div className="col-sm-12 col-md-5 d-flex justify-content-sm-center justify-content-md-end align-items-center">
                    <p>
                      <Text field={accountDetailMyProfile?.fields?.date} />
                      {formatToday()}
                    </p>
                  </div>
                </div>

                <div className="row">
                  <p className="col-12 text-center">
                    <Text field={accountDetailMyProfile?.fields?.mandatory} />
                  </p>
                  {/* <p className="col-12 text-center">
                    <Text
                      field={
                        accountDetailMyProfile?.fields?.lblPersonalInfoTest
                      }
                    />
                  </p> */}
                </div>

                <div className="col-xxl-2 col-xl-2 col-md-6 col-sm-12">
                  <div className="col-sm-12">
                    {hiddenDisclaimer && (
                      <div className="btn-disclaimer-text">
                        {getFieldValue(
                          accountDetailMyProfile,
                          "btnDisclaimer",
                          false
                        )}
                      </div>
                    )}
                  </div>
                </div>
              </li>
            </Form>
          );
        }}
      </Formik>
    </>
  );
}

export default PersonalInformation;
